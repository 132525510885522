import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "vp-rename-dialog",
  templateUrl: "./rename-dialog.component.html",
  styleUrls: ["./rename-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameDialogComponent {
  value: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.value = data;
  }
}
