import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TransactionStatusEvent } from "@vp/core/models";
import { EventAggregator, IEvent } from "@vp/shared/event-aggregator";
import { filterNullMap } from "@vp/shared/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
  selector: "vp-payment-status",
  templateUrl: "./payment-status.component.html",
  styleUrls: ["./payment-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentStatusComponent implements OnInit {
  private _statusMessageSubject = new BehaviorSubject<string | null>(null);
  private _authorized$ = new BehaviorSubject<boolean>(false);

  get isAuthorized(): Observable<boolean> {
    return this._authorized$.asObservable();
  }

  get hasStatus(): Observable<boolean> {
    return this._statusMessageSubject.pipe(map((message: string | null) => message !== null));
  }

  get statusMessage(): Observable<string> {
    return this._statusMessageSubject.pipe(filterNullMap());
  }

  constructor(private cdRef: ChangeDetectorRef, private eventAggregator: EventAggregator) {}

  ngOnInit(): void {
    this.eventAggregator
      .on<TransactionStatusEvent>(TransactionStatusEvent)
      .pipe(
        tap((event: IEvent) => {
          this._statusMessageSubject.next(event.args.message);
          this._authorized$.next(event.args.status === "success");
        })
      )
      .subscribe(() => {
        this.cdRef.detectChanges();
      });
  }
}
