import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { UserAssignRolesModule } from "@vp/administration/user/ui/user-assign-roles";
import { UserAssignedGroupsModule } from "@vp/administration/user/ui/user-assigned-groups";
import { UserAssignedRolesModule } from "@vp/administration/user/ui/user-assigned-roles";
import { UserSnippetsModule } from "@vp/administration/user/ui/user-snippets";
import { EmailVerificationModule } from "@vp/profile/ui/email-verification";
import { FeatureFlagsModule } from "@vp/shared/feature-flags";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { NgxPermissionsModule } from "ngx-permissions";
import { LegalNoticeComponent } from "./legal-notice/legal-notice.component";
import { ProfileComponent } from "./profile.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        pathMatch: "full",
        component: ProfileComponent
      },
      { path: "legalnotice", pathMatch: "full", component: LegalNoticeComponent }
    ]),
    FeatureFlagsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatIntlTelInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    MatDatepickerModule,
    NgxPermissionsModule.forChild(),
    UserAssignedRolesModule,
    UserAssignRolesModule,
    UserAssignedGroupsModule,
    UserSnippetsModule,
    EmailVerificationModule,
    NgxMaskModule.forRoot({
      validation: true
    })
  ],
  declarations: [ProfileComponent, LegalNoticeComponent],
  exports: [ProfileComponent, LegalNoticeComponent]
})
export class ProfileFeatureModule {
  constructor() {}
}
