import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UiStoreService } from "@vp/shared/store/ui";
import { mapActivatedRouteBreadcrumbs } from "@vp/shared/utilities";
import { Observable, ReplaySubject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() separator = "/";

  selectedStatus: string | null = null;
  activatedRouteUrl: Observable<string>;
  breadcrumbs: Observable<Array<breadcumb>>;

  private destroyedSubject: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private _router: Router,
    public activatedRoute: ActivatedRoute,
    public uiStoreService: UiStoreService
  ) {
    this.breadcrumbs = this._router.events.pipe(mapActivatedRouteBreadcrumbs(this.activatedRoute));

    this.activatedRouteUrl = this._router.events.pipe(
      mapActivatedRouteBreadcrumbs(this.activatedRoute),
      // TODO Maybe a better way? YES! Pushing up to the route config!
      map(breadcrumbs => breadcrumbs[breadcrumbs.length - 1].url)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyedSubject)).subscribe({
      next: params => {
        if (params.unresolved === "true") {
          this.selectedStatus = "unresolved";
        }
        if (params.unread === "true") {
          this.selectedStatus = "unread";
        }
        if (params.status) {
          const status = params.status;
          this.selectedStatus = status.replace(/-/g, " ");
        }
      }
    });
  }

  trackByFn = (_index: number, item: { label: string; url: string }): string => item.label;

  ngOnDestroy(): void {
    this.destroyedSubject.next(true);
    this.destroyedSubject.complete();
  }
}

interface breadcumb {
  label: string;
  url: string;
}
