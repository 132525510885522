import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AngularEditorComponent, AngularEditorConfig } from "@kolkov/angular-editor";
import { FieldType } from "@ngx-formly/core";
import { AddOrEditSnippetDialogComponent } from "@vp/administration/user/ui/user-snippets";
import { Snippet, User } from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { BehaviorSubject, EMPTY, of } from "rxjs";
import { concatMap, map, switchMap, withLatestFrom } from "rxjs/operators";

/* TODO: This was moved out of vp-app to remove circular dependency, while this is currently
   is used as a "Default" this should be tokenized an set up to be used via a provider from the
   main app.
*/
export const defaultEditorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  width: "auto",
  minHeight: "490px",
  placeholder: "Enter text here...",
  translate: "no",
  sanitize: true,
  // toolbarPosition: 'top',
  outline: true,
  defaultFontSize: "3",
  // showToolbar: false,
  defaultParagraphSeparator: "p",
  toolbarHiddenButtons: [
    ["subscript", "superscript", "heading", "fontName"],
    [
      "insertImage",
      "insertVideo",
      "link",
      "unlink",
      "customClasses",
      "backgroundColor",
      "fontSize",
      "insertHorizontalRule",
      "removeFormat",
      "toggleEditorMode"
    ]
  ]
};

@Component({
  selector: "lib-formly-rich-text-type",
  templateUrl: "./formly-rich-text-type.component.html",
  styleUrls: ["./formly-rich-text-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyRichTextTypeComponent extends FieldType implements AfterViewInit {
  formControl!: FormControl;
  config: AngularEditorConfig;
  selectedSnippet: Snippet = undefined;
  snippets: Snippet[];
  cursorPosition: Range | null;

  private readonly _disabled = new BehaviorSubject<boolean>(false);

  @ViewChild(AngularEditorComponent)
  public angulareditor!: AngularEditorComponent;
  public disabled = this._disabled.asObservable();

  constructor(
    private readonly dialog: MatDialog,
    private appStore: AppStoreService,
    private notificationService: NotificationService
  ) {
    super();
    this.config = { ...defaultEditorConfig };
    this.config.minHeight = "100px";
    this.config.maxHeight = "200px";
    this.appStore.userStream.subscribe(user => {
      this.snippets = (user.userData?.snippets as Snippet[]) || [];
    });
  }
  ngAfterViewInit(): void {
    this._disabled.next(!!this.formState.disabled);
    this.form.statusChanges.subscribe(() => {
      this._disabled.next(this.formState.disabled);
    });
  }

  saveSelection() {
    const sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      this.cursorPosition = sel.getRangeAt(0);
    }
  }

  showSnippet() {
    const show = this.to?.showSnippet ?? false;
    return show;
  }

  insertText(editor: any) {
    if (this.selectedSnippet && this.cursorPosition) {
      this.cursorPosition.insertNode(document.createTextNode(this.selectedSnippet.text));
      editor.togglePlaceholder(true);
    }
  }

  addNewSnippet() {
    const dialogRef = this.dialog.open(AddOrEditSnippetDialogComponent, {
      width: "500px",
      data: { action: "Add" }
    });

    dialogRef
      .afterClosed()
      .pipe(
        withLatestFrom(
          this.appStore.userStream.pipe(
            filterNullMap(),
            map(user => {
              return { ...user };
            })
          )
        ),
        switchMap(([dialogResult, user]: [Snippet, User]) => {
          if (dialogResult) {
            user.userData = { snippets: this.snippets.concat(dialogResult) };
            return of(user);
          }
          return EMPTY;
        }),
        concatMap((user: User) => {
          return this.appStore.patchUser(user);
        })
      )
      .subscribe(updatedUser => {
        this.snippets = (updatedUser.userData?.snippets as Snippet[]) || [];
        this.notificationService.successMessage("Snippet Added");
      });
  }
}
