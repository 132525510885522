import { Injectable } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { ProgressOption } from "../../components/progress-circle/progress-circle.component";
import { CaseData } from "@vp/core/models";

export interface CaseProgress {
  current: number;
  total: number;
  color: ThemePalette;
  options: ProgressOption[];
  paid: boolean;
}

@Injectable({
  providedIn: "root"
})
export class CaseProgressService {
  constructor() {}

  calculateCaseProgress(caseData: CaseData) {
    let current = 25;
    if (caseData.result?.results?.length > 0) {
      current = 100;
    } else if (caseData.assignedDateTime) {
      current = 75;
    } else if (caseData.lastSubmittedBy) {
      current = 50;
    }
    const total = 100;
    const color: ThemePalette = "primary";
    const paid = caseData.paid;
    const options: ProgressOption[] = [
      { min: 0, max: 0, label: "Just Started" },
      { min: 0, max: 50, label: "In Progress" },
      { min: 50, max: 100, label: "Amost Done" },
      { min: 100, max: 100, label: "Completed" }
    ];

    const progress: CaseProgress = {
      current,
      total,
      color,
      options,
      paid
    };

    return progress;
  }
}
