import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProfileGuard } from "@vp/profile/feature";
import { environment } from "../environments/environment";
import { AuthenticationGuard } from "./shared/guards/authentication.guard";
import { RedirectGuard } from "./shared/guards/redirect.guard";

const routes: Routes = [
  {
    path: "home",
    data: {
      breadcrumb: "homeNav"
    },
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
  },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "loggedout", redirectTo: "/home", pathMatch: "full" },
  {
    path: "forbidden",
    loadChildren: () => import("./pages/forbidden/forbidden.module").then(m => m.ForbiddenModule)
  },
  {
    path: "unauthorized",
    loadChildren: () =>
      import("./pages/unauthorized/unauthorized.module").then(m => m.UnauthorizedModule)
  },
  {
    path: "wizard",
    data: {
      breadcrumb: "caseNav"
    },
    loadChildren: () => import("./case-create/case-create.module").then(m => m.CaseCreateModule)
  },

  // BEGIN authenticated only routes
  {
    path: "login/callback",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "loggedin",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "default",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "profile",
    canLoad: [AuthenticationGuard],
    data: {
      breadCrumb: "profile"
    },
    loadChildren: () => import("@vp/profile/feature").then(m => m.ProfileFeatureModule)
  },
  {
    path: "case",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    loadChildren: () =>
      import("./case-dashboard/case-dashboard.module").then(m => m.CaseDashboardModule)
  },
  {
    path: "dashboard",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule)
  },
  {
    path: "cases",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/case-search/feature/case-search").then(m => m.CaseSearchModule)
  },
  {
    path: "patients",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("./patient/feature/patient.module").then(m => m.PatientFeatureModule)
  },
  {
    path: "administration",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () =>
      import("./administration/administration.module").then(m => m.AdministrationModule)
  },
  {
    path: "about",
    outlet: "sidebar",
    canLoad: [AuthenticationGuard],
    canActivate: [ProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("./pages/about/about.module").then(m => m.AboutModule)
  },
  { path: "**", redirectTo: "/default", pathMatch: "full" }
];

export const enableTracing = true; // <-- Set to true to debug router in dev mode

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.production ? false : enableTracing,
      useHash: false,
      onSameUrlNavigation: "reload",
      initialNavigation: "disabled",
      relativeLinkResolution: "legacy"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function isInIframe() {
  return window !== window.parent && !window.opener;
}
