import { Pipe, PipeTransform, Injectable } from "@angular/core";

export const isEmptyObject = (obj: any) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;
/**
 * Forked from https://github.com/solodynamo/ng2-search-filter
 * to get latest version not on NPM at the time
 */
@Pipe({
  name: "dynFilterTerm"
})
@Injectable()
export class DynFilterTermPipe implements PipeTransform {
  transform(
    items: any,
    term: string | undefined,
    excludes: any = [],
    filters: Record<string, unknown> | undefined = undefined
  ): any {
    if (!items) {
      return items;
    }

    if (!term && (!filters || isEmptyObject(filters))) {
      return items;
    }

    return DynFilterTermPipe.filter(items, term, excludes, filters);
  }

  /**
   *
   * @param items List of items to filter
   * @param term  String term to compare with every property of the list
   * @param excludes List of keys which will be ignored during search
   * @param filter List of key/value to filter on
   *
   */
  static filter(
    items: Array<{ [key: string]: any }>,
    term: string | undefined,
    excludes: any,
    filters: Record<string, unknown> | undefined
  ): Array<{ [key: string]: any }> {
    const toCompare = term?.toLowerCase();
    let filteredItem = false;
    let foundItem = false;

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function checkInside(item: any, term: string | undefined, found: boolean, filtered: boolean) {
      if (
        toCompare &&
        typeof item === "string" &&
        item.toString().toLowerCase().includes(toCompare)
      ) {
        found = true;
      }
      for (let property in item) {
        if (filters && filters[property] && item[property] !== filters[property]) {
          filtered = true;
        }
        if (
          item[property] === null ||
          item[property] === undefined ||
          excludes.includes(property)
        ) {
          continue;
        }
        if (toCompare && typeof item[property] === "object") {
          if (checkInside(item[property], term, found, filtered)) {
            found = true;
          }
        } else if (toCompare && item[property].toString().toLowerCase().includes(toCompare)) {
          found = true;
        }
      }
      return (term && found && !filtered) || (!term && !filtered);
    }

    return items.filter(item => checkInside(item, term, foundItem, filteredItem));
  }
}
