import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "vp-simple-delimited-list",
  templateUrl: "./simple-delimited-list.component.html",
  styleUrls: ["./simple-delimited-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDelimitedListComponent {
  @Input() prepend: string;

  @Input() delimiter: string;

  @Input() itemList: { display: string[]; style: any }[];

  constructor() {}
}
