import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DicomStudy } from "@vp/core/models";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class DicomStudyApiService {
  constructor(private readonly _http: HttpClient) {}

  updateDicomStudy(caseId: string, dicomStudy: DicomStudy) {
    const apiURL = `${environment.baseApi}/case/${caseId}/dicom-study-update`;
    return this._http.post(apiURL, dicomStudy);
  }

  deleteDicomStudy(caseId: string, studyInstanceUid: string) {
    const apiURL = `${environment.baseApi}/case/${caseId}/dicom-study-delete/${studyInstanceUid}`;
    return this._http.delete(apiURL);
  }
}
