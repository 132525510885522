import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "hashLookup"
})
export class HashLookupPipe implements PipeTransform {
  transform(value: string, hash: {}) {
    return hash[value] || "";
  }
}
