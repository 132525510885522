import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { RouterModule } from "@angular/router";
import { MtxPopoverModule } from "@ng-matero/extensions/popover";
import { FormlyModule } from "@ngx-formly/core";
import { BreadcrumbModule } from "@vp/shared/components/breadcrumb";
import { FeatureFlagsModule } from "@vp/shared/feature-flags";
import { MaterialModule } from "@vp/shared/material";
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module } from "ng-recaptcha";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { ToastContainerModule } from "ngx-toastr";
import { NavComponent } from "../components/nav-component";
import { PaymentStatusComponent } from "../services/payment/components/payment-status/payment-status.component";
import { SharedModule } from "../shared/shared.module";
import { AlertComponent } from "./alert/alert.component";
import { CaptchaComponent } from "./capcha-verify/captcha.component";
import { DicomsActionsComponent } from "./dicoms-actions/dicoms-actions.component";
import { DicomsGridComponent } from "./dicoms-grid/dicoms-grid.component";
import { DocumentActionsComponent } from "./document-actions/document-actions.component";
import { DocumentDataComponent } from "./document-data/document-data.component";
import { DocumentGridComponent } from "./document-grid/document-grid.component";
import { ImagesActionsComponent } from "./images-actions/images-actions.component";
import { ImagesDataComponent } from "./images-data/images-data.component";
import { ImagesGridComponent } from "./images-grid/images-grid.component";
import { NavItemComponent } from "./nav-item/nav-item.component";
import { ProgressCircleComponent } from "./progress-circle/progress-circle.component";
import { QuickSearchComponent } from "./quick-search";
import { RenameDialogComponent } from "./rename-dialog/rename-dialog.component";
import { SideNavComponent } from "./side-nav";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    MatBadgeModule,
    RouterModule,
    ToastContainerModule,
    FormlyModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    MtxPopoverModule,
    FeatureFlagsModule,
    NgxMatIntlTelInputModule,
    BreadcrumbModule
  ],
  declarations: [
    AlertComponent,
    CaptchaComponent,
    DicomsActionsComponent,
    DicomsGridComponent,
    DocumentActionsComponent,
    DocumentDataComponent,
    DocumentGridComponent,
    ImagesActionsComponent,
    ImagesDataComponent,
    ImagesGridComponent,
    NavComponent,
    NavItemComponent,
    PaymentStatusComponent,
    ProgressCircleComponent,
    QuickSearchComponent,
    RenameDialogComponent,
    SideNavComponent
  ],
  exports: [
    AlertComponent,
    CaptchaComponent,
    DicomsActionsComponent,
    DicomsGridComponent,
    DocumentActionsComponent,
    DocumentDataComponent,
    DocumentGridComponent,
    ImagesActionsComponent,
    ImagesDataComponent,
    ImagesGridComponent,
    MtxPopoverModule,
    NavComponent,
    NgxMatIntlTelInputModule,
    PaymentStatusComponent,
    ProgressCircleComponent,
    QuickSearchComponent,
    SideNavComponent
  ]
})
export class ComponentModule {}
