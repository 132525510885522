import { Injectable } from "@angular/core";
import { filterNullMap } from "@vp/shared/operators";
import { Observable, Subject } from "rxjs";
import { filter, tap } from "rxjs/operators";

import { IEvent } from "./event-base";

@Injectable({
  providedIn: "root"
})
export class EventAggregator {
  private subject$ = new Subject<IEvent>();

  constructor() {}

  emit(event: IEvent, sender: string): void {
    if (!event) {
      throw Error("Event is required.");
    }
    if (!sender) {
      throw Error("Sender is required.");
    }
    this.subject$.subscribe();
    event.sender = sender;
    this.subject$.next(event);
  }

  on<T>(constructor: new (...args: any[]) => T): Observable<IEvent> {
    return this.subject$.pipe(
      filterNullMap(),
      filter((e: IEvent) => {
        return e instanceof constructor;
      }),
      tap((e: IEvent) => {
        e.handled.next(true);
      })
    );
  }
}
