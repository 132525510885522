import type { TemplateRef } from "@angular/core";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { FormlyJsonschema } from "@ngx-formly/core/json-schema";
import { formlyJsonschemaOptions, UiSchemaLayoutProvider } from "@vp-libs/formly/json-schema";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "vp-data-form-wrapper",
  templateUrl: "./data-form-wrapper.component.html",
  styleUrls: ["./data-form-wrapper.component.scss"],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation, , ,
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default
})
export class DataFormWrapperComponent implements OnChanges, OnDestroy {
  formSchemaObject: any;
  formLayoutObject: any;
  formOptionsObject: FormlyFormOptions = { formState: { disabled: false } };
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];

  @Input() debug = false;
  @Input() data: any;
  @Input() schema: any;
  @Input() layout: any = { customRenderer: { name: "auto" } };
  @Input() set readonly(value: boolean) {
    if (!this.formOptionsObject.formState) {
      this.formOptionsObject.formState = {};
    }
    // Force Formly to update editable form
    setTimeout(() => {
      this.formOptionsObject.formState.disabled = value;
    });
  }
  @Input() set options(value: FormlyFormOptions) {
    this.formOptionsObject = value ?? this.formOptionsObject;
  }
  @Input() showSubmit = false;
  @Input() dataChangeDebounceTime = 2000;

  @Output() readonly dataChange = new EventEmitter<Record<string, unknown>>();
  @Output() readonly dataSubmit = new EventEmitter<{
    data: Record<string, unknown>;
    form: FormGroup;
  }>();

  @ViewChild("dialogRef") dialogRef: undefined | TemplateRef<any> = undefined;

  get readonly(): boolean {
    return this.formOptionsObject.formState.disabled;
  }

  get options() {
    return this.formOptionsObject;
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly formlyJsonschema: FormlyJsonschema,
    private readonly layoutProvider: UiSchemaLayoutProvider
  ) {
    this.subscriptions.add(
      this.debouncerSubject.pipe(debounceTime(this.dataChangeDebounceTime)).subscribe({
        next: value => this.dataChange.emit(value)
      })
    );
  }

  private subscriptions = new Subscription();
  private debouncerSubject: Subject<Record<string, unknown>> = new Subject<
    Record<string, unknown>
  >();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.schema?.currentValue !== changes?.schema?.previousValue) {
      this.schema = changes.schema ? changes.schema.currentValue : this.schema;
      this.layout = changes.layout ? changes.layout.currentValue : this.layout;

      if (this.schema && this.layout) {
        this.layoutProvider.applyScopes("wrapper", this.schema).subscribe((schema: any) => {
          this.fields = [this.formlyJsonschema.toFieldConfig(schema, formlyJsonschemaOptions)];
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  formSubmitHandler(): void {
    this.dataSubmit.emit({ data: this.data, form: this.form });
  }

  modelChangeHandler(): void {
    this.debouncerSubject.next(this.data);
  }

  showDialogHandler(): void {
    if (this.dialogRef) {
      this.dialog.open(this.dialogRef, {
        data: {
          data: this.data,
          schema: this.formSchemaObject,
          layout: this.formLayoutObject
        }
      });
    }
  }
}
