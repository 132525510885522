import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { ObservableStore } from "@codewithdan/observable-store";
import { Select } from "@ngxs/store";
import {
  AppState,
  Department,
  Organization,
  Profile,
  Role,
  ui,
  User,
  UserRole
} from "@vp/core/models";
import { OrganizationState } from "@vp/data-access/organization";
import { filterNullMap } from "@vp/shared/operators";
import { UserApiService } from "@vp/shared/store/user";
import { createPatch, Operation } from "rfc6902";
import { combineLatest, EMPTY, Observable, of } from "rxjs";
import { map, switchMap, take, tap, withLatestFrom } from "rxjs/operators";

export const APP_STORE_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export const TAG_PROFILE_COMPLETE = "profile.complete";

@Injectable({
  providedIn: "root"
})
export class AppStoreService extends ObservableStore<AppState> {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  // TODO change "computed" to observable as this does not play nice with ng
  get isUserProfileComplete() {
    const user = this.getState()?.user;
    return user?.tags?.includes(TAG_PROFILE_COMPLETE);
  }

  get userStream() {
    return this.stateChanged.pipe(map(state => state.user));
  }

  get user() {
    return this.getState().user;
  }

  get user$() {
    const { user } = this.getState();
    if (user) {
      return of(user);
    }
    return EMPTY;
  }

  selectedRole = this.stateChanged.pipe(
    map((appState: AppState) => appState.user),
    filterNullMap(),
    map((user: User) => user.roles?.find(r => r.roleId === user.selectedRoleId)),
    filterNullMap(),
    map((userRole: UserRole) =>
      this.getState().userRoles?.find(r => r.roleId === userRole?.roleId)
    ),
    filterNullMap()
  );

  selectedRoleDisplayName$ = this.selectedRole.pipe(map(userRole => userRole.displayName));

  userFullName$ = this.stateChanged.pipe(
    map(state => state.user),
    map(user => `${user?.profile?.firstName} ${user?.profile?.lastName}`)
  );

  userRoles$ = this.stateChanged.pipe(map(state => state.userRoles));

  userDepartments$ = this.userStream.pipe(
    filterNullMap(),
    map(user => user.roles.find(role => role.roleId === user.selectedRoleId)),
    map(userRole => userRole?.departments ?? []),
    withLatestFrom(this.organization$),
    map(([userDept, org]) => {
      let userDepartmentIds = userDept.map(d => d.departmentId);
      return org.departments.filter(d => userDepartmentIds.includes(d.departmentId));
    })
  );

  constructor(
    @Inject(APP_STORE_API_BASE_URL) private _apiBaseUrl: string,
    private http: HttpClient,
    private userApiService: UserApiService
  ) {
    super({ trackStateHistory: true });
    const initialState: AppState = {
      caseTypes: [],
      groups: [],
      user: null,
      userDepartments: [],
      userRoles: [],
      organization: null,
      ui
    };
    this.setState(initialState, "INIT_STATE");
  }

  /**
   * @deprecated Use `selectedRole` observable for all selected role operations for improved role switcher
   */
  getSelectedRole(): UserRole | undefined {
    const { user } = this.getState();
    return user?.roles?.find(r => r.roleId === user?.selectedRoleId);
  }

  loadUser(userId: string) {
    return this.userApiService.getUser(userId).pipe(
      tap((user: User) => {
        this.setState({ user }, "UPDATE USER");
      })
    );
  }

  loadLoginUser() {
    return this.userApiService.getUserLogin().pipe(
      tap((user: User) => {
        this.setState({ user }, "UPDATE USER");
      })
    );
  }

  removeUser() {
    this.setState({ user: null }, "REMOVE_USER");
  }

  setUser(user: User) {
    this.setState({ user }, "SET_USER");
  }

  /**
   * This set up call depends on both the user and organization to be loaded already
   */
  setUserDepartments(user: User) {
    const selectedRole = user?.roles?.find(r => r.roleId === user.selectedRoleId);
    const organization = this.getState().organization;
    const departments: Department[] = [];

    selectedRole?.departments.forEach(rd => {
      const department = organization?.departments.find(od => od.departmentId === rd.departmentId);
      if (department) {
        departments.push(...[department]);
      }
    });
    this.setState({ userDepartments: departments }, "SET_USERDEPARTMENTS", false, false);
  }

  setOrganization(organization: Organization) {
    this.setState({ organization: organization });
  }

  /**
   * This set up call depends on both the user and organization to be loaded already
   */
  setUserRoles() {
    const { user, organization } = this.getState();
    if (!user || !organization) {
      throw new Error("No user or organization found");
    }
    const roles: Role[] = [];
    this.findRolesForOrganization(user, organization, roles);

    this.setState({ userRoles: roles }, "SET_USERROLES");
  }

  private findRolesForOrganization(user: User, organization: Organization, roles: Role[]) {
    user.roles?.forEach(r => {
      const role = organization?.roles.find(
        organizationRole => organizationRole.roleId === r.roleId
      );
      if (role) {
        roles.push(role);
      }
    });
  }

  updateProfile(profile: Profile) {
    const { user } = this.getState();
    if (!user) {
      throw new Error("No user found");
    }
    user.profile = profile;
    return this.userApiService.updateUser(user).pipe(
      map(updatedUser => updatedUser.userId),
      switchMap(userId => this.updateOrInsertTag(userId, TAG_PROFILE_COMPLETE)),
      switchMap(profileUpdated => {
        //reload user after update user tag
        return this.loadUser(user.userId).pipe(map(_ => profileUpdated));
      })
    );
  }

  updateOrInsertTag(userId: string, tag: string): Observable<boolean> {
    const apiURL = `${this._apiBaseUrl}/user/${userId}/tags/${tag}`;

    return this.http
      .put(apiURL, null, {
        observe: "response",
        responseType: "text"
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  }

  updateUser(user: User) {
    return this.userApiService.updateUser(user).pipe(
      tap((user: User) => {
        this.setState({ user }, "SET_USER");
      })
    );
  }

  patchUser(updated: User): Observable<User> {
    return combineLatest([this.user$.pipe(filterNullMap()), of(updated)]).pipe(
      map(([original, changed]: [User, User]) => {
        return {
          userId: changed.userId,
          operations: createPatch(original, changed)
        };
      }),
      switchMap((userOperations: { userId: string; operations: Operation[] }) =>
        this.userApiService
          .patch(userOperations.userId, userOperations.operations)
          .pipe(switchMap(() => this.loadUser(userOperations.userId)))
      ),
      take(1)
    );
  }

  updateUserRole(roleId: string) {
    const { user } = this.getState();
    if (!user) {
      throw new Error("No user or organization found");
    }
    user.selectedRoleId = roleId;

    return this.updateUser(user).pipe(
      withLatestFrom(this.organization$),
      tap(([updatedUser, organization]) => {
        const roles: Role[] = [];
        this.findRolesForOrganization(updatedUser, organization, roles);
        this.setState(
          {
            userRoles: roles
          },
          "SET_USERROLES"
        );
        // reset user departments for the updated role
        this.setUserDepartments(updatedUser);
      })
    );
  }

  usersViewObject(page: string, viewType: "list" | "grid" | "table" = "list") {
    return this.selectedRole.pipe(
      map(role => {
        const selectedRoleId = role?.roleId;
        const orgRoleForUser =
          this.getState().userRoles.find(role => role.roleId === selectedRoleId) ?? ({} as Role);
        const views = orgRoleForUser?.views as Record<string, unknown> | undefined;
        const viewTypes = views ? (views[page] as Record<string, unknown> | undefined) : undefined;
        const firstViewType = viewTypes
          ? (viewTypes[Object.keys(viewTypes)[0]] as string)
          : undefined;
        const viewName = viewTypes ? (viewTypes[viewType] as string) : undefined;
        return {
          viewName: viewName ? viewName : firstViewType,
          viewTypesAvailable: viewTypes ? Object.keys(viewTypes) : undefined
        };
      })
    );
  }
}
