export enum OrganizationFeatures {
  virtualCare = "virtualCare",
  common = "common",
  signalR = "signalR",
  analytics = "analytics",
  autoConfirmDialogues = "autoConfirmDialogues",
  toastrPositionClass = "toastrPositionClass",
  feeBasedServices = "feeBasedServices",
  paymentsInSubmit = "paymentsInSubmit",
  caseDashboardAsSinglePage = "caseDashboardAsSinglePage",
  multiOpinionResponse = "multiOpinionResponse"
}
