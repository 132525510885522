import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TrackByFunction
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectionListChange } from "@angular/material/list";
import { UserAdministrationService } from "@vp/administration/user/data-access/user-administration-service";
import { AssignedRolePerDepartment, Column } from "@vp/administration/user/feature";
import { UserRole } from "@vp/core/models";
import { RolesAssignmentService } from "@vp/shared/assignments/data-access/roles";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { AppStoreService } from "@vp/shared/store/app";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { concatMap, map, mergeMap, switchMap, take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "vp-user-assign-roles",
  templateUrl: "./user-assign-roles.component.html",
  styleUrls: ["./user-assign-roles.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RolesAssignmentService]
})
export class UserAssignRolesComponent implements OnInit, OnDestroy {
  filters: Record<string, unknown> | undefined = undefined;
  excludeProperties: string[] = [];
  departmentSelector = new FormControl();
  columns$!: Observable<Column[]>;
  items$!: Observable<AssignedRolePerDepartment[]>;
  search$!: Observable<string | null>;
  selected$!: Observable<AssignedRolePerDepartment[]>;
  columns = [
    {
      field: "department",
      header: "Department"
    },
    {
      field: "role",
      header: "Role"
    }
  ];

  // Abastract
  departments$!: Observable<string[]>;

  private readonly destroyed$ = new Subject();
  private readonly columns$$ = new BehaviorSubject<Column[]>([]);
  private readonly search$$ = new BehaviorSubject<string | null>(null);
  private readonly selected$$ = new BehaviorSubject<AssignedRolePerDepartment[]>([]);
  private readonly filters$$ = new BehaviorSubject<string[]>([]);

  constructor(
    private readonly appStore: AppStoreService,
    public permConst: PermissionsConstService,
    private rolesAssignmentService: RolesAssignmentService,
    private userAdministrationService: UserAdministrationService,
    private readonly ngxPermissionsService: NgxPermissionsService
  ) {
    this.search$ = this.search$$.asObservable();
    this.selected$ = this.selected$$.asObservable();

    this.columns$ = this.columns$$.pipe(
      switchMap((columns: Column[]) => {
        return combineLatest([
          of(columns),
          this.ngxPermissionsService.hasPermission([this.permConst.Case.Assign.User.Write])
        ]);
      }),
      map(([columns, hasWritePermissions]) => {
        if (hasWritePermissions) {
          var cols = [...columns];
          cols.push({
            field: "actions",
            header: "Actions"
          } as Column);
          return cols;
        }
        return columns;
      })
    );

    this.items$ = this.rolesAssignmentService.assignableTags$.pipe(
      mergeMap((assignable: AssignedRolePerDepartment[]) => {
        return combineLatest([of(assignable), this.filters$$]);
      }),
      map(([assignable, filters]: [AssignedRolePerDepartment[], string[]]) => {
        if (filters.length > 0) {
          return assignable.filter(item => filters.includes(item.departmentId));
        }
        return assignable;
      }),
      takeUntil(this.destroyed$)
    );

    // TODO: Abstract this into its own component
    this.departments$ = this.appStore.userStream.pipe(
      filterNullMap(),
      map(user => user.roles),
      map((userRoles: UserRole[]) => {
        return userRoles
          .reduce((depts: string[], role: UserRole) => {
            let departments = role.departments.map(d => d.departmentId);
            depts = depts.concat(departments);
            return depts;
          }, [])
          .filter((value: string, index: number, array: string[]) => {
            return array.indexOf(value) === index;
          });
      })
    );
  }

  ngOnInit(): void {
    this.columns$$.next(this.columns);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  searched(event: any) {
    this.search$$.next(event);
  }

  assignSelected() {
    this.selected$
      .pipe(
        concatMap((data: AssignedRolePerDepartment[]) => {
          return this.userAdministrationService.addDepartmentRoles$(data);
        }),
        take(1)
      )
      .subscribe();
  }

  selectionChanged(event: MatSelectionListChange) {
    this.selected$$
      .pipe(
        take(1),
        map(selected => [...selected]),
        tap(selected => {
          if (event.options[0].selected) {
            selected.push(event.options[0].value);
          } else {
            const index = selected.indexOf(event.options[0].value);
            selected.splice(index, 1);
          }
        })
      )
      .subscribe(stillSelected => {
        this.selected$$.next(stillSelected);
      });
  }

  departmentsFilterChanged = (selected: string[]) => {
    this.filters$$.next(selected);
  };

  isSelected(item: AssignedRolePerDepartment): Observable<boolean> {
    return this.selected$$.pipe(
      map(
        (selected: AssignedRolePerDepartment[]) =>
          selected.findIndex(
            i => i.roleId === item.roleId && i.departmentId === item.departmentId
          ) > -1
      )
    );
  }

  trackById: TrackByFunction<AssignedRolePerDepartment> = (
    _: number,
    item: AssignedRolePerDepartment
  ) => item.roleId + item.departmentId;
}
