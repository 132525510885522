import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseFilePdfExtract } from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { FileApiService } from "../../../services/api/file-api.service";

export interface Page {
  value: string;
  index: number;
  selected: boolean;
}

@Component({
  selector: "vp-extract-pdf-dialog",
  templateUrl: "./extract-pdf-dialog.component.html",
  styleUrls: ["./extract-pdf-dialog.component.scss"]
})
export class ExtractPdfDialogComponent implements OnInit {
  fileName: string;
  url!: string;
  caseId: string;
  newFileName!: string;
  pages: Page[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileService: FileApiService,
    private notifications: NotificationService,
    private dialogRef: MatDialogRef<ExtractPdfDialogComponent>
  ) {
    this.fileName = this.data.pdf.fileName;
    this.caseId = this.data.caseId;
  }

  ngOnInit(): void {
    this.fileService
      .getReadOnlySecurelink(this.caseId, this.fileName, false)
      .subscribe(secureLink => (this.url = secureLink));
    this.fileService.getPdfPageCount(this.caseId, this.fileName).subscribe(pageCount => {
      if (pageCount) {
        for (let i = 1; i <= pageCount; i++) {
          this.pages.push({ value: `Page ${i}`, index: i, selected: false } as Page);
        }
      }
    });
  }

  extractPdf() {
    const today = new Date();
    const mm = today.getMonth() + 1; // January is 0...
    const dd = today.getDate();
    const fileName = this.fileName.split(".").slice(0, -1).join("."); //remove filename extension

    this.newFileName =
      this.newFileName ||
      `${fileName}_extract_${today.getFullYear()}${mm.toString().padStart(2, "0")}${dd
        .toString()
        .padStart(2, "0")}`;
    this.newFileName += ".pdf";

    const selectedPages = this.pages.filter(i => i.selected).map(p => p.index);
    if (selectedPages.length > 0) {
      const pdfExtract = {
        fileName: this.newFileName,
        fileDescription: this.data.pdf.fileDescription,
        extractPages: selectedPages,
        extractFromUrl: this.url
      } as CaseFilePdfExtract;
      this.fileService.extractPdfPages(this.caseId, pdfExtract).subscribe(caseFile => {
        if (!!caseFile) {
          this.notifications.successMessage("Successfully extracted PDF");
          this.dialogRef.close(caseFile);
        } else {
          this.notifications.errorMessage("Failed to extract PDF");
        }
      });
    }
  }
}
