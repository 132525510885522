import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { tap } from "rxjs/operators";
import { FeatureConfig, FeatureFlagsService } from "./feature-flag.service";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[featureEnabled]"
})
export class FeatureEnabledDirective implements OnInit {
  @Input() set featureEnabled(val: string) {
    if (val) {
      this._featureEnabled = val;
    }
  }
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private featureFlagsService: FeatureFlagsService
  ) {}

  private _featureEnabled = "";

  ngOnInit() {
    this.featureFlagsService
      .feature$(this._featureEnabled)
      .pipe(
        tap((data: FeatureConfig | null) => {
          if (data !== null && data["enabled"]) {
            this.viewContainerRef.createEmbeddedView(this.templateRef, {
              $implicit: data
            });
          } else {
            this.viewContainerRef.clear();
          }
        })
      )
      .subscribe();
  }
}
