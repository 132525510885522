import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Ajv } from "ajv";

export const AJV_INSTANCE = new InjectionToken<Ajv>("The AJV Class Instance");
export const AJV_CLASS = new InjectionToken<Ajv>("The AJV Class Instance");
export const AJV_CONFIG = new InjectionToken<Ajv>("The AJV Class config");

export interface ValidateResult {
  isValid: boolean;
  errorsText: string;
}

@Injectable()
export class JsonSchemaValidationService {
  constructor(@Inject(AJV_INSTANCE) private readonly ajv: Ajv) {}

  /**
   * Fetches the Schema and adds it to the validator schema set
   * @param name The name of the schema, this will be used as the key to store it
   * @param urlPath The URL path of the schema to load
   */
  public loadSchema(schema: any, name: string): void {
    if (!this.ajv.getSchema(name)) {
      this.ajv.addSchema(schema, name);
    }
  }

  /**
   * Validate data against a schema
   * @param name The name of the schema to validate
   * @param data The data to validate
   */
  public validateData<T>(name: string, data: T): ValidateResult {
    const isValid = this.ajv.validate(name, data) as boolean;
    return { isValid, errorsText: this.ajv.errorsText() };
  }
}
