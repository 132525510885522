/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from "@ngx-formly/core";

export function minItemsValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should NOT have fewer than ${field.templateOptions?.minItems} items`;
}

export function maxItemsValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should NOT have more than ${field.templateOptions?.maxItems} items`;
}

export function minlengthValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should NOT be shorter than ${field.templateOptions?.minLength} characters`;
}

export function maxlengthValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should NOT be longer than ${field.templateOptions?.maxLength} characters`;
}

export function minValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should be >= ${field.templateOptions?.min}`;
}

export function maxValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should be <= ${field.templateOptions?.max}`;
}

export function multipleOfValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should be multiple of ${field.templateOptions?.step}`;
}

export function exclusiveMinimumValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should be > ${field.templateOptions?.step}`;
}

export function exclusiveMaximumValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should be < ${field.templateOptions?.step}`;
}

export function constValidationMessage(_error: any, field: FormlyFieldConfig) {
  return `should be equal to constant "${field.templateOptions?.const}"`;
}
