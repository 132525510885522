import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DateValueChange } from "./date-value-change.model";

@Injectable()
export class DateFilterService {
  private dateFilterChange = new BehaviorSubject<DateValueChange>({} as DateValueChange);
  public dateValueChange = this.dateFilterChange.asObservable();

  public updateDateFilters(startDate: Date, endDate: Date) {
    this.dateFilterChange.next({ startDate, endDate });
  }
}
