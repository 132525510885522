/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  ElementRef,
  HostListener,
  SimpleChange,
  OnChanges,
  Self,
  Optional
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Directive({
  selector: "[json]"
})
export class JsonInputAccessorDirective implements ControlValueAccessor, OnChanges {
  onChange: (value: string) => void;
  onTouched: any;

  constructor(@Self() @Optional() private control: NgControl, private elementRef: ElementRef) {
    this.control.valueAccessor = this;
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    this.onChange(changes["controlValue"].currentValue);
  }

  @HostListener("input", ["$event.target.value"])
  onInput = (value: string) => {
    this.onChange(value);
  };

  @HostListener("focusout", ["$event.target.value"])
  onBlur(value: string) {
    this.onTouched(value);
  }

  writeValue(value: any) {
    this.elementRef.nativeElement.value = JSON.stringify(value, null, 4);
  }

  registerOnChange(fn: any) {
    this.onChange = value => {
      try {
        var v = JSON.parse(value);
        this.control.control.setErrors(null);
        fn(v);
      } catch (err) {
        this.control.control.setErrors({ invalidJson: true });
      }
    };
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
