import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FieldWrapper, FormlyFieldConfig } from "@ngx-formly/core";

export interface ModalWrapperDialogData {
  title: string;
  fields: FormlyFieldConfig | FormlyFieldConfig[];
  model: any;
}

@Component({
  selector: "lib-modal-wrapper",
  templateUrl: "./modal-wrapper.component.html",
  styleUrls: ["./modal-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalWrapperComponent extends FieldWrapper implements OnInit {
  formControl!: FormControl;
  snapshot: any = {};

  @ViewChild("modalWrapper", { static: true }) modalWrapper: TemplateRef<any>;

  constructor(public dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.snapshot = this.formControl.value;
  }

  onClose() {
    this.formControl.reset(this.snapshot);
    this.dialog.closeAll();
  }

  onCommit = () => {
    if (this.formControl.value) {
      this.dialog.closeAll();
      this.snapshot = this.formControl.value;
    }
  };

  onClick() {
    this.dialog
      .open(this.modalWrapper, {
        width: `${this.to.width}`
      })
      .afterClosed()
      .subscribe();
  }
}
