import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@vp/shared/authentication";
import { Observable } from "rxjs";
import { IS_IVY_API } from "../../app-initializer.factory";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = new URL(request.url);

    //only add APIM and Token headers if its our API
    if (!environment.oktaApiRoutes.find(x => url.origin.includes(x))) {
      return next.handle(request);
    }

    //handle Ivy Token headers
    if (this.isIvyApi) {
      let apiPath = request.url;
      if (url.hostname !== "localhost") {
        const rootPath = url.pathname.split("/")[1];
        apiPath = request.url.replace(rootPath, environment.ivySuffix);
      }

      const ivyRequest = request.clone({
        url: apiPath,
        headers: request.headers
          .set("Ocp-Apim-Subscription-Key", environment.ivyApimSubscriptionKey)
          .set("Ocp-Apim-Trace", environment.apimTrace)
          .set("Authorization", "Bearer " + this.authenticationService.generateIvyToken())
      });
      return next.handle(ivyRequest);
    }

    this.authenticationService.isLoggedIn$().subscribe(isAuthenticated => {
      if (!isAuthenticated && !this.isIvyApi) {
        if (
          request.urlWithParams.includes("callback") ||
          request.urlWithParams.includes("silent-renew")
        ) {
          this.authenticationService.login();
        }

        if (!this.authenticationService.isLoggingOut) {
          this.router.navigate(["/home"]);
        }
      }
    });

    // Clone the request to add the new header
    const clonedRequest = request.clone({
      headers: request.headers
        .set("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)
        .set("Ocp-Apim-Trace", environment.apimTrace)
        .set("Authorization", "Bearer " + this.authenticationService.getAccessToken())
        .set("x-ui-local-offset", new Date().getTimezoneOffset().toString())
    });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
