import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction,
  ViewChild
} from "@angular/core";
import { MatDrawer, MatDrawerContent } from "@angular/material/sidenav";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AuthenticationService } from "@vp/shared/authentication";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { AppStoreService } from "@vp/shared/store/app";
import { UiStoreService } from "@vp/shared/store/ui";
import { activatedRouteData } from "@vp/shared/utilities";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { CaseContextService } from "../../services/case-context/case-context.service";
import { DrawerContentScrollService } from "../../services/drawer-content-scroll/drawer-content-scroll.service";
import { Logger } from "../../services/logging/logging.service";
import { NavigationItem } from "./navigation-item";
import { SideNavService } from "./side-nav.service";

@Component({
  selector: "vp-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroyed$ = new Subject();
  private readonly _opened$ = new BehaviorSubject<boolean>(true);
  private readonly _showToggle$ = new BehaviorSubject<boolean>(true);
  private readonly _menuMode$ = new BehaviorSubject<MenuMode>("side");

  @Output() readonly navigationItemClickHandler = new EventEmitter<NavigationItem>();
  @ViewChild("drawer") public drawer!: MatDrawer;
  @ViewChild("content") public content!: MatDrawerContent;

  constructor(
    private logger: Logger,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sideNavService: SideNavService,
    private ngxPermissionsService: NgxPermissionsService,
    private breakpointObserver: BreakpointObserver,
    public readonly permConst: PermissionsConstService,
    public uiStoreService: UiStoreService,
    public appStoreService: AppStoreService,
    public readonly authenticationService: AuthenticationService,
    private readonly drawerContentScroll: DrawerContentScrollService,
    private caseContextService: CaseContextService
  ) {}

  navigationItems: NavigationItem[] = [];
  menuMode = this._menuMode$.asObservable();
  opened = this._opened$.asObservable();
  showToggle = this._showToggle$.asObservable();
  singlePage = this.router.events.pipe(
    activatedRouteData(this.activatedRoute),
    map(data => {
      this._opened$.next(data.isSinglePage !== true);
      return data.isSinglePage === true;
    }),
    takeUntil(this.destroyed$)
  );

  mobile = [
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.TabletPortrait,
    Breakpoints.WebPortrait
  ];

  desktop = [
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
    Breakpoints.WebLandscape,
    Breakpoints.TabletLandscape
  ];

  ngOnInit(): void {
    this.breakpointObserver
      .observe(this.mobile)
      .pipe(filter((state: BreakpointState) => state.matches))
      .subscribe(_ => {
        this._opened$.next(false);
        this._showToggle$.next(true);
        this._menuMode$.next("over");
      });

    this.breakpointObserver
      .observe(this.desktop)
      .pipe(filter((state: BreakpointState) => state.matches))
      .subscribe(_ => {
        this._opened$.next(true);
        this._showToggle$.next(false);
        this._menuMode$.next("side");
      });

    // Force browser to scroll to top any time a route changes from this component
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: () => {
          window.scrollTo(0, 0);
        },
        error: error => this.logger.logException(error, "SideNavComponent.ngOnInit.router.events")
      });

    this.sideNavService.navigationItemsChanged
      .pipe(
        tap((items: NavigationItem[]) => {
          this.navigationItems = items;
        })
      )
      .subscribe({
        error: error =>
          this.logger.logException(
            error,
            "SideNavComponent.ngOnInit.sideNavService.navigationItemsChanged"
          )
      });

    // Display nav when permissions have resolved
    this.ngxPermissionsService.permissions$
      .pipe(
        filter(data => {
          return Object.keys(data).length > 0;
        }),
        switchMap(() => this.activatedRoute.data)
      )
      .pipe(
        tap(data => {
          this.navigationItems = data.navItems;
        })
      )
      .subscribe({
        error: error => this.logger.logException(error)
      });

    this.caseContextService.Context.pipe(
      tap(caseData => {
        if (caseData?.communicationCounts) {
          const currentUserRole = this.appStoreService.getSelectedRole();
          const commCounts = caseData.communicationCounts.find(
            c => c.roleId === currentUserRole?.roleId
          );

          if (this.navigationItems?.length > 0 && commCounts) {
            const navItem = this.navigationItems.find(
              i => i.friendlyName === "case-communications"
            );
            if (navItem) {
              if (commCounts?.unreadCount > 0 || commCounts?.unresolvedCount > 0) {
                navItem.showActionIndicator = true;
              } else {
                navItem.showActionIndicator = false;
              }
            }
          }
        }
      })
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.drawerContentScroll.trackAnchors();
  }

  toggleSideNav() {
    this._opened$.next(!this._opened$.getValue());
    this.drawer?.toggle(this._opened$.getValue());
  }

  navigationItemClicked = (item?: NavigationItem) => {
    if (item && item.enabled) {
      for (const index in this.navigationItems) {
        if (
          this.navigationItems.hasOwnProperty(index) &&
          this.navigationItems[index].hasOwnProperty("active") &&
          this.navigationItems[index].hasOwnProperty("key")
        ) {
          this.navigationItems[index].active = this.navigationItems[index].key === item.key;
        }
      }
      if (item.routerLink && this.breakpointObserver.isMatched(this.mobile)) {
        this._opened$.next(false);
      } else {
        this.navigationItemClickHandler.emit(item);
      }
    } else {
      this._opened$.next(false);
    }
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  trackByKey: TrackByFunction<NavigationItem> = (_index: number, item: NavigationItem) => item.key;
}

export type MenuMode = "over" | "push" | "side";
export type PageMode = "desktop" | "mobile";
