import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";
import { mergeDeep } from "@vp-libs/formly/json-schema";

@Component({
  selector: "lib-formly-flex-input-wrapper",
  templateUrl: "./formly-flex-input-wrapper.component.html",
  styleUrls: ["./formly-flex-input-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFlexInputWrapperComponent extends FieldWrapper implements OnInit {
  flexTo: any = {};
  fxFlexDefault = {
    fxFlex: "100",
    fxFlexOffset: "0"
  };

  ngOnInit(): void {
    this.flexTo = mergeDeep(this.fxFlexDefault, this.to, "replace");
  }
}
