/* eslint-disable prefer-arrow/prefer-arrow-functions */
export const excludedFileTypes =
  // eslint-disable-next-line max-len
  "ade;adp;app;asa;ashx;asmx;asp;bas;bat;cdx;cer;chm;class;cmd;com;config;cpl;crt;csh;dll;exe;fxp;hlp;hta;htr;htw;ida;idc;idq;inf;ins;isp;its;jar;js;jse;ksh;lnk;mad;maf;mag;mam;maq;mar;mas;mat;mau;mav;maw;mda;mdb;mde;mdt;mdw;mdz;msc;msh;msh1;msh1xml;msh2;msh2xml;mshxml;msi;msp;mst;ops;pcd;pif;prf;prg;printer;pst;reg;rem;scf;scr;sct;shb;shs;shtm;shtml;soap;stm;tmp;url;vb;vbe;vbs;vsmacros;vss;vst;vsw;ws;wsc;wsf;wsh";

const getFileExtension = (filename: string) => {
  return filename.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;
};

const fileIsOverLimit = (file: File) => {
  const mb = file.size / (1024 * 1024);
  return mb >= 20;
};

const fileIsWrongType = (file: File) => {
  const excludedTypes = excludedFileTypes.split(";");
  const extension = getFileExtension(file.name);
  return excludedTypes.findIndex(x => x === extension) !== -1;
};

export type FileUploadError = "size" | "type";

export function LoadFileToBase64(file: File): Promise<string> | Promise<FileUploadError> {
  return new Promise(
    (resolve: (value: string) => void, reject: (reason: FileUploadError) => void) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result as string);
      };

      if (fileIsOverLimit(file)) {
        reject("size");
      }

      if (fileIsWrongType(file)) {
        reject("type");
      }

      reader.readAsDataURL(file);
    }
  );
}

export function removeBase64Header(content: string) {
  return content?.split(",")[1];
}

export function removeFileExtension(fileName: string) {
  var lastDotPosition = fileName.lastIndexOf(".");
  if (lastDotPosition === -1) return fileName;
  else return fileName.substring(0, lastDotPosition);
}
