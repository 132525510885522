import { Injectable } from "@angular/core";
import { AlertItem } from "../components/alert/alert.component";
import { Store } from "rxjs-observable-store";

export class JsonUiState {
  errorMessages: null | string | string[] | AlertItem[] = null;
  options: Record<string, unknown> = {};
  readonly = true;
  showSubmit = false;
}

@Injectable()
export class JsonUiStoreService extends Store<JsonUiState> {
  constructor() {
    super(new JsonUiState());
  }

  errorMessages = this.onChanges("errorMessages");
  options = this.onChanges("options");
  readonly = this.onChanges("readonly");
  showSubmit = this.onChanges("showSubmit");

  setErrorMessages(errorMessages: null | string | string[] | AlertItem[]) {
    this.patchState(errorMessages, "errorMessages");
  }

  setOptions(options: Record<string, unknown>) {
    this.patchState(options, "options");
  }

  setReadonly(readonly: boolean) {
    this.patchState(readonly, "readonly");
  }

  setShowSubmit(showSubmit: boolean) {
    this.patchState(showSubmit, "showSubmit");
  }
}
