import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Sort } from "@angular/material/sort";
import { UserAdministrationService } from "@vp/administration/user/data-access/user-administration-service";
import { GroupsAssignmentService } from "@vp/case-assignments/data-access/groups";
import { Group } from "@vp/core/models";
import { ASSIGNMENT_MODAL_OPTIONS } from "@vp/shared/assignments/models";
import {
  DialogData,
  DialogFactoryService,
  DialogService
} from "@vp/shared/components/generic-dialog";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { GroupTypePipe } from "@vp/shared/pipes/context-display";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-user-assigned-groups",
  templateUrl: "./user-assigned-groups.component.html",
  styleUrls: ["./user-assigned-groups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GroupsAssignmentService,
    {
      provide: ASSIGNMENT_MODAL_OPTIONS,
      useValue: {
        columns: [
          {
            field: "displayName",
            header: "Name"
          },
          {
            field: "groupTypeDisplayName",
            header: "Group Type"
          },
          {
            field: "description",
            header: "Description"
          }
        ],
        title: "Assign Groups",
        config: {
          disableClose: true,
          autoFocus: false,
          closeOnNavigation: true,
          width: "60vw"
        }
      }
    }
  ]
})
export class UserAssignedGroupsComponent implements OnDestroy {
  @ViewChild("modalTemplate", { static: false }) modalTemplate!: TemplateRef<any>;
  dialog!: DialogService;
  assigned$: Observable<Group[]>;
  columns = [
    {
      field: "groupTypeId",
      header: "Group Type",
      pipe: GroupTypePipe
    },
    {
      field: "displayName",
      header: "Name"
    },
    {
      field: "actions",
      header: "Delete"
    }
  ];

  private readonly destroyed$ = new Subject();
  private readonly sortSubject$ = new BehaviorSubject<Sort>({
    direction: "asc",
    active: "groupDisplayName"
  });

  constructor(
    public permConst: PermissionsConstService,
    private userAdministrationService: UserAdministrationService,
    private dialogFactoryService: DialogFactoryService,
    private readonly groupsAssignmentService: GroupsAssignmentService
  ) {
    this.assigned$ = this.groupsAssignmentService.assigned$.pipe(takeUntil(this.destroyed$));
  }

  assignHandler = () => {
    this.openDialog({
      title: "Assign Groups",
      template: this.modalTemplate
    });
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  unassignHandler = (e: Group) => {
    this.userAdministrationService.deleteGroup$(e.groupId).subscribe();
  };

  sortHandler = (sort: Sort): void => {
    this.sortSubject$.next(sort);
  };

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, {
      width: "70vw",
      disableClose: false
    });
    this.dialog.closed$.subscribe();
  }
}
