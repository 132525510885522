import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { Organization, Tag } from "@vp/core/models";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { Observable } from "rxjs";
import { map, shareReplay, withLatestFrom } from "rxjs/operators";

@Pipe({
  name: "tagDisplay"
})
export class TagDisplayPipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  public transform(tagId: string): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      withLatestFrom(this.tags$.pipe(shareReplay())),
      map(([org, tags]: [Organization, Tag[]]) => {
        return getTagDisplay(tagId, tags, org);
      })
    );
  }
}

export const getTagDisplay = (tagId: string, tags: Tag[], org: Organization) => {
  let tagDisplayName = "[tag not found]";
  let tagTypeDisplayName = "[tag type  not found]";
  const tag = tags.find(t => t.tagId === tagId);
  if (tag !== undefined) {
    tagDisplayName = tag.displayName;
    const tagType = org.tagTypes.find(r => r.tagTypeId === tag.tagTypeId);
    if (tagType !== undefined) {
      tagTypeDisplayName = tagType.displayName;
    }
  }
  return `${tagTypeDisplayName}: ${tagDisplayName}`;
};
