import { InjectionToken } from "@angular/core";
import { MetaDefinition } from "@angular/platform-browser";

export const THEME_MANAGER_CONFIG = new InjectionToken<ThemeManagerConfig>("THEME_MANAGER_CONFIG", {
  providedIn: "root",
  factory: () => ({} as ThemeManagerConfig)
});

export interface HostnameSettings {
  title: string;
  metas: MetaDefinition[];
  bodyClass: string;
  styles: string[];
  logoUrl: string;
  logoRedirectUrl: string;
  faviconUrl: string;
  googleTagManagerId: string;
}

export interface ThemeManagerConfig {
  hostnames?: {
    [x: string]: Partial<HostnameSettings>;
  };
  debug?: {
    hostname?: string;
  };
}
