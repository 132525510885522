import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Organization, PageResult, Template } from "@vp/core/models";
import { Operation } from "rfc6902";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class OrganizationApiService {
  constructor(private _http: HttpClient, @Inject("environment") private environment: any) {}

  public getSingleOrganization = (
    organizationId: string,
    includeDetail?: boolean
  ): Observable<Organization> => {
    const apiURL = `${this.environment.baseApi}/organization/${organizationId}?includeDetail=${
      includeDetail || false
    }`;
    return this._http.get<Organization>(apiURL);
  };

  public getOrganization = (): Observable<Organization> => {
    const apiURL = `${this.environment.baseApi}/organization`;
    return this._http.get<Organization>(apiURL);
  };

  public patch = (operations: Operation[]) => {
    const apiURL = `${this.environment.baseApi}/organization`;
    return this._http.patch<Organization>(apiURL, operations);
  };

  public getOrganizationsPaged = (
    take: number = 10,
    skip: number = 0,
    sort: string,
    sortdirection: boolean,
    search: string
  ): Observable<PageResult<Organization>> => {
    let apiURL = `${this.environment.baseApi}/organization/?take=${take}&skip=${skip}`;
    if (sort) {
      apiURL = apiURL + `&sort=${sort}`;
    }
    if (sort && sortdirection) {
      apiURL = apiURL + `&sortdirection=${sortdirection}`;
    }
    if (search) {
      apiURL = apiURL + `&search=${search}`;
    }

    return this._http
      .get<PageResult<Organization>>(apiURL, {
        observe: "response"
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          const total: number = Number(response.headers.get("X-Paging-TotalRecordCount")).valueOf();
          return {
            results: response.body as Organization[],
            totalRecords: total
          } as PageResult<Organization>;
        })
      );
  };

  public getTemplates = (): Observable<null | Template[]> => {
    let apiURL = `${this.environment.baseApi}/template`;

    return this._http
      .get<null | Template[]>(apiURL, {
        observe: "response"
      })
      .pipe(
        map((response: HttpResponse<null | Template[]>) => {
          if (response.status === 200) {
            return response.body as Template[];
          }
          return null;
        })
      );
  };
}
