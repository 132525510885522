import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NavigationCancel, Router } from "@angular/router";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import { FormlyModule } from "@ngx-formly/core";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { SchemaConfigurationModule } from "@vp-libs/formly/json-schema";
import { DynAssignModule } from "@vp-libs/shared/assign";
import { SharedThemeManagerModule } from "@vp-libs/shared/theme-manager";
import {
  AdvancedCommandsService,
  ADVANCED_COMMANDS_API_BASE_URL
} from "@vp-libs/virtualcareapp/feature-advanced-commands";
import {
  CaseSummaryService,
  CASE_SUMMARY_API_BASE_URL
} from "@vp/case-search/data-access/case-summary";
import { CaseApiService, CASE_API_BASE_URL } from "@vp/core/case";
import { CaseTypesState, CASE_TYPES_API_BASE_URL } from "@vp/data-access/case-types";
import { GroupsState, GROUPS_API_BASE_URL } from "@vp/data-access/groups";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState, TAG_API_BASE_URL } from "@vp/data-access/tags";
import { ProfileGuard } from "@vp/profile/feature";
import { AuthenticationService } from "@vp/shared/authentication";
import { CybersourceModule } from "@vp/shared/cybersouce";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { FeatureFlagsModule, FeatureFlagsService } from "@vp/shared/feature-flags";
import {
  LocalStorageModule,
  LocalStorageService,
  LOCAL_STORAGE_OPTIONS
} from "@vp/shared/local-storage";
import { MaterialModule } from "@vp/shared/material";
import {
  PermissionsConstService,
  PERMISSIONS_CONST_ENVIRONMENT_IS_PROD
} from "@vp/shared/permissions-const";
import { SpinnerInterceptorService, SpinnerModule } from "@vp/shared/spinner";
import { AppStoreService, APP_STORE_API_BASE_URL } from "@vp/shared/store/app";
import { UiStoreService } from "@vp/shared/store/ui";
import { UserApiService, USER_API_BASE_URL } from "@vp/shared/store/user";
import { UTILITY_OPTIONS } from "@vp/shared/utilities";
import ajv from "ajv";
import {
  AuthModule,
  EventTypes,
  OidcConfigService,
  PublicEventsService
} from "angular-auth-oidc-client";
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaV3Module,
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY
} from "ng-recaptcha";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { MomentModule } from "ngx-moment";
import { NgxPermissionsModule } from "ngx-permissions";
import { ToastContainerModule, ToastrModule } from "ngx-toastr";
import { filter } from "rxjs/operators";
import { environment } from "../environments/environment";
import { config } from "../environments/environment.config";
import { accessControlFactory } from "./access-control.factory";
import { configureAuth } from "./app-config";
import { appInitializerFactory, CONFIG_DEPENDENCIES, IS_IVY_API } from "./app-initializer.factory";
import { AppRoutingModule } from "./app-routing.module";
import { appThemeManagerConfig } from "./app-theme-manager.config";
import { AppComponent } from "./app.component";
import { ComponentModule } from "./components";
import { GlobalErrorHandler } from "./global-error-handler";
import { LogoutComponent } from "./logout/logout.component";
import { AccessControlService } from "./services/access-control/access-control.service";
import {
  AJV_CLASS,
  AJV_CONFIG,
  AJV_INSTANCE,
  JsonSchemaValidationService
} from "./services/json-schema-service.service";
import { SignalRService } from "./services/signal-r.service";
import { TagManagerService } from "./services/tag-manager.service";
import { AuthenticationGuard } from "./shared/guards/authentication.guard";
import { AuthHeaderInterceptor } from "./shared/interceptors/auth-header-interceptor";
import { ServerErrorInterceptor } from "./shared/interceptors/server-error.interceptor";
import { SharedModule } from "./shared/shared.module";

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null; // for use with ngx-mask

@NgModule({
  declarations: [AppComponent, LogoutComponent],
  imports: [
    NgxsModule.forRoot([CaseTypesState, GroupsState, OrganizationState, TagsState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AppRoutingModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ComponentModule,
    DynAssignModule,
    FlexLayoutModule,
    FullCalendarModule,
    HttpClientModule,
    LocalStorageModule,
    MatDatepickerModule,
    MaterialModule,
    MomentModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    SharedModule,
    SpinnerModule,
    ToastContainerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true
    }),
    SchemaConfigurationModule.forRoot({
      layouts: [
        {
          types: ["wizard"],
          elements: [
            {
              scope: "#/properties/subject",
              formlyConfig: {
                templateOptions: {
                  actions: {
                    continue: true,
                    finishLater: false
                  }
                }
              }
            }
          ]
        },
        {
          types: ["payment"],
          elements: [
            {
              scope: "#/properties/payment",
              definition: "payment-page",
              formlyConfig: {}
            }
          ]
        },
        {
          types: ["answers"],
          elements: [
            {
              scope: "#",
              formlyConfig: {
                type: "tabs"
              }
            }
          ]
        },
        {
          types: ["images"],
          elements: [
            {
              scope: "#/properties/images",
              order: 1,
              definition: "dicoms"
            }
          ]
        }
      ],
      definitions: [
        {
          types: ["email-field"],
          formlyConfig: {
            templateOptions: {
              type: "email",
              maxLength: 255
            },
            validators: {
              validation: ["email"]
            }
          }
        },
        {
          types: ["attachments"],
          formlyConfig: {
            name: "attachments",
            templateOptions: {
              label: "Attachments",
              order: 2,
              fxLayout: "column"
            },
            fieldGroup: [
              {
                key: "document-data",
                type: "document-data",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "document-actions",
                type: "document-actions",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "document-grid",
                type: "document-grid",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "image-actions",
                type: "image-actions",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "image-grid",
                type: "image-grid",
                templateOptions: {
                  fxFlex: 100
                }
              }
            ]
          }
        },
        {
          types: ["documents"],
          formlyConfig: {
            name: "documents",
            templateOptions: {
              label: "Attachments",
              order: 2,
              fxLayout: "column"
            },
            fieldGroup: [
              {
                key: "document-data",
                type: "document-data",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "document-actions",
                type: "document-actions",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "document-grid",
                type: "document-grid",
                templateOptions: {
                  fxFlex: 100
                }
              }
            ]
          }
        },
        {
          types: ["images"],
          formlyConfig: {
            name: "images",
            templateOptions: {
              label: "Images",
              order: 2,
              fxLayout: "column"
            },
            fieldGroup: [
              {
                key: "image-data",
                type: "image-data",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "image-actions",
                type: "image-actions",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "image-grid",
                type: "image-grid",
                templateOptions: {
                  fxFlex: 100
                }
              }
            ]
          }
        },
        {
          types: ["dicoms"],
          formlyConfig: {
            name: "dicoms",
            templateOptions: {
              label: "Imaging",
              order: 2,
              fxLayout: "column"
            },
            fieldGroup: [
              {
                key: "dicoms-data",
                type: "image-data", // Reusing image-data component
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                template: `
                <div style='margin: 16px !important'>
                  <div>
                    <span><strong>1.</strong></span>
                    Please place the CD in the CD drive of your computer, or insert the USB Flash Drive you were given after receiving radiology imaging back from the facility you had radiologic imaging done.
                  </div>
                  <div>
                    <span><strong>2.</strong></span>
                    Close any other browser windows.
                  </div>
                  <div>
                    <span><strong>3.</strong></span>
                    Click on "Upload DICOM Folder" button.
                  </div>
                  <div>
                    <span ><strong>4.</strong></span>
                    Click Select Folder from your CD or USB Flash Drive.
                  </div>
                  <div>
                    <span><strong>5.</strong></span>
                    If the facility you had imaging done provided you with a download link please ensure the files are downloaded and unzipped. Export the files to a folder on your desktop and select the folder.
                  </div>
                  <div>
                    <span><strong>6.</strong></span>
                    Click the Upload button.
                  </div>
                </div>
                `
              },
              {
                key: "dicoms-actions",
                type: "dicoms-actions",
                templateOptions: {
                  fxFlex: 100
                }
              },
              {
                key: "dicoms-grid",
                type: "dicoms-grid",
                templateOptions: {
                  fxFlex: 100
                }
              }
            ]
          }
        },
        {
          types: ["assignments"],
          formlyConfig: {
            name: "assignments",
            templateOptions: {
              label: "Assignments",
              order: 3,
              fxLayout: "row wrap"
            },
            fieldGroup: [
              {
                key: "assign-groups",
                type: "assign-groups",
                templateOptions: {
                  fxFlex: 100
                }
              }
            ]
          }
        },
        {
          types: ["state-selector"],
          formlyConfig: {
            type: "select",
            templateOptions: {
              options: [
                {
                  label: "Alabama",
                  value: "AL"
                },
                {
                  label: "Alaska",
                  value: "AK"
                },
                {
                  label: "Arizona",
                  value: "AZ"
                },
                {
                  label: "Arkansas",
                  value: "AR"
                },
                {
                  label: "California",
                  value: "CA"
                },
                {
                  label: "Colorado",
                  value: "CO"
                },
                {
                  label: "Connecticut",
                  value: "CT"
                },
                {
                  label: "Delaware",
                  value: "DE"
                },
                {
                  label: "District Of Columbia",
                  value: "DC"
                },
                {
                  label: "Florida",
                  value: "FL"
                },
                {
                  label: "Georgia",
                  value: "GA"
                },
                {
                  label: "Guam",
                  value: "GU"
                },
                {
                  label: "Hawaii",
                  value: "HI"
                },
                {
                  label: "Idaho",
                  value: "ID"
                },
                {
                  label: "Illinois",
                  value: "IL"
                },
                {
                  label: "Indiana",
                  value: "IN"
                },
                {
                  label: "Iowa",
                  value: "IA"
                },
                {
                  label: "Kansas",
                  value: "KS"
                },
                {
                  label: "Kentucky",
                  value: "KY"
                },
                {
                  label: "Louisiana",
                  value: "LA"
                },
                {
                  label: "Maine",
                  value: "ME"
                },
                {
                  label: "Marshall Islands",
                  value: "MH"
                },
                {
                  label: "Maryland",
                  value: "MD"
                },
                {
                  label: "Massachusetts",
                  value: "MA"
                },
                {
                  label: "Michigan",
                  value: "MI"
                },
                {
                  label: "Minnesota",
                  value: "MN"
                },
                {
                  label: "Mississippi",
                  value: "MS"
                },
                {
                  label: "Missouri",
                  value: "MO"
                },
                {
                  label: "Montana",
                  value: "MT"
                },
                {
                  label: "Nebraska",
                  value: "NE"
                },
                {
                  label: "Nevada",
                  value: "NV"
                },
                {
                  label: "New Hampshire",
                  value: "NH"
                },
                {
                  label: "New Jersey",
                  value: "NJ"
                },
                {
                  label: "New Mexico",
                  value: "NM"
                },
                {
                  label: "New York",
                  value: "NY"
                },
                {
                  label: "North Carolina",
                  value: "NC"
                },
                {
                  label: "North Dakota",
                  value: "ND"
                },
                {
                  label: "Northern Mariana Islands",
                  value: "MP"
                },
                {
                  label: "Ohio",
                  value: "OH"
                },
                {
                  label: "Oklahoma",
                  value: "OK"
                },
                {
                  label: "Oregon",
                  value: "OR"
                },
                {
                  label: "Palau",
                  value: "PW"
                },
                {
                  label: "Pennsylvania",
                  value: "PA"
                },
                {
                  label: "Puerto Rico",
                  value: "PR"
                },
                {
                  label: "Rhode Island",
                  value: "RI"
                },
                {
                  label: "South Carolina",
                  value: "SC"
                },
                {
                  label: "South Dakota",
                  value: "SD"
                },
                {
                  label: "Tennessee",
                  value: "TN"
                },
                {
                  label: "Texas",
                  value: "TX"
                },
                {
                  label: "Utah",
                  value: "UT"
                },
                {
                  label: "Vermont",
                  value: "VT"
                },
                {
                  label: "Virgin Islands",
                  value: "VI"
                },
                {
                  label: "Virginia",
                  value: "VA"
                },
                {
                  label: "Washington",
                  value: "WA"
                },
                {
                  label: "West Virginia",
                  value: "WV"
                },
                {
                  label: "Wisconsin",
                  value: "WI"
                },
                {
                  label: "Wyoming",
                  value: "WY"
                }
              ]
            }
          }
        },
        {
          types: ["payment-page"],
          formlyConfig: {
            type: "flex-layout",
            key: "payment",
            name: "payment",
            templateOptions: {
              label: "Payment",
              actions: {
                payment: {
                  submit: true
                }
              }
            },
            fieldGroup: [
              {
                key: "cybersource",
                type: "cybersource-payment"
              }
            ]
          }
        }
      ]
    }),
    NgxPermissionsModule.forRoot(),
    NgxMaskModule.forRoot(),
    SharedThemeManagerModule.forRoot(appThemeManagerConfig),
    FormlyModule.forRoot({
      extras: {
        checkExpressionOn: "modelChange"
      },
      validators: [
        { name: "requiredTrue", validation: Validators.requiredTrue },
        { name: "email", validation: Validators.email }
      ],
      validationMessages: [{ name: "email", message: "A Valid email address is required" }]
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    FeatureFlagsModule,
    CybersourceModule
  ],
  providers: [
    EventAggregator,
    JsonSchemaValidationService,
    TagManagerService,
    {
      provide: "environment",
      useValue: environment
    },
    AdvancedCommandsService,
    { provide: ADVANCED_COMMANDS_API_BASE_URL, useValue: config.baseApi },
    AppStoreService,
    { provide: APP_STORE_API_BASE_URL, useValue: config.baseApi },
    CaseApiService,
    { provide: CASE_API_BASE_URL, useValue: config.baseApi },
    CaseSummaryService,
    { provide: CASE_SUMMARY_API_BASE_URL, useValue: config.baseApi },
    { provide: CASE_TYPES_API_BASE_URL, useValue: `${config.baseApi}/casetype` },
    { provide: GROUPS_API_BASE_URL, useValue: `${config.baseApi}/group` },
    PermissionsConstService,
    { provide: PERMISSIONS_CONST_ENVIRONMENT_IS_PROD, useValue: environment.production },
    { provide: TAG_API_BASE_URL, useValue: `${config.baseApi}/tags` },
    UserApiService,
    { provide: USER_API_BASE_URL, useValue: config.baseApi },
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      // WARNING! These deps need to match that of the appIntializerFactory DI
      deps: [
        AccessControlService,
        AppStoreService,
        AuthenticationService,
        CONFIG_DEPENDENCIES,
        FeatureFlagsService,
        IS_IVY_API,
        LocalStorageService,
        PermissionsConstService,
        Router,
        SignalRService,
        Store,
        UiStoreService
      ]
    },
    {
      provide: CONFIG_DEPENDENCIES,
      // Use a factory that return an array of dependant functions to be executed
      useFactory: (accessControlService: AccessControlService) => {
        // Easy to add or remove dependencies
        return [accessControlFactory(accessControlService)];
      },
      deps: [AccessControlService]
    },
    {
      provide: IS_IVY_API,
      useFactory: () => {
        const fromHost = window.location.href;
        return environment.ivySiteUrls.some(x => fromHost.includes(x));
      }
    },
    {
      provide: LOCAL_STORAGE_OPTIONS,
      useValue: {
        storageName: "app",
        maxAgeSeconds: 600
      }
    },
    {
      provide: UTILITY_OPTIONS,
      useValue: {
        routeDefault: "/home"
      }
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptorService, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: ProfileGuard, useClass: ProfileGuard },
    { provide: AuthenticationGuard, useClass: AuthenticationGuard },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaSecretKey
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.reCaptchaSiteKey,
        badge: "inline"
      } as RecaptchaSettings
    },
    { provide: AJV_CLASS, useValue: ajv },
    { provide: AJV_CONFIG, useValue: {} },
    {
      provide: AJV_INSTANCE,
      useFactory: (ajvClass: any, config: ajv.Options) => {
        return new ajvClass(config);
      },
      deps: [AJV_CLASS, AJV_CONFIG]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private eventService: PublicEventsService, private route: Router) {
    this.route.events.pipe(filter(event => event instanceof NavigationCancel)).subscribe();

    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => {
          return notification.type === EventTypes.CheckSessionReceived;
        })
      )
      // eslint-disable-next-line no-console
      .subscribe(value => console.info("CheckSessionChanged with value", value));
  }
}
