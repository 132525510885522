import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CaseFile, CaseResponse } from "@vp/core/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class ResponseApiService {
  constructor(private http: HttpClient) {}

  public getResponse(
    caseId: string,
    resultId: string,
    templateId: string
  ): Observable<null | string> {
    const apiURL = `${environment.baseApi}/case/${caseId}/response/result/${resultId}/template/${templateId}`;
    return this.http
      .get(apiURL, {
        observe: "response",
        responseType: "text"
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.ok) {
            return response.body as string;
          }
          return null;
        })
      );
  }

  public saveResponse(caseId: string, caseResponse: Partial<CaseResponse>): Observable<boolean> {
    const apiURL = `${environment.baseApi}/case/${caseId}/response`;
    return this.http
      .post<boolean>(apiURL, caseResponse, {
        observe: "response"
      })
      .pipe(
        map((httpResponse: HttpResponse<any>) => {
          if (httpResponse.ok) {
            return true;
          }
          return false;
        })
      );
  }

  public updateResponse(caseId: string, caseResponse: Partial<CaseResponse>): Observable<boolean> {
    const apiURL = `${environment.baseApi}/case/${caseId}/response/${caseResponse.responseId}`;
    return this.http
      .put(apiURL, caseResponse, {
        observe: "response"
      })
      .pipe(
        map((httpResponse: HttpResponse<any>) => {
          if (httpResponse.ok) {
            return true;
          }
          return false;
        })
      );
  }

  public daleteResponse(caseId: string, responseId: string): Observable<boolean> {
    const apiURL = `${environment.baseApi}/case/${caseId}/response/${responseId}`;
    return this.http
      .delete(apiURL, {
        observe: "response"
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.ok) {
            return true;
          }
          return false;
        })
      );
  }

  public generateResponsePdf(
    caseId: string,
    caseResponse: CaseResponse,
    fileName?: string
  ): Observable<CaseFile> {
    let apiUrl = `${environment.baseApi}/case/${caseId}/pdf`;
    if (fileName) {
      apiUrl += `/${fileName}`;
    }
    return this.http.post<CaseFile>(apiUrl, caseResponse);
  }
}
