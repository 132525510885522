import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseFile } from "@vp/core/models";

@Component({
  selector: "vp-image-zoom-dialog",
  templateUrl: "./image-zoom-dialog.component.html",
  styleUrls: ["./image-zoom-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageZoomDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ImageZoomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CaseFile
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
