import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SharedConfirmationService } from "@vp-libs/shared/confirmation";
import { Snippet, User } from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { EMPTY, Observable, of, zip } from "rxjs";
import { concatMap, map, switchMap, withLatestFrom } from "rxjs/operators";
import { AddOrEditSnippetDialogComponent } from "../add-or-edit-snippet-dialog/add-or-edit-snippet-dialog.component";

@Component({
  selector: "vp-user-snippets",
  templateUrl: "./user-snippets.component.html",
  styleUrls: ["./user-snippets.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
export class UserSnippetsComponent {
  snippets: Observable<Snippet[]> = this.appStore.userStream.pipe(
    map(user => user.userData?.snippets ?? [])
  );

  expandedRow: Snippet | null;

  constructor(
    private appStore: AppStoreService,
    private readonly dialog: MatDialog,
    private notificationService: NotificationService,
    private readonly confirmationDialog: SharedConfirmationService
  ) {}

  addOrEditSnippet(action: "Add" | "Edit", snippet?: Snippet, index?: number) {
    const dialogRef = this.dialog.open(AddOrEditSnippetDialogComponent, {
      width: "500px",
      data: { action: action, snippet: snippet }
    });

    dialogRef
      .afterClosed()
      .pipe(
        withLatestFrom(
          this.appStore.user$.pipe(
            filterNullMap(),
            map(user => {
              return { ...user };
            })
          )
        ),
        concatMap(([dialogResult, user]: [Snippet, User]) => {
          return zip(of(dialogResult), of(user), this.snippets);
        }),
        switchMap(([dialogResult, user, snippets]: [Snippet, User, Snippet[]]) => {
          if (dialogResult) {
            switch (action) {
              case "Add":
                user.userData = { snippets: snippets.concat(dialogResult) };
                break;

              case "Edit":
                this.snippets[index] = dialogResult;
                user.userData = { snippets: snippets };
                break;
            }
            return of(user);
          }
          return EMPTY;
        }),
        concatMap((user: User) => {
          return this.appStore.patchUser(user);
        })
      )
      .subscribe(() => {
        this.notificationService.successMessage("Snippet Added");
      });
  }

  deleteSnippet(index: number) {
    this.confirmationDialog
      .open("Delete this template?")
      .afterConfirmed()
      .pipe(
        withLatestFrom(
          this.appStore.user$.pipe(
            filterNullMap(),
            map(user => {
              return { ...user };
            })
          )
        ),
        concatMap(([dialogResult, user]: [Snippet, User]) => {
          return zip(of(dialogResult), of(user), this.snippets);
        }),
        switchMap(([dialogResult, user, snippets]: [Snippet, User, Snippet[]]) => {
          if (dialogResult) {
            snippets.splice(index, 1);
            user.userData = { snippets: snippets };
            return of(user);
          }
          return EMPTY;
        }),
        concatMap((user: User) => {
          return this.appStore.patchUser(user);
        })
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Successfully deleted"),
        error: () => this.notificationService.warningMessage("Failed to delete")
      });
  }
}
