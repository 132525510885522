import { AfterViewInit, ChangeDetectionStrategy, Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldType, FormlyFormOptions } from "@ngx-formly/core";
import { formlyJsonschemaOptions, UiSchemaLayoutProvider } from "@vp-libs/formly/json-schema";
import { CaseData, CaseType } from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { Subject } from "rxjs";
import { concatMap, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { FileApiService } from "../../services/api/file-api.service";
import { CaseContextService } from "../../services/case-context/case-context.service";

/*
  I hate doing this with the passion of a thousand firey suns.
*/
@Component({
  selector: "vp-document-data",
  templateUrl: "./document-data.component.html",
  styleUrls: ["./document-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentDataComponent extends FieldType implements AfterViewInit {
  formOptionsObject: FormlyFormOptions = { formState: { disabled: false } };
  form = new FormGroup({});
  data: any;
  fields: any;

  private caseId: string | null = null;
  private destroyed$ = new Subject<void>();

  constructor(
    private readonly caseContextService: CaseContextService,
    private readonly fileApi: FileApiService,
    private readonly notificationService: NotificationService,
    private readonly uiSchemaLayoutProvider: UiSchemaLayoutProvider
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.caseContextService.Context.pipe(
      filterNullMap(),
      take(1),
      tap((caseData: CaseData) => {
        this.caseId = caseData.caseId;
      }),
      switchMap(() => {
        return this.caseContextService.caseType$.pipe(filterNullMap());
      }),
      concatMap((caseType: CaseType) => {
        return this.uiSchemaLayoutProvider.applyScopes(
          "documents-data",
          caseType.documents.documentSchema
        );
      }),
      takeUntil(this.destroyed$)
    ).subscribe({
      next: (schema: any) => {
        this.fields = this.uiSchemaLayoutProvider.getFieldConfig(schema, formlyJsonschemaOptions);
      },
      error: () => this.notificationService.errorMessage("Failed to retrieve document data.")
    });
  }

  modelChangeHandler(): void {
    this.fileApi
      .updateDocumentData(this.caseId as string, this.data)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        error: () => this.notificationService.errorMessage("Failed to save document.")
      });
  }
}
