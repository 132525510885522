import { AccessControlService } from "./services/access-control/access-control.service";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function accessControlFactory(
  accessControlService: AccessControlService
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, _reject) => {
      accessControlService.initNgxPermissions();
      resolve(void 0);
    });
  };
}
