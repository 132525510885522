import { Inject, ModuleWithProviders, NgModule } from "@angular/core";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { ThemeManagerConfig, THEME_MANAGER_CONFIG } from "./theme-manager-config.model";
import { GTM_MANAGER_ID, GTM_NOT_FOUND, ThemeManagerService } from "./theme-manager.service";

@NgModule({
  imports: [GoogleTagManagerModule],
  exports: [GoogleTagManagerModule],
  providers: [ThemeManagerService]
})
export class SharedThemeManagerModule {
  constructor(
    @Inject(THEME_MANAGER_CONFIG) private readonly themeManagerConfig: ThemeManagerConfig,
    private readonly sharedStyleManagerService: ThemeManagerService
  ) {
    if (this.themeManagerConfig?.hostnames) {
      this.sharedStyleManagerService.setTheme();
    }
  }

  static forRoot(config?: ThemeManagerConfig): ModuleWithProviders<SharedThemeManagerModule> {
    return {
      ngModule: SharedThemeManagerModule,
      providers: [
        {
          provide: THEME_MANAGER_CONFIG,
          useValue: config
        },
        {
          provide: GTM_MANAGER_ID,
          useValue: GTM_NOT_FOUND
        }
      ]
    };
  }
}
