import { ChangeDetectionStrategy, Component, OnDestroy, TrackByFunction } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectionListChange } from "@angular/material/list";
import { Select } from "@ngxs/store";
import { UserAdministrationService } from "@vp/administration/user/data-access/user-administration-service";
import { Column } from "@vp/administration/user/feature";
import { GroupsAssignmentService } from "@vp/case-assignments/data-access/groups";
import { Group, GroupType, Organization } from "@vp/core/models";
import { OrganizationState } from "@vp/data-access/organization";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { concatMap, map, switchMap, take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "vp-user-assign-groups",
  templateUrl: "./user-assign-groups.component.html",
  styleUrls: ["./user-assign-groups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GroupsAssignmentService]
})
export class UserAssignGroupsComponent implements OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  columns$!: Observable<Column[]>;
  excludeProperties: string[] = [];
  filters: Record<string, unknown> | undefined = undefined;
  groupTypes$!: Observable<GroupType[]>;
  groupTypeSelector = new FormControl();
  hasSelected$: Observable<boolean>;
  items$!: Observable<Group[]>;
  search$!: Observable<string | null>;
  selected$!: Observable<Group[]>;
  selectedCount$: Observable<number>;

  private readonly destroyed$ = new Subject();
  private readonly columnsSubject$ = new BehaviorSubject<Column[]>([]);
  private readonly searchSubject$ = new BehaviorSubject<string | null>(null);
  private readonly selectedSubject$ = new BehaviorSubject<Group[]>([]);
  private readonly filtersSubject$ = new BehaviorSubject<string[]>([]);

  constructor(
    private readonly groupsAssignmentService: GroupsAssignmentService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {
    this.search$ = this.searchSubject$.asObservable();
    this.selected$ = this.selectedSubject$.asObservable();
    this.hasSelected$ = this.selectedSubject$.pipe(map(items => items.length > 0));
    this.selectedCount$ = this.selectedSubject$.pipe(map(items => items.length));

    this.columns$ = this.columnsSubject$.pipe(
      switchMap((columns: Column[]) => {
        return combineLatest([
          of(columns),
          this.ngxPermissionsService.hasPermission([
            this.permConst.Admin.User.GroupAssignment.Write
          ])
        ]);
      }),
      map(([columns, hasWritePermissions]) => {
        if (hasWritePermissions) {
          var cols = [...columns];
          cols.push({
            field: "actions",
            header: "Actions"
          } as Column);
          return cols;
        }
        return columns;
      })
    );

    this.items$ = combineLatest([
      this.groupsAssignmentService.assignableTags$,
      this.filtersSubject$
    ]).pipe(
      map(([assignable, filters]: [Group[], string[]]) => {
        if (filters.length > 0) {
          return assignable.filter(item => filters.includes(item.groupTypeId));
        }
        return assignable;
      }),
      takeUntil(this.destroyed$)
    );

    this.groupTypes$ = this.organization$.pipe(
      filterNullMap(),
      map(org => org.groupTypes)
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  searched(event: any) {
    this.searchSubject$.next(event);
  }

  assignSelected() {
    this.selected$
      .pipe(
        concatMap((data: Group[]) => {
          return this.userAdministrationService.addGroups$(data);
        }),
        take(1)
      )
      .subscribe();
  }

  selectionChanged(event: MatSelectionListChange) {
    this.selected$
      .pipe(
        map(selected => [...selected]),
        tap(selected => {
          if (event.options[0].selected) {
            selected.push(event.options[0].value);
          } else {
            const index = selected.indexOf(event.options[0].value);
            selected.splice(index, 1);
          }
        }),
        take(1)
      )
      .subscribe(stillSelected => {
        this.selectedSubject$.next(stillSelected);
      });
  }

  groupTypeFilterChanged = (selected: string[]) => {
    this.filtersSubject$.next(selected);
  };

  isSelected(item: Group): Observable<boolean> {
    return this.selected$.pipe(
      map((selected: Group[]) => selected.findIndex(i => i.groupId === item.groupId) > -1)
    );
  }

  trackById: TrackByFunction<Group> = (_: number, item: Group) => item.groupId;
}
