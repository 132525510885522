import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { interval, Subscription } from "rxjs";
import { throttle } from "rxjs/operators";

import { ProgressBarMode } from "@angular/material/progress-bar";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { UploadCompletedEvent } from "../events/upload-completed-event";
import { UploadProgressEvent } from "../events/upload-progress-event";
import { filterNullMap } from "@vp/shared/operators";

@Component({
  selector: "vp-upload-progress",
  templateUrl: "./upload-progress.component.html",
  styleUrls: ["./upload-progress.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadProgressComponent implements OnInit, OnDestroy {
  private uploadProgressSubscription: Subscription;
  private uploadCompleteSubscription: Subscription;

  mode: ProgressBarMode = "indeterminate";
  public progress: number | null = null;
  public status = "";
  public done = false;

  constructor(
    private eventAggregator: EventAggregator,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.uploadProgressSubscription = this.eventAggregator
      .on<UploadProgressEvent>(UploadProgressEvent)
      .pipe(
        filterNullMap(),
        /* The angular material progress component has a 250 ms interval for the
           progress animation, so we have to throttle it here to prevent jumpiness.
        */
        throttle(() => interval(250))
      )
      .subscribe((e: UploadProgressEvent) => {
        this.done = false;
        this.status = e.status;
        const lastProgress = this.progress;
        this.progress = e.progress;
        // prevents back-sliding especially when concurrent processing is enabled
        if (lastProgress && this.progress < lastProgress) {
          this.progress = lastProgress;
        }
        this.changeDetectorRef.detectChanges();
      });

    this.uploadCompleteSubscription = this.eventAggregator
      .on<UploadCompletedEvent>(UploadCompletedEvent)
      .subscribe((_event: UploadCompletedEvent) => {
        this.status = "Upload Finished";
        this.done = true;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.uploadProgressSubscription.unsubscribe();
    this.uploadCompleteSubscription.unsubscribe();
  }

  dismiss = () => {
    if (this.dismissable) {
      this.progress = null;
      this.status = "";
      this.done = false;
    }
  };

  get dismissable() {
    return this.progress ? this.progress >= 100 : false;
  }
}
