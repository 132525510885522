import { Injector, Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe maps an array of objects to a array of strings containing the specificed member
 * this is useful for matHeaderRowDef directives when colums are an object and not an
 * array of strings.
 *
 * For Example:
 *    <mat-header-row *matHeaderRowDef="columns | mapToField: 'field'"></mat-header-row>
 */
@Pipe({
  name: "mapToField"
})
export class MapFieldPipe implements PipeTransform {
  transform(value: any[], member: string): string[] {
    return value.map(c => c[member]);
  }
}

/**
 * Resolves and executes an instance of a pipe at runtime from configuration. An example of this
 * might be in a table where all the columns are resolved in an ngFor, but the data is an id for which
 * you need to look up a display name for the given id. For example a table that uses the below config
 * to define its structure,
 *
 *  columns: [
 *     {
 *       field: "displayName",
 *      header: "Display Name"
 *    },
 *    {
 *      field: "tagTypeId",
 *      header: "Tag Type",
 *       pipe: TagTypePipe
 *    }],
 *
 *    and your data looks like: [{
 *      "displayName" : "My Tag",
 *      "tagTypeId" : "123454"
 *    }
 *  ]
 *
 * You would use the TagTypePipe from the config array above to resolve the display name of
 * the tag type without having to merge the entire org table in with the tag types and creating
 * a VM for this.
 *
 *    <mat-cell *matCellDef="let item">
 *      <span *ngIf="!column.pipe; else piped">{{ item[column.field] }}</span>
 *      <ng-template #piped>
 *        <span *ngIf="column.pipe">{{
 *          item[column.field] | dynamicLookup: column.pipe:item[column.field] | async
 *        }}</span>
 *      </ng-template>
 *    </mat-cell>
 *
 * in this example column.pipe is the tag type, and the args being passed are item[column.field]
 * where item is the given row and column.field is "tagTypeId"
 */
@Pipe({
  name: "dynamicLookup"
})
export class DynamicLookupPipe implements PipeTransform {
  public constructor(private injector: Injector) {}

  transform(value: any, pipeToken: any, pipeArgs: any[]): any {
    if (!pipeToken) {
      return value;
    } else {
      let pipe = this.injector.get(pipeToken);
      return pipe.transform(value, ...pipeArgs);
    }
  }
}
