import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "vp-captcha",
  template: ` <re-captcha (resolved)="resolve($event)"></re-captcha> `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaptchaComponent extends FieldType implements OnInit {
  formControl!: FormControl;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.formControl.setErrors({ invalidtoken: true });
    this.formControl.setValidators([this.invalidtoken()]);
  }

  invalidtoken(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === null ? { invalidtoken: true } : null;
    };
  }

  public resolve(token: string | null) {
    if (token) {
      this.formControl.setValue(token, { emitEvent: true });
    }
  }
}
