import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { Group, Organization } from "@vp/core/models";
import { GroupsState } from "@vp/data-access/groups";
import { OrganizationState } from "@vp/data-access/organization";
import { Observable } from "rxjs";
import { map, shareReplay, withLatestFrom } from "rxjs/operators";

@Pipe({
  name: "groupDisplay"
})
export class GroupDisplayPipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(GroupsState.allGroups) groups$!: Observable<Group[]>;

  public transform(groupId: string): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      withLatestFrom(this.groups$),
      map(([org, groups]: [Organization, Group[]]) => {
        return getGroupDisplay(groupId, groups, org);
      })
    );
  }
}

export const getGroupDisplay = (groupId: string, groups: Group[], org: Organization) => {
  let groupsDisplayName = "[group not found]";
  let groupTypeDisplayName = "[group type not found]";
  const group = groups.find(t => t.groupId === groupId);
  if (group !== undefined) {
    groupsDisplayName = group.displayName;
    const groupType = org.groupTypes.find(g => g.groupTypeId === group.groupTypeId);
    if (groupType !== undefined) {
      groupTypeDisplayName = groupType.displayName;
    }
  }
  return `${groupTypeDisplayName}: ${groupsDisplayName}`;
};
