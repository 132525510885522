import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/material";

@Component({
  selector: "lib-formly-masked-type",
  templateUrl: "./formly-masked-type.component.html",
  styleUrls: ["./formly-masked-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMaskedTypeComponent extends FieldType implements OnInit {
  formControl!: FormControl;
  maskTypes: MaskTypes = {};

  //@ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;

  constructor() {
    super();
  }

  get type() {
    return this.to.type || "text";
  }

  get dropSpecialCharacters(): boolean {
    return !!this.to.dropSpecialCharacters ? this.to.dropSpecialCharacters : false;
  }

  get prefix() {
    return this.to.prefix || "";
  }

  get suffix() {
    return this.to.suffix || "";
  }

  get mask() {
    return this.to.mask || "";
  }

  get pattern() {
    return this.to.pattern || "";
  }

  get placeHolder() {
    return this.to.placeholder || "";
  }

  get showMaskTyped(): boolean {
    return this.to.showMaskTyped || false;
  }

  get specialCharacters() {
    return this.to.specialCharacters || "";
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}

export interface MaskTypes {
  [k: string]: {
    mask: string;
    pattern: any;
    symbol: string;
    prefix: string;
    suffix: string;
    dropSpecialCharacters: boolean;
    showMaskTyped: boolean;
    clearIfNotMatch: boolean;
    specialCharacters: any;
  };
}
