import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { removeFileExtension } from "@vp/shared/utilities";
import globrex from "globrex";
import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import { EMPTY, from, of, Subject } from "rxjs";
import {
  catchError,
  concatMap,
  finalize,
  mergeMap,
  reduce,
  switchMap,
  takeUntil,
  tap
} from "rxjs/operators";
import { BlobStorageService } from "../../../services/azure-blob-storage/blob-storage.service";
import { UploadCompletedEvent } from "./events/upload-completed-event";
import { UploadFileCompleteEvent } from "./events/upload-file-complete-event";
import { UploadFileFailEvent } from "./events/upload-file-fail-event";
import { UploadProgressEvent } from "./events/upload-progress-event";
import { FileState } from "./file-upload.component";
import { ICreateDicomImagesUploadResponse } from "./models/CreateDicomImagesUploadResponse.interface";
import { IFileUpload } from "./models/file-upload.interface";
import { IFile } from "./models/file.interface";

export const DICOMDIR_NAME = "DICOMDIR";
export const DICOM_FILE_EXTENTIONS_GLOB = "*.dcm";
export const MAX_CONCURRENT_REQUESTS = 3;
export const getFileNameFromPartialPath = (value: string) => value.split("/").pop() ?? "";

@Injectable({
  providedIn: "root"
})
export class FileUploadService implements OnDestroy {
  private destroyed$ = new Subject();
  constructor(
    private eventAggregator: EventAggregator,
    private httpClient: HttpClient,
    private blobStorage: BlobStorageService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private upload = (file: IFileUpload, uploadURL: string) => {
    return this.httpClient.post<any>(uploadURL, file.file, {
      reportProgress: true,
      observe: "events"
    });
  };

  uploadWithProgress = (file: IFileUpload, uploadURL: string = "") => {
    this.upload(file, uploadURL)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (event: HttpEvent<HttpProgressEvent>) => {
          //send events for progress uploader
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
              this.eventAggregator.emit(
                new UploadProgressEvent(progress, "Uploading..."),
                "FileUploadService"
              );
              break;
            case HttpEventType.Response:
              this.eventAggregator.emit(
                new UploadProgressEvent(100, "Upload complete!"),
                "FileUploadService"
              );
              break;
            default:
              break;
          }
        },
        error: () => {
          this.eventAggregator.emit(new UploadFileFailEvent(file), "FileUploadService");
        },
        complete: () =>
          this.eventAggregator.emit(new UploadCompletedEvent(file), "FileUploadService")
      });
  };

  prepareFolderWithValidation = async (files: NgxFileDropEntry[]) => {
    this.eventAggregator.emit(new UploadProgressEvent(0, "Validating..."), "FileUploadService");
    return await new Promise<FileState[]>(async resolveValidation => {
      const fileEntries: FileState[] = [];
      const onlyDcmFiles = files.every(
        (file: NgxFileDropEntry) =>
          getFileNameFromPartialPath(file.fileEntry.name) !== DICOMDIR_NAME
      );
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          // `file` method is an async callback when using drag-n-drop only
          await new Promise((resolveFile: Function) => {
            fileEntry.file((file: File) => {
              let valid = true;
              if (onlyDcmFiles) {
                valid = globrex(DICOM_FILE_EXTENTIONS_GLOB).regex.test(
                  getFileNameFromPartialPath(file.name)
                );
              }
              const exists = fileEntries.find(
                (entry: FileState) =>
                  entry.name === file.name && entry.path === (file as any).webkitRelativePath
              );
              if (valid && !exists) {
                fileEntries.push({
                  icon: "pending",
                  name: file.name,
                  size: file.size,
                  lastModified: file.lastModified,
                  path: (file as any).webkitRelativePath
                });
              }
              resolveFile();
            });
          });
        }
      }
      resolveValidation(fileEntries);
    }).finally(() =>
      this.eventAggregator.emit(new UploadProgressEvent(null, ""), "FileUploadService")
    );
  };

  uploadFolderWithProgress = (
    files: File[],
    createUploadURL: string,
    queueUploadURLTemplate: (template: string) => string
  ) => {
    this.eventAggregator.emit(new UploadProgressEvent(1, "Preparing..."), "FileUploadService");
    return this.createUpload(files, createUploadURL).pipe(
      switchMap((result: ICreateDicomImagesUploadResponse) => {
        return from([...this.sendUploads(files, result.filenames, result)]).pipe(
          mergeMap(calls => calls, MAX_CONCURRENT_REQUESTS),
          reduce((acc, _) => acc, result)
        );
      }),
      concatMap((result: ICreateDicomImagesUploadResponse) =>
        this.queueUpload(queueUploadURLTemplate(result.uploadId))
      ),
      finalize(() => {
        const dummyFile = { fieldName: "dummyFile" } as IFile;
        const dummyUpload = { file: dummyFile, metadata: null } as IFileUpload;
        this.eventAggregator.emit(new UploadCompletedEvent(dummyUpload), "FileUploadService");
      })
    );
  };

  private createUpload = (files: File[], createURL: string) => {
    const dicomdirFile = files.find(
      (file: File) => getFileNameFromPartialPath(file.name) === DICOMDIR_NAME
    );
    if (dicomdirFile) {
      return of(dicomdirFile).pipe(
        switchMap((file: File) => {
          let formData = new FormData();
          formData.append("file", file, file.name);
          return this.httpClient.post<ICreateDicomImagesUploadResponse>(createURL, formData);
        })
      );
    } else {
      return this.httpClient.post<ICreateDicomImagesUploadResponse>(createURL, null);
    }
  };

  private sendUploads = (
    files: File[],
    globs: string[],
    uploadConfig: ICreateDicomImagesUploadResponse
  ) => {
    const totalProgress = files.length;
    return files.map((file: File, index: number, _files: File[]) => {
      return of(file).pipe(
        switchMap((file: File) => {
          const progress = Math.round((100 * index) / totalProgress);
          const iFile = {
            fieldName: file.name,
            webkitRelativePath: (file as any).webkitRelativePath
          } as IFile;
          const iFileUpload = { file: iFile, metadata: null } as IFileUpload;
          const found = globs.some((glob: string) => {
            const globFileName = getFileNameFromPartialPath(glob);
            const fileName = getFileNameFromPartialPath(file.name);
            const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
            const dcmFileWithoutExtension =
              extension.toLowerCase() === "dcm" ? removeFileExtension(fileName) : fileName;
            const regex = globrex(globFileName, { flags: "i" } as globrex.Options).regex;
            return regex.test(fileName) || regex.test(dcmFileWithoutExtension);
          });
          if (!found) {
            this.eventAggregator.emit(new UploadFileFailEvent(iFileUpload), "FileUploadService");
            return EMPTY;
          }
          return this.blobStorage.uploadAmbraFile(uploadConfig, file).pipe(
            tap(() => {
              this.eventAggregator.emit(
                new UploadProgressEvent(progress, `Uploading ${file.name}...`),
                "FileUploadService"
              );
            }),
            catchError(() => {
              return of(false);
            }),
            finalize(() => {
              this.eventAggregator.emit(
                new UploadFileCompleteEvent(iFileUpload),
                "FileUploadService"
              );
            })
          );
        })
      );
    });
  };

  private queueUpload = (queueURL: string) => {
    return this.httpClient.post<any>(queueURL, null);
  };

  getAllFilesFromSystem(droppedFiles: NgxFileDropEntry[]): Promise<(File | null)[]> {
    const filesPromise = droppedFiles.map(async entry => {
      const file = await this.getFileFromSystem(entry);
      return file;
    });
    return Promise.all(filesPromise);
  }

  getFileFromSystem(droppedFile: NgxFileDropEntry): Promise<File | null> {
    // The `file` method is an async callback when using drag-n-drop DataTransfer API
    // Converting to Promise makes sure the file is retrieved in time to upload
    return new Promise<File | null>(resolve => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          resolve(file);
        });
      } else {
        resolve(null);
      }
    });
  }
}
