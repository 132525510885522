import { EventBase } from "@vp/shared/event-aggregator";
import { IFileUpload } from "../models/file-upload.interface";

export class UploadProgressEvent extends EventBase<IFileUpload> {
  constructor(progress: number | null, status: string) {
    super();
    this.progress = progress;
    this.status = status;
  }

  public progress: number | null;
  public status: string;
}
