import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { UserAdministrationService } from "@vp/administration/user/data-access/user-administration-service";
import { Group, GroupLite } from "@vp/core/models";
import { GroupsState } from "@vp/data-access/groups";
import { IAssignmentService } from "@vp/shared/assignments/models";
import { Observable } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class GroupsAssignmentService implements IAssignmentService {
  assignableTags$: Observable<Group[]>;
  assigned$: Observable<Group[]>;

  @Select(GroupsState.allGroups) all$!: Observable<Group[]>;

  constructor(private userAdministrationService: UserAdministrationService) {
    this.assigned$ = this.userAdministrationService.user$.pipe(
      map(user => user.groups),
      withLatestFrom(this.all$),
      map(([assigned, all]: [GroupLite[], Group[]]) =>
        all.filter(e => assigned.findIndex(r => r.groupId === e.groupId) > -1)
      )
    );

    this.assignableTags$ = this.userAdministrationService.user$.pipe(
      map(u => u.groups),
      withLatestFrom(this.all$),
      map(([assigned, all]: [GroupLite[], Group[]]) =>
        all.filter(e => assigned.findIndex(r => r.groupId === e.groupId) < 0)
      )
    );
  }
}
