/**
 * A list of all valid display tags (keys) for the UI
 */
export type UiKeys = keyof typeof ui;

/**
 * Database representation of custom UI
 */
export interface DisplayTag {
  tag: UiKeys;
  display: string;
}

/*
  A map of all tags (keys) and default display (values) used by the ui-store.service.ts
  ngx-translate library would be a better method of custom UI
*/
export const ui = {
  caseNav: "Case",
  reviewNav: "Review",
  responseNav: "Response",
  answersNav: "Answers",
  imagesNav: "Images",
  documentsNav: "Documents",
  submitNav: "Submit",
  featureBillingNav: "Fees & Payments",
  approveNav: "Approve",
  assignmentsNav: "Assignments",
  communicationsNav: "Communications",
  resultsNav: "Results",
  conferenceNav: "Video Conference",
  chatNav: "Chat",
  pushNav: "Push",
  qaConsoleNav: "QA Console",

  caseSearch: "Case Search",
  caseTypeSearch: "Case Type Search",
  groupSearch: "Group Search",
  userSearch: "User Search",

  homeNav: "Home",

  dashboardNav: "Dashboard",
  dashboardSectionsNav: "Global",
  dashboardOverviewNav: "Overview",

  caseManagerNav: "Request Manager",
  caseManagerStatusNav: "Request Status",
  caseManagerDetailsNav: "Request Details",

  patientManagerNav: "Patient Manager",

  departmentFilter: "Department",
  opinionTypeFilter: "Opinion Type",

  profileNav: "Profile",
  detailsNav: "Details",
  accountNav: "Account",

  administrationNav: "Administration",
  caseTypePageNav: "Case Type Details",
  casetypePageNav: "Case Types",
  deviceListPageNav: "Devices",
  groupListNav: "Groups",
  groupPageNav: "Group Details",
  inviteUserPageNav: "Invite New User",
  organizationPageNav: "Organization",
  patientDashboardNav: "Patient Manager",
  qaConsolePageNav: "QA Console",
  questionEditorListNav: "Question List",
  questionEditorPageNav: "Question Editor",
  scheduleMaintenanceNav: "Schedule Maintenance",
  tagsListPageNav: "Tags",
  userListPageNav: "Users",
  userPageNav: "User Details",

  aboutNav: "About",

  caseManagerBreadcrumbButton: "Create New Case",
  caseTypeBreadcrumbButton: "Create New Case Type",
  groupListBreadcrumbButton: "Create New Group",
  questionGroupBreadcrumbButton: "Create New Question Group",

  caseWizardSelectPrompt: "Select Opinion Type",
  caseWizardRequestLabel: "request",

  assignGroupLabel: "Assign Group(s)",
  assignUserLabel: "Assign User",

  submitTitle: "Case Submitted",
  submitDetail: null
};
