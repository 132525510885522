import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UserAdministrationService } from "@vp/administration/user/data-access/user-administration-service";
import { User } from "@vp/core/models";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { insert } from "@vp/shared/utilities";
import { BehaviorSubject, EMPTY, Observable, Subject } from "rxjs";
import { first, map, switchMap, takeUntil, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "vp-legal-notice",
  templateUrl: "./legal-notice.component.html",
  styleUrls: ["./legal-notice.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserAdministrationService]
})
export class LegalNoticeComponent implements OnDestroy {
  destroyed = new Subject();
  form$!: Observable<FormGroup>;
  legalNoticeRequested$: Observable<boolean>;

  private form$$ = new BehaviorSubject<FormGroup | null>(null);
  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly appStoreService: AppStoreService
  ) {
    this.form$$.next(
      this.formBuilder.group({
        legalNoticedAccepted: [false, Validators.requiredTrue]
      })
    );

    this.form$ = this.form$$.pipe(filterNullMap());
    this.legalNoticeRequested$ = this.appStoreService.userStream.pipe(
      filterNullMap(),
      map(user => {
        return user?.userData?.flags?.legalNoticeRequested || true;
      }),
      takeUntil(this.destroyed)
    );
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onAccept() {
    this.form$
      .pipe(
        withLatestFrom(this.appStoreService.userStream),
        switchMap(([form, user]: [FormGroup, User]) => {
          if (form.value.legalNoticedAccepted === true) {
            let modified = { ...user };
            // set legalNoticeRequested to false on the user so they are no longer prompted
            insert({
              targetObject: modified,
              props: ["userData", "flags"],
              objToInsert: {
                legalNoticeRequested: false
              }
            });
            return this.appStoreService.patchUser(modified);
          }
          form.markAllAsTouched();
          return EMPTY;
        }),
        first()
      )
      .subscribe({
        next: () => {
          this.router.navigate(["/default"]);
        }
      });
  }
}
