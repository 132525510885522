import { Directive, Output, EventEmitter, HostBinding, HostListener } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[deAppFileDragDrop]"
})
export class FileDragDropDirective {
  @Output() readonly fileDropped: EventEmitter<File> = new EventEmitter();

  @HostBinding("style.background-color") public backgroundColor: string | undefined;

  @HostBinding("style.opacity") public opacity: number | undefined;

  @HostListener("dragover", ["$event"]) public onDragOver(event: DragEvent): void {
    this.handelBaseEvent(event, "#5555", 0.8);
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(event: DragEvent): void {
    this.handelBaseEvent(event, "white", 1);
  }

  @HostListener("drop", ["$event"]) public onDrop(event: DragEvent): void {
    this.handelBaseEvent(event, "white", 1);

    // TODO Extend to handle directory of files
    //const entry = event.dataTransfer.items[0].webkitGetAsEntry(); // For drag-n-drop
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const files = event.dataTransfer.files;
      this.fileDropped.emit(files[0]);
    }
  }

  private handelBaseEvent(event: DragEvent, backgroundColor: string, opacity: number): void {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = backgroundColor;
    this.opacity = opacity;
  }
}
