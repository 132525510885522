import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UserAssignGroupsModule } from "@vp/administration/user/ui/user-assign-groups";
import { GenericDialogModule } from "@vp/shared/components/generic-dialog";
import { MaterialModule } from "@vp/shared/material";
import { ContextDisplayModule, GroupTypePipe } from "@vp/shared/pipes/context-display";
import { UtilitiesModule } from "@vp/shared/utilities";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserAssignedGroupsComponent } from "./user-assigned-groups.component";

@NgModule({
  imports: [
    CommonModule,
    ContextDisplayModule,
    FlexLayoutModule,
    GenericDialogModule,
    MaterialModule,
    MatSlideToggleModule,
    NgxPermissionsModule,
    UserAssignGroupsModule,
    UtilitiesModule
  ],
  declarations: [UserAssignedGroupsComponent],
  exports: [UserAssignedGroupsComponent],
  providers: [GroupTypePipe]
})
export class UserAssignedGroupsModule {}
