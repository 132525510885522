import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction
} from "@angular/core";
import { FilterToolbarItem } from "./filter-toolbar.module";

@Component({
  selector: "vp-filter-toolbar",
  templateUrl: "./filter-toolbar.component.html",
  styleUrls: ["./filter-toolbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterToolbarComponent {
  @Input() items: FilterToolbarItem[] | null = [];
  @Output() readonly itemRemoved = new EventEmitter<any>();
  @Output() readonly filterCleared = new EventEmitter<any[]>();

  constructor() {}

  removedHandler(item: FilterToolbarItem) {
    this.itemRemoved.emit(item);
  }

  clearAllHandler() {
    this.filterCleared.emit([]);
  }

  trackByValue: TrackByFunction<FilterToolbarItem> = (_index: number, item: FilterToolbarItem) =>
    item.value;
}
