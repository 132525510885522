import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Group, PageResult } from "@vp/core/models";
import { tap } from "rxjs/operators";
import { GroupsApiService } from "../api/groups-api.service";
import { GroupsActions } from "./groups-actions";

export interface GroupsStateModel {
  allGroups: Group[];
  pagedGroups: PageResult<Group> | null;
  currentGroup: Group | null;
}

@State<GroupsStateModel>({
  name: "groups",
  defaults: { allGroups: [], pagedGroups: null, currentGroup: null }
})
@Injectable()
export class GroupsState {
  constructor(private readonly _groupsApiService: GroupsApiService) {}

  @Selector()
  public static allGroups(state: GroupsStateModel) {
    return state.allGroups;
  }

  @Selector()
  public static pagedGroups(state: GroupsStateModel) {
    return state.pagedGroups;
  }

  @Action(GroupsActions.CreateGroup)
  createGroup(ctx: StateContext<GroupsStateModel>, { group }: GroupsActions.CreateGroup) {
    return this._groupsApiService.createGroup(group).pipe(
      tap((group: Group) => {
        ctx.patchState({ allGroups: [...ctx.getState().allGroups, group] });
      })
    );
  }

  @Action(GroupsActions.LoadGroups)
  load(ctx: StateContext<GroupsStateModel>, {}: GroupsActions.LoadGroups) {
    return this._groupsApiService.getAllGroups().pipe(
      tap((groups: Group[]) => {
        ctx.patchState({ allGroups: groups });
      })
    );
  }

  @Action(GroupsActions.LoadPagedGroups)
  loadPagedGroups(ctx: StateContext<GroupsStateModel>, { options }: GroupsActions.LoadPagedGroups) {
    return this._groupsApiService.getGroupsPaged(options).pipe(
      tap((pageResult: PageResult<Group>) => {
        ctx.patchState({ pagedGroups: pageResult });
      })
    );
  }

  @Action(GroupsActions.SetCurrentGroup)
  setCurrentGroup(ctx: StateContext<GroupsStateModel>, { groupId }: GroupsActions.SetCurrentGroup) {
    return this._groupsApiService.getGroupById(groupId).pipe(
      tap((group: Group) => {
        ctx.patchState({ currentGroup: group });
      })
    );
  }

  @Action(GroupsActions.UnsetCurrentGroup)
  unsetCurrentGroup(ctx: StateContext<GroupsStateModel>, {}: GroupsActions.UnsetCurrentGroup) {
    ctx.patchState({ currentGroup: null });
  }

  @Action(GroupsActions.UpdateGroup)
  updateGroup(ctx: StateContext<GroupsStateModel>, { group }: GroupsActions.UpdateGroup) {
    return this._groupsApiService.updateGroup(group).pipe(
      tap((group: Group) => {
        ctx.patchState({
          allGroups: [...ctx.getState().allGroups.filter(g => g.groupId !== group.groupId), group]
        });
      })
    );
  }

  @Action(GroupsActions.DeleteGroup)
  deleteGroup(ctx: StateContext<GroupsStateModel>, { groupId }: GroupsActions.DeleteGroup) {
    return this._groupsApiService.deleteGroup(groupId).pipe(
      tap((success: boolean) => {
        if (success) {
          ctx.patchState({
            allGroups: [...ctx.getState().allGroups.filter(g => g.groupId !== groupId)]
          });
        }
      })
    );
  }
}
