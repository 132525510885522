import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AnswerGroup, CaseData } from "@vp/core/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

/** @todo rename to RecordSchema */
@Injectable({
  providedIn: "root"
})
export class AnswerGroupApiService {
  constructor(private http: HttpClient) {}

  updateRecordData(caseId: string, recordData: any): Observable<CaseData> {
    const apiURL = `${environment.baseApi}/case/${caseId}/recorddata`;
    return this.http.put<CaseData>(apiURL, recordData);
  }

  saveManagementData(caseId: string, managementData: any): Observable<CaseData> {
    const apiURL = `${environment.baseApi}/case/${caseId}/management`;
    return this.http.put<CaseData>(apiURL, managementData);
  }

  /** @deprecated use updateRecordData */
  public updateAnswerGroup = (
    caseId: string,
    questionGroupId: string,
    answerGroup: AnswerGroup
  ): Observable<boolean> => {
    const apiURL = `${environment.baseApi}/case/${caseId}/answergroup/${questionGroupId}`;
    return this.http
      .put(apiURL, answerGroup, {
        observe: "response"
      })
      .pipe(
        map((result: HttpResponse<any>) => {
          if (result.status === 200) {
            return true;
          }
          return false;
        })
      );
  };

  /** @deprecated use updateManagementData */
  public updateCoordinationAnswerGroup(caseId: string, answerGroup: AnswerGroup) {
    let apiURL = `${environment.baseApi}/case/${caseId}/coordination/`;
    if (answerGroup.answerGroupId) {
      apiURL = apiURL + `${answerGroup.answerGroupId}`;
    }

    const questionGroupId = answerGroup.questionGroupId;
    const answers = answerGroup.answers;
    const answerGroupId = answerGroup.answerGroupId;

    return this.http
      .put(apiURL, { questionGroupId, answers, answerGroupId }, { observe: "response" })
      .pipe(
        map((response: HttpResponse<object>) => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  }
}
