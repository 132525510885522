import { ErrorHandler, Injectable } from "@angular/core";
import { NotificationService } from "@vp/shared/notification";

import { environment } from "./../environments/environment";
import { Logger } from "./services/logging/logging.service";

const FRIENDLY_GENERIC_ERROR =
  "There was an error on this page. Please reload the application and try again.";

@Injectable({
  providedIn: "root"
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private logger: Logger, private notificationService: NotificationService) {}

  handleError(error: any): void {
    // Log client-side errors only (not http errors handled by server-error.interceptor)
    const message = environment.production ? FRIENDLY_GENERIC_ERROR : error.toString();
    this.notificationService.errorMessage(message);
    this.logger.logException(error);
    this.handleOutdatedModuleFiles(error);
  }

  private handleOutdatedModuleFiles(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
    }
  }
}
