import { CaseType, Group } from "@vp/core/models";
import { CaseCommunicationCount, UserLite } from ".";
import { GroupViewModel } from "../ui";

export interface VirtualCareCaseDashboardViewModel {
  caseId: string;
  displayName: string;
  createdByUserEmail: string;
  createdByUser: UserLite;
  caseType: CaseType;
  createdDateTime: string;
  assignedDateTime: string | null;
  lastUpdatedDateTime: string;
  answeredQuestionsCount: number;
  totalQuestionsCount: number;
  resultsCount: number;
  responsesCount: number;
  statusFriendlyId: string;
  providers: string[];
  groupIds: string[];
  groups: Group[];
  communicationCounts: CaseCommunicationCount[];
  zoomCallStatus: ZoomCallStatus;
  zoomCallApplicationHostId: string;
  callLightStatus: CallLightStatus;
  lastMessageSent: string;
  lastMessageSentDateTime: string;
  room: GroupViewModel | null;
  hub: GroupViewModel | null;
  assignedDevice: UserLite;
}

export enum ZoomCallStatus {
  Available = 1,
  InCall = 2,
  Unassigned = 3,
  Offline = 4,
  Unknown = 99
}

export enum CallLightStatus {
  On = 1,
  Off = 2,
  Unassigned = 3,
  Unknown = 99
}
