import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AdvancedCommand } from "../../models/advanced-command";

@Component({
  selector: "lib-command-list",
  templateUrl: "./command-list.component.html",
  styleUrls: ["./command-list.component.scss"]
})
export class CommandListComponent {
  @Input() commands: AdvancedCommand[] | undefined;
  @Output() sendCommand = new EventEmitter<AdvancedCommand>();

  constructor() {}

  sendAdvancedCommand(command: AdvancedCommand): void {
    this.sendCommand.emit(command);
  }
}
