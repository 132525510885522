import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Role } from "@vp/core/models";
import { LocalStorageData, LocalStorageService } from "@vp/shared/local-storage";
import { AppStoreService } from "@vp/shared/store/app";
import { RouterUtilities } from "@vp/shared/utilities";
import { combineLatest, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";

export const ROUTE_DEFAULT = "/home";

@Injectable({
  providedIn: "root"
})
export class RedirectGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly appStoreService: AppStoreService,
    private localStorageService: LocalStorageService,
    private routerUtilities: RouterUtilities
  ) {
    localStorageService.load();
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.appStoreService.selectedRole,
      this.localStorageService.localStorage$
    ]).pipe(
      mergeMap(([role, localStorage]: [Role, LocalStorageData | null]) => {
        if (localStorage?.data?.redirect === true) {
          var pathname = localStorage.data?.pathname;
          const search = RouterUtilities.parseQueryString(localStorage.data?.search);
          const url = new URL(pathname, window.location.origin);
          const pathParts = url.pathname.split("/");
          this.localStorageService.clearAll();
          return this.router.navigate(pathParts, {
            queryParams: search
          });
        }
        let { path, queryParams } = this.routerUtilities.getRouteDefaultFromRole(role as Role);
        return this.router.navigate([path], {
          queryParams: queryParams
        });
      })
    );
  }
}
