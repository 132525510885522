import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogInputData {
  title: string;
  message: string;
  action: string;
  value: any;
}

@Component({
  selector: "vp-input-dialog",
  templateUrl: "./input-dialog.component.html",
  styleUrls: ["./input-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInputData
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }
}
