import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class LocalStorageRefService {
  get localStorage(): Storage {
    return getLocalStorage();
  }

  constructor() {}
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function getLocalStorage(): Storage {
  return localStorage;
}
