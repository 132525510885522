import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { filterNullMap } from "@vp/shared/operators";
import { first, tap } from "rxjs/operators";
import { FeatureFlagsService } from "./feature-flag.service";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[featureFlag]"
})
export class FeatureFlagDirective implements OnInit {
  @Input() set featureFlag(val: string) {
    if (val) {
      this.requiredFlag = val;
    }
  }
  @Input() set feature(val: string) {
    if (val) {
      this._feature = val;
    }
  }
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private featureFlagsService: FeatureFlagsService
  ) {}

  private requiredFlag = "";
  private _feature = "common";

  ngOnInit() {
    this.featureFlagsService
      .featureFlags$(this._feature)
      .pipe(
        filterNullMap(),
        first(),
        tap((feature: any) => {
          if (!!feature && feature[this.requiredFlag] === true) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainerRef.clear();
          }
        })
      )
      .subscribe();
  }
}
