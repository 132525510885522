import { Pipe, PipeTransform } from "@angular/core";
import { indexOfOf } from "@vp/shared/utilities";

@Pipe({
  name: "indexofof"
})
export class IndexOfOfPipe implements PipeTransform {
  transform(value: any, outer: number[], inner: number[]): any {
    return indexOfOf(value, outer, inner);
  }
}
