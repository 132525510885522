import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "lib-formly-mixed-schema-type",
  templateUrl: "./formly-mixed-schema-type.component.html",
  styleUrls: ["./formly-mixed-schema-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMixedSchemaTypeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
