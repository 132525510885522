import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { NavigationItem } from "../side-nav";

@Component({
  selector: "vp-nav-item",
  templateUrl: "./nav-item.component.html",
  styleUrls: ["./nav-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavItemComponent {
  @Input() item!: NavigationItem | null;
}
