/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { timer, Observable, EMPTY } from "rxjs";
import { scan, tap, switchMapTo, first, catchError } from "rxjs/operators";

function checkAttempts(maxAttempts: number) {
  return (attempts: number) => {
    if (attempts > maxAttempts) {
      throw new Error("Error: max attempts");
    }
  };
}

/**
 * Call observable source periodically
 * @param pollStart Start time in ms
 * @param pollInterval Interval time in ms
 * @param maxAttempts Max attempts before observable completes
 * @param responsePredicate Stops polling when true
 */
export function pollUntil<T>(
  pollStart: number,
  pollInterval: number,
  maxAttempts: number,
  responsePredicate: (res: any) => boolean
) {
  return (source: Observable<T>) =>
    timer(pollStart, pollInterval).pipe(
      scan(attempts => ++attempts, 0),
      tap(checkAttempts(maxAttempts)),
      switchMapTo(source),
      first(responsePredicate),
      catchError(() => EMPTY)
    );
}
