import { Injectable, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { CaseApiService } from "@vp/core/case";
import {
  CaseData,
  CaseType,
  PaymentRequestResponse,
  PaymentTransactionResult,
  TransactionStatusEvent,
  User
} from "@vp/core/models";
import { CaseTypesActions, CaseTypesState } from "@vp/data-access/case-types";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap, filterRecord } from "@vp/shared/operators";
import { getMonthYearFromString, hasOwnProperty } from "@vp/shared/utilities";
import { Guid } from "guid-typescript";
import { combineLatest, EMPTY, Observable, of, Subject } from "rxjs";
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap
} from "rxjs/operators";
import { AlertItem } from "../../components/alert/alert.component";
import { CaseContextService } from "../case-context/case-context.service";
import {
  TransactionCancelledEvent,
  TransactionCreatedEvent
} from "./events/payment-service.events";

@Injectable()
export class PaymentService implements OnDestroy {
  @Select(CaseTypesState.caseTypeFee) fee$!: Observable<number>;

  get transaction(): Observable<PaymentTransaction> {
    return this._transactionStream.pipe(filterNullMap(), takeUntil(this._cancelled$));
  }

  get cancelled(): Observable<Guid> {
    return this._cancelled$.pipe(filterNullMap());
  }

  private readonly _cancelled$ = new Subject<Guid>();
  private readonly _destroyed$ = new Subject<void>();
  private readonly _transactionStream = new Subject<PaymentTransaction>();

  constructor(
    private readonly caseApiService: CaseApiService,
    private readonly caseContextService: CaseContextService,
    private readonly eventAggregator: EventAggregator,
    private readonly notifications: NotificationService,
    private readonly store: Store
  ) {
    this.caseContextService.contextCaseType.pipe(
      filterNullMap(),
      tap((caseType: CaseType) => {
        this.store.dispatch(new CaseTypesActions.GetCaseTypeFee(caseType.caseTypeId));
      })
    );

    this._transactionStream.subscribe((transaction: PaymentTransaction) => {
      eventAggregator.emit(new TransactionCreatedEvent(transaction), "PaymentService");
    });
    this._cancelled$.subscribe((transactionId: Guid) => {
      eventAggregator.emit(new TransactionCancelledEvent(transactionId), "PaymentService");
    });

    this.caseContextService.pluckRecord("payment", (record: Record<string, unknown>) => {
      if (record.hasOwnProperty("payment")) {
        const payment = record["payment"] as any;
        if (payment.hasOwnProperty("submit") && payment["submit"] === true) {
          of(record)
            .pipe(
              extractPaymentRequest(),
              mergeMap((request: PaymentTransactionRequest) => {
                return combineLatest([
                  of(request),
                  this.caseContextService.Context.pipe(filterNullMap(), take(1)),
                  this.fee$
                ]);
              }),
              take(1),
              concatMap(
                ([request, caseData, fee]: [PaymentTransactionRequest, CaseData, number]) => {
                  return this.submit(caseData.caseId, fee, request).pipe(
                    switchMap((ptr: PaymentTransactionResult) => {
                      if (ptr.status === "success") {
                        this.notifications.successMessage("Payment success");
                        return this.caseApiService.submitCase(caseData.caseId);
                      } else {
                        this.notifications.errorMessage(ptr.message);
                      }
                      return of(false);
                    })
                  );
                }
              ),
              takeUntil(this._destroyed$)
            )
            .subscribe(submitSuccess => {
              if (!submitSuccess) {
                this.notifications.errorMessage("Payment Failed");
              }
            });
        }
      }
    });
  }

  ngOnDestroy() {
    this.cancel();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  initalize(user: User): Observable<PaymentTransaction> {
    this._transactionStream.next({
      transactionId: Guid.create(),
      record: null,
      user: user
    } as PaymentTransaction);
    return this._transactionStream.asObservable().pipe(take(1));
  }

  submit(
    caseId: string,
    fee: Number,
    paymentRequest: PaymentTransactionRequest
  ): Observable<PaymentTransactionResult> {
    if (!paymentRequest) {
      throw Error("paymentRequest is required.");
    }
    if (!fee || fee === 0) {
      throw Error("fee is required.");
    }
    if (!caseId || caseId.length === 0) {
      throw Error("Valid CaseId is required.");
    }

    paymentRequest.amount = fee;

    return of({
      caseId: caseId,
      paymentRequest: paymentRequest
    }).pipe(
      take(1),
      concatMap(request => {
        return this.caseApiService.paymentRequest(request.caseId, request.paymentRequest);
      }),
      map((response: PaymentRequestResponse) => {
        return this.mapTransactionResult(response);
      }),
      take(1),
      tap((result: PaymentTransactionResult) => {
        this.eventAggregator.emit(new TransactionStatusEvent(result), "PaymentService.submit");
      }),
      catchError((errors: unknown) => {
        if (Array.isArray(errors) && errors.every(i => hasOwnProperty(i, "type"))) {
          this.eventAggregator.emit(
            new TransactionStatusEvent({
              status: "failed",
              message: errors.map((item: AlertItem) => item.message).join(", "),
              record: {
                trasactionId: null
              }
            } as PaymentTransactionResult),
            "PaymentService.submit"
          );
          if (errors?.length > 0) {
            this.notifications.errorMessage(errors[0].message);
          } else {
            this.notifications.errorMessage("Payment failed");
          }
        }
        return EMPTY;
      })
    );
  }

  mapTransactionResult(response: PaymentRequestResponse) {
    return {
      status: response.paymentMade ? "success" : "failed",
      message: response.message,
      record: {
        transactionId: response.transactionId
      }
    } as PaymentTransactionResult;
  }

  cancel(): void {
    this._transactionStream.subscribe((transaction: PaymentTransaction) => {
      this._cancelled$.next(transaction.transactionId);
    });
  }
}

export const extractPaymentRequest = () => {
  return (source$: Observable<Record<string, unknown>>) => {
    return source$.pipe(
      filterRecord("payment"),
      map((record: Record<string, unknown>) => {
        const payment: any = record["payment"];
        if (!Object.keys(payment).includes("expirationDate")) {
          throw Error("expirationDate is required.");
        }
        if (!Object.keys(payment).includes("creditCardNumber")) {
          throw Error("creditCardNumber is required.");
        }
        if (!Object.keys(payment).includes("securityCode")) {
          throw Error("securityCode is required.");
        }
        const monthYear = getMonthYearFromString(payment["expirationDate"]);
        return {
          amount: null,
          creditCardNumber: payment["creditCardNumber"],
          creditCardExpirationMonth: monthYear.month,
          creditCardExpirationYear: monthYear.year,
          creditCardCvv2: payment["securityCode"]
        } as PaymentTransactionRequest;
      })
    );
  };
};

export interface PaymentTransaction {
  transactionId: Guid;
  record: Record<string, any> | null;
  user: User;
}

export interface PaymentTransactionRequest {
  amount?: Number | null; // as decimal in API
  billToStreet?: string;
  billToZip?: string;
  creditCardNumber: string;
  creditCardExpirationMonth: string;
  creditCardExpirationYear: string;
  creditCardCvv2: string;
}
