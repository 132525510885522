import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NavigationItem } from "./navigation-item";

@Injectable({
  providedIn: "root"
})
export class SideNavService {
  public navigationItemsChanged: Subject<NavigationItem[]> = new Subject<NavigationItem[]>();

  constructor() {}
}
