import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UserAssignRolesModule } from "@vp/administration/user/ui/user-assign-roles";
import { GenericDialogModule } from "@vp/shared/components/generic-dialog";
import { MaterialModule } from "@vp/shared/material";
import { ContextDisplayModule, DepartmentPipe, RolePipe } from "@vp/shared/pipes/context-display";
import { UtilitiesModule } from "@vp/shared/utilities";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserAssignedRolesComponent } from "./user-assigned-roles.component";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    NgxPermissionsModule,
    MatSlideToggleModule,
    GenericDialogModule,
    UserAssignRolesModule,
    UtilitiesModule,
    ContextDisplayModule
  ],
  exports: [UserAssignedRolesComponent],
  declarations: [UserAssignedRolesComponent],
  providers: [DepartmentPipe, RolePipe]
})
export class UserAssignedRolesModule {}
