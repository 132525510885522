import { Injectable } from "@angular/core";
import { Store } from "rxjs-observable-store";
import { FileState, IImageDimensions } from "../file-upload.component";

export class UploadState {
  files: FileState[] = [];
  fileCount = 0;
  isDisabled = true;
  dimensions!: IImageDimensions; // TODO Move logic from component to here
}

@Injectable()
export class UploadStoreService extends Store<UploadState> {
  constructor() {
    super(new UploadState());
  }
}
