import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource as ClientArrayTableDataSource } from "@angular/material/table";
import { FieldType } from "@ngx-formly/core";
import { environment } from "../../../environments/environment";
import { SharedConfirmationService } from "@vp-libs/shared/confirmation";
import { DicomStudy } from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UiStoreService } from "@vp/shared/store/ui";
import { NgxPermissionsService } from "ngx-permissions";
import { EMPTY } from "rxjs";
import { concatMap, switchMap, tap } from "rxjs/operators";
import { DicomStudyApiService } from "../../services/api/dicom-study-api.service";
import { CaseContextService } from "../../services/case-context/case-context.service";
import { RenameDialogComponent } from "../rename-dialog/rename-dialog.component";

@Component({
  selector: "vp-dicoms-grid",
  templateUrl: "./dicoms-grid.component.html",
  styleUrls: ["./dicoms-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DicomsGridComponent extends FieldType implements OnInit, AfterViewInit {
  clientArrayTableDataSource = new ClientArrayTableDataSource<DicomStudy>();
  displayedColumns = ["fileIcon", "name", "lastUpdatedDateTime"];
  caseData = this.caseContextService.Context;

  dicomStudies = this.caseContextService.contextDicomStudies.pipe(
    tap((dicomStudies: DicomStudy[]) => {
      // MatSort requires client-side array be used
      this.clientArrayTableDataSource.data = dicomStudies;
    })
  );

  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly notificationService: NotificationService,
    private readonly confirmationDialog: SharedConfirmationService,
    private readonly caseContextService: CaseContextService,
    public readonly permConst: PermissionsConstService,
    public readonly uiStoreService: UiStoreService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly dicomStudyApiService: DicomStudyApiService
  ) {
    super();
  }

  get imagePermissions() {
    return this.permConst.Case.Image;
  }

  ambraViewerLink(securelink: string) {
    return `${environment.ambraViewerBaseUrl}${securelink}`;
  }

  ngOnInit(): void {
    this.ngxPermissionsService
      .hasPermission([
        this.permConst.Case.Image.Write, // used for rename
        this.permConst.Case.Image.Delete
      ])
      .then(hasPermissions => {
        if (hasPermissions) {
          this.displayedColumns.push("actions");
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  ngAfterViewInit(): void {
    // MatTableDataSource automatically handles sorting
    this.clientArrayTableDataSource.sort = this.sort;

    // MatTableDataSource automatically handles paging
    // TODO Add pager and uncomment if needed
    //this.dataSource.paginator = this.paginator;
  }

  deleteDicomStudy(caseId: string | undefined, dicomStudy: DicomStudy) {
    if (!caseId) {
      throw new Error("context is missing");
    }

    this.confirmationDialog
      .open(`You are about to permanently delete ${dicomStudy.name}`, "Delete")
      .afterConfirmed()
      .pipe(
        switchMap(() => {
          return this.dicomStudyApiService.deleteDicomStudy(caseId, dicomStudy.studyInstanceUid);
        }),
        switchMap(() => this.caseContextService.contextRefresh())
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Successfully deleted"),
        error: () => this.notificationService.warningMessage("Failed to delete study.")
      });
  }

  renameDicomStudy(caseId: string | undefined, dicomStudy: DicomStudy) {
    if (!caseId) {
      throw new Error("context is missing");
    }
    const dialogRef = this.dialog.open(RenameDialogComponent, {
      width: "400px",
      data: dicomStudy.name
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(dialogResult => {
          if (dialogResult && dialogResult !== dicomStudy.name) {
            dicomStudy.name = dialogResult;
            return this.dicomStudyApiService.updateDicomStudy(caseId, dicomStudy);
          }

          /* Completes the stream (wont call next, only complete)
           This occurs when the user selects Cancel on the dialog box.
           */
          return EMPTY;
        }),
        concatMap(() => this.caseContextService.contextRefresh())
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Rename successful"),
        error: () => this.notificationService.warningMessage("Unable to rename study.")
      });
  }

  trackByFn = (
    _index: number,
    item: { fileName: string; url: string; fileDescription: string }
  ): string => item.url;
}
