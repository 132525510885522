import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { ui } from "@vp/core/models";
import { UiStoreService } from "@vp/shared/store/ui";
import { activatedRouteData } from "@vp/shared/utilities";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-quick-search",
  templateUrl: "./quick-search.component.html",
  styleUrls: ["./quick-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickSearchComponent implements OnInit, OnDestroy {
  @Input() showButtonText = true;
  quickSearchTag$: Observable<keyof typeof ui> | undefined;
  inputOriginal = "";
  input = "";
  focused: boolean = false;
  @ViewChild("searchinput", { static: false }) searchinput!: ElementRef;

  constructor(
    public uiStoreService: UiStoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  private readonly destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.quickSearchTag$ = this.router.events.pipe(
      activatedRouteData(this.activatedRoute),
      map((data: Data) => data.quickSearchTag as keyof typeof ui)
    );

    this.activatedRoute.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: queryParamMap => {
        this.inputOriginal = queryParamMap.get("search") ?? "";
        this.input = queryParamMap.get("search") ?? "";
      }
    });
  }

  searchClicked() {
    this.redirect(this.input || "");
  }

  enterPress() {
    this.redirect(this.input);
  }

  clear() {
    // User wants to clear input, but do not perform search yet
    this.input = "";
    this.focused = true;
  }

  private redirect(searchString: string) {
    this.router.navigate([], {
      queryParams: {
        search: searchString
      },
      queryParamsHandling: "merge"
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
