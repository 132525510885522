import { ThemeManagerConfig } from "@vp-libs/shared/theme-manager";

export const appThemeManagerConfig: ThemeManagerConfig = {
  debug: {
    hostname: undefined
  },
  hostnames: {
    localhost: {
      title: "Breakout",
      metas: [{ name: "author", content: "Valore Partners" }],
      bodyClass: "theme-breakout",
      styles: ["libs-theme-breakout-styles.css", "libs-theme-breakout-badge-localhost.css"],
      logoUrl: "assets/libs-theme-breakout/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-breakout/logo.png",
      googleTagManagerId: "GTM-PRBBXD2"
    },
    "default-theme": {
      title: "Mercury",
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "breakout-dev.azureedge.net": {
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      googleTagManagerId: "GTM-PRBBXD2"
    },
    "breakout-tst.azureedge.net": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "breakout-demo1.azureedge.net": {
      title: "Anonymous Ivy Site",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "second-opinion-dev.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "second-opinion-qa.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "second-opinion-prd.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "app.secondopinion.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "bcn.secondopinion.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "nso.secondopinion.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "virtualcare-dev.commonspirit.org": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-dev" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico",
      googleTagManagerId: undefined
    },
    "cdne-w1-vcdp-spa-dev.azureedge.net": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-dev" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico",
      googleTagManagerId: undefined
    },
    "cdne-w1-vcdp-spa-qa.azureedge.net": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-qa" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico",
      googleTagManagerId: undefined
    },
    "virtualcare-qa.commonspirit.org": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-qa" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico",
      googleTagManagerId: undefined
    },
    "virtualcare.commonspirit.org": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-prod" }],
      bodyClass: "theme-vsd",
      styles: [],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico",
      googleTagManagerId: undefined
    },
    "ivy.secondopinion.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso/styles.css"],
      logoUrl: "assets/ivy/ivylogo.png",
      faviconUrl: "assets/libs-theme-nso/favicon.png",
      googleTagManagerId: undefined
    },
    "cdne-w1-2nd-op-int-spa-dev.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "cdne-w1-2nd-op-int-spa-qa.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "cdne-w1-2nd-op-int-prd.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "cdne-w1-2nd-op-int-spa-prd.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "remotesecondopinion.dignityhealthinternational.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "remotesecondopinion.commonspirit.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "remotesecondopinion-dev.dignityhealthinternational.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "remotesecondopinion-qa.dignityhealthinternational.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "remote-second-opinion-qa.commonspirit.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "remote-second-opinion-dev.commonspirit.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico",
      googleTagManagerId: undefined
    },
    "cdne-w1-ref-mgmt-dev.azureedge.net": {
      title: "Referral Management",
      metas: [{ name: "application-name", content: "referral-management-dev" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico",
      googleTagManagerId: undefined
    },
    "cdne-w1-ref-mgmt-qa.azureedge.net": {
      title: "Referral Management",
      metas: [{ name: "application-name", content: "referral-management-qa" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico",
      googleTagManagerId: undefined
    }
  }
};
