import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { SharedConfirmationService } from "@vp-libs/shared/confirmation";
import {
  AssignUserEvent,
  CallLightActivatedEvent,
  CallLightDeactivatedEvent,
  CaseChatEvent,
  CaseDataChangedEvent,
  CaseStatusChangedEvent,
  CaseUpdatedEvent,
  DeviceConnectionChangedEvent,
  DeviceRoomAssignedEvent,
  DeviceRoomUnassignmentEvent,
  MessageToPatientEvent,
  MovementInRoomDetected,
  RealTimeNotification,
  SignalRConnected,
  SignalrMethods,
  User,
  ZoomWebhookEvent
} from "@vp/core/models";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { IS_IVY_API } from "../app-initializer.factory";
import { Logger } from "./logging/logging.service";

export interface SignalREvent {
  method: string;
  data: any;
  eventTime: Date;
}

export interface SignalRHubConnection {
  state: string | null;
  lastUpdated: Date | null;
  connectionId: string | null;
  receivedEvents: SignalREvent[];
}

@Injectable({
  providedIn: "root"
})
export class SignalRService {
  private hubConnection!: HubConnection;
  private hubConnection$: BehaviorSubject<SignalRHubConnection> =
    new BehaviorSubject<SignalRHubConnection>({
      state: null,
      lastUpdated: null,
      connectionId: null,
      receivedEvents: new Array()
    } as SignalRHubConnection);

  constructor(
    private readonly logger: Logger,
    private readonly eventAggregrator: EventAggregator,
    private readonly _http: HttpClient,
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly confirmationDialog: SharedConfirmationService
  ) {}

  get getHubConnection$(): Observable<SignalRHubConnection> {
    return this.hubConnection$.asObservable();
  }

  public startConnection = (user: User): void => {
    this.getSignalRConnection(user.userId)
      .pipe(take(1))
      .subscribe(signalrConnection => {
        //signalRConnection contains the URL to the Azure SignalR instance
        //and the accessToken granting the user access
        const options = {
          accessTokenFactory: () => signalrConnection.accessToken
        };

        this.hubConnection = new HubConnectionBuilder()
          .withUrl(signalrConnection.url, options)
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Warning)
          .build();

        this.registerConnectionEvents();
        this.registerClientMethods();

        this.start();
      });
  };

  private registerConnectionEvents = (): void => {
    this.hubConnection.onclose(connection => {
      this.logger.logEvent(
        `SignalR Connection Closed: ${connection?.message}. Stack: ${connection?.stack}`
      );
      this.updateConnectionState();

      this.confirmationDialog
        .open(
          `When this application is placed in the background your browser can disable it.
           This will cause a timeout due to inactivity. Pressing Ok will refresh your page.`,
          undefined,
          "Your session has timed out due to inactivity",
          undefined,
          "600px",
          false,
          true
        )
        .afterConfirmed()
        .subscribe(() => {
          window.location.reload();
        });
    });

    this.hubConnection.onreconnecting(connection => {
      this.logger.logEvent(
        `SignalR Reconnecting: ${connection?.message}. Stack: ${connection?.stack}`
      );
      this.updateConnectionState();
    });

    this.hubConnection.onreconnected(connection => {
      this.logger.logEvent(`SignalR Reconnected: ${connection}.`);
      this.updateConnectionState();
    });
  };

  private getSignalRConnection = (userId: string): Observable<any> => {
    const apiUrl = `${environment.baseApi}/realtime/negotiate`;
    return this._http.get<any>(apiUrl, {
      //we must pass this header so signalR can assign a UserId per connection
      //this is needed in order to add users to groups, as that UserId identifies which connection to place in a group
      headers: new HttpHeaders().set("x-ms-signalr-userid", userId)
    });
  };

  public addToGroup = (userId: string | undefined, groupName: string): void => {
    if (!this.isIvyApi) {
      var notification: RealTimeNotification = {
        groupName: groupName,
        userId: userId
      };

      const apiUrl = `${environment.baseApi}/realtime/addUserToGroup`;
      this._http.post<boolean>(apiUrl, notification).subscribe();
    }
  };

  public removeFromGroup = (userId: string | undefined, groupName: string): void => {
    if (!this.isIvyApi) {
      var notification: RealTimeNotification = {
        groupName: groupName,
        userId: userId
      };

      const apiUrl = `${environment.baseApi}/realtime/removeUserFromGroup`;
      this._http.post<boolean>(apiUrl, notification).subscribe();
    }
  };

  //expose client methods signalR can call from the API
  //when SignalR calls a method, the eventAggregrator will broadcast it to anyone subscribed
  private registerClientMethods = (): void => {
    if (!this.isIvyApi) {
      this.hubConnection.on(SignalrMethods.newCaseChat, data => {
        this.logSignalrEvent(SignalrMethods.newCaseChat, data);
        this.eventAggregrator.emit(new CaseChatEvent(data), SignalrMethods.newCaseChat);
      });

      this.hubConnection.on(SignalrMethods.newAssignUser, data => {
        this.logSignalrEvent(SignalrMethods.newAssignUser, data);
        this.eventAggregrator.emit(new AssignUserEvent(data), SignalrMethods.newAssignUser);
      });

      this.hubConnection.on(SignalrMethods.updatedCase, data => {
        this.logSignalrEvent(SignalrMethods.updatedCase, data);
        this.eventAggregrator.emit(new CaseUpdatedEvent(data), SignalrMethods.updatedCase);
      });

      this.hubConnection.on(SignalrMethods.callLightActivated, data => {
        this.logSignalrEvent(SignalrMethods.callLightActivated, data);
        this.eventAggregrator.emit(
          new CallLightActivatedEvent(data),
          SignalrMethods.callLightActivated
        );
      });

      this.hubConnection.on(SignalrMethods.callLightDeactivated, data => {
        this.logSignalrEvent(SignalrMethods.callLightDeactivated, data);
        this.eventAggregrator.emit(
          new CallLightDeactivatedEvent(data),
          SignalrMethods.callLightDeactivated
        );
      });

      this.hubConnection.on(SignalrMethods.movementInRoomDetected, data => {
        this.logSignalrEvent(SignalrMethods.movementInRoomDetected, data);
        this.eventAggregrator.emit(
          new MovementInRoomDetected(data),
          SignalrMethods.movementInRoomDetected
        );
      });

      this.hubConnection.on(SignalrMethods.deviceConnectionChanged, data => {
        this.logSignalrEvent(SignalrMethods.deviceConnectionChanged, data);
        this.eventAggregrator.emit(
          new DeviceConnectionChangedEvent(data),
          SignalrMethods.deviceConnectionChanged
        );
      });

      this.hubConnection.on(SignalrMethods.caseDataChanged, data => {
        this.logSignalrEvent(SignalrMethods.caseDataChanged, data);
        this.eventAggregrator.emit(new CaseDataChangedEvent(data), SignalrMethods.caseDataChanged);
      });

      this.hubConnection.on(SignalrMethods.roomAssignedToDevice, data => {
        this.logSignalrEvent(SignalrMethods.roomAssignedToDevice, data);
        this.eventAggregrator.emit(
          new DeviceRoomAssignedEvent(data),
          SignalrMethods.roomAssignedToDevice
        );
      });

      this.hubConnection.on(SignalrMethods.roomUnassignedFromDevice, data => {
        this.logSignalrEvent(SignalrMethods.roomUnassignedFromDevice, data);
        this.eventAggregrator.emit(
          new DeviceRoomUnassignmentEvent(data),
          SignalrMethods.roomUnassignedFromDevice
        );
      });

      this.hubConnection.on(SignalrMethods.interactiveSessionStarted, data => {
        this.logSignalrEvent(SignalrMethods.interactiveSessionStarted, data);
        this.eventAggregrator.emit(
          new ZoomWebhookEvent(data),
          SignalrMethods.interactiveSessionStarted
        );
      });

      this.hubConnection.on(SignalrMethods.interactiveSessionEnded, data => {
        this.logSignalrEvent(SignalrMethods.interactiveSessionEnded, data);
        this.eventAggregrator.emit(
          new ZoomWebhookEvent(data),
          SignalrMethods.interactiveSessionEnded
        );
      });

      this.hubConnection.on(SignalrMethods.messageToPatient, data => {
        this.logSignalrEvent(SignalrMethods.messageToPatient, data);
        this.eventAggregrator.emit(
          new MessageToPatientEvent(data),
          SignalrMethods.messageToPatient
        );
      });

      this.hubConnection.on(SignalrMethods.caseStatusChanged, data => {
        this.logSignalrEvent(SignalrMethods.caseStatusChanged, data);
        this.eventAggregrator.emit(
          new CaseStatusChangedEvent(data),
          SignalrMethods.caseStatusChanged
        );
      });
    }
  };

  private start = async () => {
    await this.hubConnection
      .start()
      .then(() => {
        this.logger.logEvent("Connected to SignalR");
        this.eventAggregrator.emit(new SignalRConnected(true), "signalRConnected");
        this.updateConnectionState();
      })
      .catch(err => {
        this.logger.logEvent("Error while connecting to SignalR: " + err);
        setTimeout(() => this.start(), 5000);
      });
  };

  private updateConnectionState = () => {
    var events = this.hubConnection$?.value.receivedEvents;
    this.hubConnection$.next({
      state: this.hubConnection.state,
      lastUpdated: new Date(),
      connectionId: this.hubConnection.connectionId,
      receivedEvents: events
    } as SignalRHubConnection);
  };

  private logSignalrEvent(method: string, data: any) {
    if (this.hubConnection$?.value) {
      var hubConnectionSubject = this.hubConnection$?.value;
      var parsedData = JSON.stringify(data, null, 4);

      var printedEvent = {
        method: method,
        data: parsedData,
        eventTime: new Date()
      } as SignalREvent;

      //rolling list of last 20 events.
      hubConnectionSubject.receivedEvents = hubConnectionSubject.receivedEvents.slice(
        //Get the last 19
        Math.max(hubConnectionSubject.receivedEvents.length - 19, 0)
      );
      //add the new one to the beginning of the list
      hubConnectionSubject.receivedEvents.unshift(printedEvent);
      this.hubConnection$?.next(hubConnectionSubject);
    }
  }

  public copySignalrLogs = (): void => {
    this.hubConnection$.pipe(take(1)).subscribe(hub => {
      let logs =
        "SignalR Status: " +
        hub.state +
        "\nConnection Last Updated: " +
        hub.lastUpdated?.toLocaleString() +
        "\nConnectionId: " +
        hub.connectionId +
        "\n\nEvents:\n";

      let events = hub.receivedEvents
        .map(e => `${e.eventTime.toLocaleString()}: ${e.method}\n${e.data}\n`)
        .join("\n");

      if (events.length === 0) {
        events = "No events received";
      }

      logs = logs.concat(events);
      navigator.clipboard.writeText(logs);
    });
  };
}
