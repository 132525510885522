import { Pipe, PipeTransform } from "@angular/core";

import { formatDate } from "@angular/common";

@Pipe({
  name: "formatDateString",
  pure: true
})
export class FormatDateStringPipe implements PipeTransform {
  transform(firstDateTimeString: string): string {
    return this.stringToDate(firstDateTimeString);
  }
  stringToDate(firstDateTimeString: string): string {
    const firstDateTime = formatDate(new Date(firstDateTimeString), "MM/dd/yyyy h:mma", "en-US");
    return firstDateTime;
  }
}
