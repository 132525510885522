import { Injectable, InjectionToken } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Tag } from "@vp/core/models";
import { tap } from "rxjs/operators";
import { TagsApiService } from "../api/tags-api.service";
import { TagsActions } from "./tags-actions";

export const TAG_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export interface TagsStateModel {
  tags: Tag[];
  selectedId: string | null;
}

@State<TagsStateModel>({
  name: "tags",
  defaults: { tags: [], selectedId: null }
})
@Injectable()
export class TagsState {
  constructor(private readonly _tagsApiService: TagsApiService) {}

  @Selector()
  public static tags(state: TagsStateModel) {
    return state.tags;
  }

  @Selector()
  public static selectedTagId(state: TagsStateModel) {
    return state.selectedId;
  }

  @Action(TagsActions.CreateTag)
  create(ctx: StateContext<TagsStateModel>, { tag }: TagsActions.CreateTag) {
    return this._tagsApiService.addTag(tag).pipe(
      tap((tag: Tag) => {
        ctx.patchState({ tags: [...ctx.getState().tags, tag] });
      })
    );
  }

  @Action(TagsActions.LoadTags)
  load(ctx: StateContext<TagsStateModel>, {}: TagsActions.LoadTags) {
    return this._tagsApiService.getTags().pipe(
      tap((tags: Tag[]) => {
        ctx.patchState({ tags: tags });
      })
    );
  }

  @Action(TagsActions.SetSelectedTagId)
  setSelectedId(ctx: StateContext<TagsStateModel>, { tagId }: TagsActions.SetSelectedTagId) {
    ctx.patchState({ selectedId: tagId });
  }

  @Action(TagsActions.UpdateTag)
  update(ctx: StateContext<TagsStateModel>, { tag }: TagsActions.UpdateTag) {
    return this._tagsApiService.updateTag(tag).pipe(
      tap(() => {
        ctx.patchState({
          tags: [...ctx.getState().tags.filter(t => t.tagId !== tag.tagId), tag]
        });
      })
    );
  }

  @Action(TagsActions.DeleteTag)
  delete(ctx: StateContext<TagsStateModel>, { tagId }: TagsActions.DeleteTag) {
    return this._tagsApiService.deleteTag(tagId).pipe(
      tap(() => {
        ctx.patchState({ tags: [...ctx.getState().tags.filter(t => t.tagId !== tagId)] });
      })
    );
  }
}
