import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FocusStateModule } from "@vp/shared/directives/focus-state";
import { MaterialModule } from "@vp/shared/material";
import { QuickSearchComponent } from "./quick-search.component";

@NgModule({
  imports: [CommonModule, FormsModule, FocusStateModule, MaterialModule],
  declarations: [QuickSearchComponent],
  exports: [QuickSearchComponent]
})
export class QuickSearchModule {}
