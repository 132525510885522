import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { formlyJsonschemaOptions, UiSchemaLayoutProvider } from "@vp-libs/formly/json-schema";
import { NotificationService } from "@vp/shared/notification";
import { throwError } from "rxjs";
import { catchError, concatMap, map, switchMap } from "rxjs/operators";
import { FileApiService } from "../../services/api/file-api.service";
import { CaseContextService } from "../../services/case-context/case-context.service";
import { Logger } from "../../services/logging/logging.service";

@Component({
  selector: "vp-images-data",
  templateUrl: "./images-data.component.html",
  styleUrls: ["./images-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesDataComponent implements OnInit {
  formOptionsObject: FormlyFormOptions = { formState: { disabled: false } };
  caseId!: string | undefined;
  form = new FormGroup({});
  data!: Record<string, unknown>;
  fields!: FormlyFieldConfig[];
  contextImagesJson = this.caseContextService.contextImagesJson.pipe(
    concatMap(({ caseId, caseTypeId, data, schema }) =>
      this.layoutProvider.applyScopes("images-data", schema, `imageLayout${caseTypeId}`).pipe(
        map(scopedSchema => ({
          caseId,
          data,
          scopedSchema
        }))
      )
    )
  );

  constructor(
    private readonly logger: Logger,
    private readonly caseContextService: CaseContextService,
    private readonly fileApi: FileApiService,
    private notificationService: NotificationService,
    private layoutProvider: UiSchemaLayoutProvider
  ) {}

  ngOnInit(): void {
    this.contextImagesJson.subscribe({
      next: ({ caseId, data, scopedSchema }) => {
        this.caseId = caseId;
        this.data = data;
        this.fields = this.layoutProvider.getFieldConfig(scopedSchema, formlyJsonschemaOptions);
      },
      error: () => this.notificationService.errorMessage("Failed to retrieve images")
    });
  }

  modelChangeHandler(): void {
    this.fileApi
      .updateImageData(this.caseId as string, this.data)
      .pipe(
        switchMap(() => this.caseContextService.contextRefresh()),
        catchError((error: any) => {
          this.notificationService.errorMessage("Failed to save");
          return throwError(error);
        })
      )
      .subscribe({
        error: error => this.logger.logException(error, "ImagesDataComponent.modelChangeHandler")
      });
  }
}
