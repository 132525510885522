import { Component, Output, EventEmitter, OnInit, Input } from "@angular/core";
import { DateValueChange } from "./date-value-change.model";
import moment from "moment";
import { DateFilterService } from "./date-filter.service";

type dateSelect = "week" | "month" | "year" | "custom";

@Component({
  selector: "vp-date-filter",
  styleUrls: ["./date-filter.component.scss"],
  templateUrl: "./date-filter.component.html"
})
export class DateFilterComponent implements OnInit {
  dateSelectionOption: dateSelect;
  startDate: moment.Moment;
  endDate: moment.Moment;

  @Input()
  filterRangeType: "past" | "future";

  @Input()
  defaultDateSelect: "week" | "month" | "year" = "month";

  @Output()
  dateValueChanges = new EventEmitter<DateValueChange>();

  constructor(private dateService: DateFilterService) {}

  ngOnInit() {
    this.onDateSelectionChange(this.defaultDateSelect);
  }

  onDateSelectionChange(dateSelection: dateSelect) {
    if (dateSelection === this.dateSelectionOption && this.dateSelectionOption !== "custom") {
      return;
    }
    this.dateSelectionOption = dateSelection;
    this.setDateRange();
  }

  setDateRange() {
    switch (this.dateSelectionOption) {
      case "week":
        this.startDate =
          this.filterRangeType === "future"
            ? moment().startOf("day")
            : moment().subtract(1, "weeks").startOf("day");
        this.endDate =
          this.filterRangeType === "future"
            ? moment().add(1, "weeks").endOf("day")
            : moment().endOf("day");
        break;
      case "month":
        this.startDate =
          this.filterRangeType === "future"
            ? moment().startOf("day")
            : moment().subtract(1, "months").startOf("day");
        this.endDate =
          this.filterRangeType === "future"
            ? moment().add(1, "months").endOf("day")
            : moment().endOf("day");
        break;
      case "year":
        this.startDate =
          this.filterRangeType === "future"
            ? moment().startOf("day")
            : moment().subtract(1, "years").startOf("day");
        this.endDate =
          this.filterRangeType === "future"
            ? moment().add(1, "years").endOf("day")
            : moment().endOf("day");
        break;
      case "custom":
        this.startDate = this.startDate.startOf("day");
        this.endDate = this.endDate.endOf("day");
        break;
    }

    this.dateService.updateDateFilters(this.startDate.toDate(), this.endDate.toDate());
  }

  getLabel() {
    if (this.filterRangeType === "past") {
      return "Last";
    }
    return "Next";
  }
}
