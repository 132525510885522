import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { CaseContextService } from "@remove-circular-dep/services/case-context/case-context.service";
import { PaymentService } from "@remove-circular-dep/services/payment/payment.service";
import {
  OrganizationFeatures,
  PaymentPayload,
  PaymentTransactionResult,
  TransactionStatusEvent
} from "@vp/core/models";
import { CybersourceService } from "@vp/shared/cybersource-service";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { FeatureFlagsService } from "@vp/shared/feature-flags";
import { NotificationService } from "@vp/shared/notification";
import { BehaviorSubject, EMPTY, Subject, timer } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";

declare var Flex: any;

@Component({
  selector: "vp-cybersource-payment",
  templateUrl: "./cybersource-payment.component.html",
  styleUrls: ["./cybersource-payment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CybersourcePaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  microform: any;
  caseId: string;
  yearList: number[] = [];
  fee = new BehaviorSubject<number>(0);
  isPaid = new BehaviorSubject<boolean>(false);

  private readonly destroyed$ = new Subject();

  constructor(
    private paymentService: PaymentService,
    private caseContextService: CaseContextService,
    private cybersouceService: CybersourceService,
    private notificationService: NotificationService,
    private eventAggregator: EventAggregator,
    private readonly featureFlagsService: FeatureFlagsService
  ) {
    this.populateYearList();
    this.caseContextService.contextCaseId.subscribe(caseId => (this.caseId = caseId));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.featureFlagsService
      .featureEnabled$(OrganizationFeatures.paymentsInSubmit)
      .pipe(
        switchMap((featureEnabled: boolean) => {
          if (featureEnabled === true) {
            return this.caseContextService.serviceFeeTotal.pipe(map(total => total.balanceDue));
          }
          return this.paymentService.fee$;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(fee => this.fee.next(fee));
  }

  ngAfterViewInit(): void {
    this.fee
      .pipe(
        switchMap(fee => {
          if (fee <= 0) {
            this.isPaid.next(true);
            return EMPTY;
          } else {
            this.isPaid.next(false);
            // token expires after 15 min, refresh token every 10 min
            return timer(1, 600000).pipe(
              switchMap(_ => this.cybersouceService.getTransientToken())
            );
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(token => this.loadMicroform(token));
  }

  private loadMicroform(token: any) {
    let style = {
      input: {
        "font-size": "16px",
        color: "#3A3A3A"
      },
      ":disabled": {
        cursor: "not-allowed"
      },
      valid: {
        color: "green"
      },
      invalid: {
        color: "red"
      }
    };
    let flex = new Flex(token.keyId);
    this.microform = flex.microform({ styles: style });
    let cardNumber = this.microform.createField("number", {
      placeholder: "Enter card number"
    });
    let securityCode = this.microform.createField("securityCode", {
      placeholder: "***"
    });

    cardNumber.load("#number-container");
    securityCode.load("#securityCode-container");
  }

  onSubmit(form) {
    let options = {
      expirationMonth: form.value.expMonth,
      expirationYear: form.value.expYear.toString()
    };

    this.microform.createToken(options, (err, token) => {
      if (err) {
        switch (err.reason) {
          case "CREATE_TOKEN_VALIDATION_FIELDS":
            this.notificationService.errorMessage("Invalid Card");
            break;
          default:
            this.notificationService.errorMessage("Payment error, please try again");
            break;
        }
      } else {
        const payload = {
          totalAmount: this.fee.getValue().toFixed(2),
          currency: "USD",
          clientReferenceInformation: `caseId:${this.caseId}`,
          transientToken: token
        } as PaymentPayload;
        this.cybersouceService
          .submitPayment(this.caseId, payload)
          .subscribe((transactionResult: PaymentTransactionResult) => {
            this.eventAggregator.emit(
              new TransactionStatusEvent(transactionResult),
              "cybersource.submit"
            );
            if (transactionResult.status === "success") {
              this.isPaid.next(true);
              this.notificationService.successMessage("Payment success");
            } else {
              this.notificationService.errorMessage("Payment failed");
            }
            return EMPTY;
          });
      }
    });
  }

  private populateYearList() {
    const year = new Date().getFullYear();
    for (let index = 0; index < 15; index++) {
      this.yearList.push(year + index);
    }
  }
}
