import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
/**
 * This is a pure (dumb) component. Do not add domain logic or models and instead prefer generic inputs
 */
export const PERCENTAGE_BAD_VALUE = "-";

export interface ProgressOption {
  min?: number;
  max?: number;
  label?: string;
  color?: ThemePalette;
}

/**
 * Shows a progress percentage circle based on current/total inputs
 * Also shows a label and circle color based on min/max options
 */
@Component({
  selector: "vp-progress-circle",
  templateUrl: "./progress-circle.component.html",
  styleUrls: ["./progress-circle.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressCircleComponent implements OnChanges {
  @Input() current: undefined | number;
  @Input() total: undefined | number;
  @Input() color: ThemePalette = undefined;
  @Input() options: undefined | ProgressOption[];
  @Input() paid: undefined | boolean = false;
  value: undefined | number = undefined;
  percentage = PERCENTAGE_BAD_VALUE;
  stroke: undefined | string = undefined;
  label: undefined | string = undefined;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.current = changes.current?.currentValue ?? this.current;
    this.total = changes.total?.currentValue ?? this.total;
    this.options = changes.options?.currentValue ?? this.options;
    this.color = changes.color?.currentValue ?? this.color;
    this.paid = changes.paid?.currentValue ?? this.paid;
    this.update();
  }

  private update() {
    // Reset to unknown/bad value display as default
    this.value = undefined;
    this.percentage = PERCENTAGE_BAD_VALUE;
    this.stroke = undefined;
    this.label = undefined;

    // Update value and percentage label
    if (
      this.current !== undefined &&
      this.total !== undefined &&
      this.current >= 0 &&
      this.total >= 0 &&
      this.current <= this.total
    ) {
      this.value = (this.current / this.total) * 100;
      if (!isNaN(this.value)) {
        this.percentage = `${this.value.toFixed(0)}%`;
      }
    }

    // Update stroke or hide it if bad value
    if (this.value !== undefined && this.value !== 0 && !isNaN(this.value)) {
      this.stroke = `${this.value}, 100`;
    }

    // Update status label and color based on min/max options
    // The color in option, if specified, will override component color
    if (Array.isArray(this.options)) {
      this.options.forEach(option => {
        if (this.value !== undefined && option.min !== undefined && option.max !== undefined) {
          if (option.min === option.max && this.value === option.min) {
            this.label = option.label;
            this.color = option.color ? option.color : this.color;
          } else if (option.min < this.value && this.value <= option.max) {
            this.label = option.label;
            this.color = option.color ? option.color : this.color;
          }
        }
      });
    }
  }
}
