import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Group, PageRequestOptions, PageResult } from "@vp/core/models";
import { Observable } from "rxjs";
import { GROUPS_API_BASE_URL } from "../groups-data-access.module";

@Injectable({
  providedIn: "root"
})
export class GroupsApiService {
  constructor(
    @Inject(GROUPS_API_BASE_URL) private _apiBaseUrl: string,
    private _http: HttpClient
  ) {}

  createGroup = (group: Group): Observable<Group> => {
    return this._http.post<Group>(`${this._apiBaseUrl}`, group);
  };

  getAllGroups = (): Observable<Group[]> => {
    return this._http.get<Group[]>(`${this._apiBaseUrl}`);
  };

  getGroupsPaged = (options: PageRequestOptions): Observable<PageResult<Group>> => {
    if (options.take === 0) options.take = 10;
    let apiURL = `${this._apiBaseUrl}/?take=${options.take}&skip=${options.skip}`;
    if (options.sort) {
      apiURL = apiURL + `&sort=${options.sort}`;
    }
    if (options.sort && options.sortdirection !== null) {
      apiURL = apiURL + `&sortdirection=${options.sortdirection}`;
    }
    if (options.search) {
      apiURL = apiURL + `&search=${options.search}`;
    }
    if (options.filters) {
      apiURL = apiURL + options.filters.map(filter => `&filter=${filter}`);
    }
    return this._http.get<PageResult<Group>>(apiURL);
  };

  getGroupById = (groupId: string): Observable<Group> => {
    return this._http.get<Group>(`${this._apiBaseUrl}/${groupId}`);
  };

  updateGroup = (group: Group): Observable<Group> => {
    return this._http.put<Group>(`${this._apiBaseUrl}/${group.groupId}`, group);
  };

  deleteGroup = (groupId: string): Observable<boolean> => {
    return this._http.delete<boolean>(`${this._apiBaseUrl}/${groupId}`);
  };
}
