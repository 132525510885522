import { Injectable } from "@angular/core";
import { TagEvent } from "@vp/core/models";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Injectable({
  providedIn: "root"
})
export class TagManagerService {
  private _tagEvent = this.eventAggregator.on<TagEvent>(TagEvent);

  constructor(
    private readonly eventAggregator: EventAggregator,
    readonly gtmService: GoogleTagManagerService
  ) {
    this._tagEvent.subscribe((tag: TagEvent) => {
      gtmService.pushTag(tag.args);
    });
  }
}
