import { Component, Input } from "@angular/core";

@Component({
  selector: "vp-dialog-button",
  styleUrls: ["dialog-button.component.scss"],
  template: `
    <button mat-raised-button [color]="color" [disabled]="disabled">
      <ng-content></ng-content>
    </button>
  `
})
export class ButtonComponent {
  @Input() color = "primary";
  @Input() disabled = false;
}
