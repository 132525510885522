import { Injectable } from "@angular/core";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UiStoreService } from "@vp/shared/store/ui";
import { defer, of } from "rxjs";
import { NavigationItem } from "../../components/side-nav";

@Injectable({
  providedIn: "root"
})
export class AppNavService {
  constructor(
    public _PermissionsConstService: PermissionsConstService,
    private uiStoreService: UiStoreService
  ) {}

  menuItems = defer(() => this.resolve());

  private resolve() {
    const navItems: NavigationItem[] = [
      {
        key: "dashboard",
        displayName: this.uiStoreService.ui.dashboardNav,
        friendlyName: "dashboard",
        routerLink: "/dashboard/overview",
        extraRouterLinks: ["/dashboard"],
        enabled: true,
        valid: true,
        active: false,
        order: 1,
        permissions: [this._PermissionsConstService.Global.DashboardPage.View]
      },
      {
        key: "cases",
        displayName: this.uiStoreService.ui.caseManagerNav,
        friendlyName: "cases",
        routerLink: "/cases",
        enabled: true,
        valid: true,
        active: false,
        order: 2,
        permissions: [this._PermissionsConstService.Global.CaseSearchPage.View]
      },
      {
        key: "patients",
        displayName: this.uiStoreService.ui.patientManagerNav,
        friendlyName: "patients",
        routerLink: "/patients",
        enabled: true,
        valid: true,
        active: false,
        order: 3,
        permissions: [this._PermissionsConstService.Global.PatientManagerPage.View]
      },
      {
        key: "administration",
        displayName: this.uiStoreService.ui.administrationNav,
        friendlyName: "administration",
        routerLink: "/administration/group-list",
        extraRouterLinks: ["/administration"],
        enabled: true,
        valid: true,
        active: false,
        order: 4,
        permissions: [this._PermissionsConstService.Global.AdministrationPage.View]
      },
      {
        key: "about",
        displayName: this.uiStoreService.ui.aboutNav,
        friendlyName: "about",
        routerLink: "/about",
        extraRouterLinks: ["/dashboard"],
        enabled: true,
        valid: true,
        active: false,
        order: 5,
        permissions: [this._PermissionsConstService.Global.AboutPage.View]
      }
    ];
    return of(navItems);
  }
}
