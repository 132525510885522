import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  AssignUserEvent,
  CallLightActivatedEvent,
  MovementInRoomDetected,
  User
} from "@vp/core/models";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { NotificationService } from "@vp/shared/notification";
import { filterNull } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { Subject } from "rxjs";
import { filter, map, takeUntil, tap } from "rxjs/operators";
import { IS_IVY_API } from "./app-initializer.factory";
import { AppNavService } from "./services/app-nav/app-nav.service";
import { Logger } from "./services/logging/logging.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  isLoggedIn = false;
  isAdmin = false;

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly appStoreService: AppStoreService,
    private readonly eventAggregrator: EventAggregator,
    private readonly logger: Logger,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    public appNavService: AppNavService
  ) {}

  ngOnInit() {
    this.logger.logEvent("Breakout app initalized");
    this.isIframe = window !== window.parent && !window.opener;

    if (this.isIvyApi) {
      this.router.navigate(["/wizard"]);
    }

    this.eventAggregrator
      .on<AssignUserEvent>(AssignUserEvent)
      .pipe(
        filter((eventName: AssignUserEvent) => eventName.sender === "newAssignUser"),
        takeUntil(this.destroyed$)
      )
      .subscribe((message: AssignUserEvent) => {
        this.notificationService.successMessage(message.args, "New User Assignment", {
          disableTimeOut: true,
          closeButton: true,
          tapToDismiss: false,
          enableHtml: true
        });
      });

    this.eventAggregrator
      .on<CallLightActivatedEvent>(CallLightActivatedEvent)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((callLight: CallLightActivatedEvent) => {
        this.notificationService.successMessage(
          `The call light is activated for patient: ${callLight.args.caseDisplayName}`,
          "Call Light Activated",
          {
            disableTimeOut: true,
            closeButton: true,
            tapToDismiss: false,
            enableHtml: true
          }
        );
      });

    //If moving forward with Angel Eyes, we should move the logic to determine the toast's CSS somewhere else and include icons
    this.eventAggregrator
      .on<MovementInRoomDetected>(MovementInRoomDetected)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((message: MovementInRoomDetected) => {
        var toastColor = "ngx-toastr toast-success default";
        if (message.args.status.notificationColor === 0) {
          toastColor = "ngx-toastr toast-success green";
        } else if (message.args.status.notificationColor === 1) {
          toastColor = "ngx-toastr toast-success yellow";
        } else if (message.args.status.notificationColor === 2) {
          toastColor = "ngx-toastr toast-success red";
        }
        this.notificationService.successMessage(message.args.message, "Movement Detected", {
          disableTimeOut: true,
          closeButton: true,
          tapToDismiss: false,
          enableHtml: true,
          toastClass: toastColor
        });
      });

    this.appStoreService.stateChanged
      .pipe(
        map(state => state.user),
        filterNull(),
        tap((user: User | null) => {
          this.isLoggedIn = !!user;
          this.isAdmin = false;
          // TODO put into app store service
          if (user && user.roles) {
            for (const role of user.roles) {
              if (role.roleId === user.selectedRoleId && role.friendlyId === "admin") {
                this.isAdmin = true;
              }
            }
          }
        })
      )
      .subscribe({
        next: () => this.logger.logTrace("AppComponent.ngOnInit.appStoreService.stateChanged"),
        error: error =>
          this.logger.logException(error, "AppComponent.ngOnInit.appStoreService.stateChanged"),
        complete: () => {}
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
