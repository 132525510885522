import { Operation } from "rfc6902";

export namespace OrganizationActions {
  export class LoadOrganization {
    public static readonly type = "[Organization] Load Organization";
    constructor() {}
  }

  export class PatchOrganization {
    public static readonly type = "[Organization] Patch Organization";
    constructor(public opertaions: Operation[]) {}
  }

  export class LoadTemplates {
    public static readonly type = "[Organization] Load Templates";
    constructor() {}
  }
}
