import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterToolbarComponent } from "./filter-toolbar.component";
import { MaterialModule } from "@vp/shared/material";

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [FilterToolbarComponent],
  exports: [FilterToolbarComponent]
})
export class FilterToolbarModule {}

export interface FilterToolbarItem {
  value: string;
  label: string;
}
