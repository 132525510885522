import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { UserAdministrationService } from "@vp/administration/user/data-access/user-administration-service";
import { AssignedRolePerDepartment } from "@vp/administration/user/feature";
import { Organization, UserRole } from "@vp/core/models";
import { OrganizationState } from "@vp/data-access/organization";
import { IAssignmentService } from "@vp/shared/assignments/models";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay, withLatestFrom } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class RolesAssignmentService implements IAssignmentService {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  assignableTags$: Observable<AssignedRolePerDepartment[]>;

  private assigned$: Observable<AssignedRolePerDepartment[]>;
  private all$: Observable<AssignedRolePerDepartment[]>;

  constructor(
    private readonly appStore: AppStoreService,
    private userAdministrationService: UserAdministrationService
  ) {
    this.all$ = this.appStore.userStream.pipe(
      filterNullMap(),
      map(user => mapToVm(user.roles))
    );

    this.assigned$ = this.userAdministrationService.user$.pipe(map(user => mapToVm(user.roles)));

    this.assignableTags$ = combineLatest([this.assigned$, this.all$]).pipe(
      map(([assigned, all]: [AssignedRolePerDepartment[], AssignedRolePerDepartment[]]) =>
        all.filter(
          e =>
            // Exclude already assigned items from assignable list
            assigned.findIndex(r => r.departmentId === e.departmentId && r.roleId === e.roleId) < 0
        )
      ),
      withLatestFrom(this.organization$.pipe(shareReplay())),
      map(([assignable, org]) =>
        assignable.map(item => {
          const roleMatch = org.roles.find(r => r.roleId === item.roleId);
          const deptMatch = org.departments.find(d => d.departmentId === item.departmentId);
          // Stuff the searchable content together in a string that is not displayed
          // but inspected by the filterTerm pipe for the purposes of searching.
          item.search = `
            ${deptMatch?.displayName ?? ""}
            ${deptMatch?.description ?? ""}
            ${roleMatch?.displayName ?? ""}`;
          return item;
        })
      )
    );
  }
}

const mapToVm = (userRoles: UserRole[]) => {
  return userRoles.reduce((a: AssignedRolePerDepartment[], role: UserRole) => {
    let w = role.departments.map(d => {
      return {
        departmentId: d.departmentId,
        roleId: role.roleId
      } as AssignedRolePerDepartment;
    });
    a = a.concat(w);
    return a;
  }, []);
};
