import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { NotificationService } from "@vp/shared/notification";
import { NgxFileDropEntry } from "ngx-file-drop";
import { combineLatest, iif, of, ReplaySubject, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Logger } from "../../../services/logging/logging.service";
import { UploadCompletedEvent } from "./events/upload-completed-event";
import { UploadFileCompleteEvent } from "./events/upload-file-complete-event";
import { UploadFileFailEvent } from "./events/upload-file-fail-event";
import { FileUploadService } from "./file-upload.service";
import { IFileUpload } from "./models/file-upload.interface";
import { IFile } from "./models/file.interface";
import { UploadState, UploadStoreService } from "./store/upload-store.service";

export interface FileUploadData {
  accept: string;
  uploadURL: string;
  directory: boolean;
  createURL: string;
  enableDrapAndDrop: boolean;
  sendUploadURLTemplate: (uploadId: string) => string;
  queueUploadURLTemplate: (uploadId: string) => string;
}
export const uiSource = {
  file: {
    title: "Upload File",
    select: "Select File",
    drop: "Drop file here"
  },
  directory: {
    title: "Upload Folder",
    select: "Select Folder",
    drop: "Drop folder here"
  }
};

export interface FileState extends Partial<File> {
  icon: string;
  path: string;
}
@Component({
  selector: "vp-file-select",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  providers: [UploadStoreService, FileUploadService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  get fileFormGroup(): FormGroup {
    return this.formGroup.get(this.fileGroupName) as FormGroup;
  }
  constructor(
    private readonly logger: Logger,
    private readonly notificationService: NotificationService,
    private readonly fileUploadService: FileUploadService,
    public readonly dialogRef: MatDialogRef<FileUploadComponent>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly eventAggregator: EventAggregator,
    @Inject(MAT_DIALOG_DATA) public data: FileUploadData,
    public readonly uploadStoreService: UploadStoreService
  ) {
    this.accept = data.accept.split(",");
    this.uploadURL = data.uploadURL;
    this.directory = data.directory || false;
    this.ui = this.directory ? uiSource.directory : uiSource.file;
    this.enableDragAndDrop = data.enableDrapAndDrop ?? true;
  }

  readonly fileGroupName: string = "file";
  readonly fileNameFieldName: string = "name";
  readonly fileTypeFieldName: string = "type";
  readonly fileEncodedFieldName: string = "file";
  readonly metadataGroupName: string = "metadata";
  readonly fileDescriptionFieldName: string = "fileDescription";

  dimensions = new ReplaySubject<IImageDimensions>(1);

  src: string | ArrayBuffer | null = null;
  accept: string[];
  uploadURL: string;
  directory: boolean;
  enableDragAndDrop: boolean;
  ui: Record<string, string>;
  fileType: "images" | "documents" | "video" | null = null;

  selectedFile: File | undefined;
  formGroup = this.getForm();
  fileDropEntries: NgxFileDropEntry[] = [];
  fileEntries: FileState[] = [];
  files = this.uploadStoreService.state$.pipe(map(state => state.files));
  fileCount = this.uploadStoreService.state$.pipe(map((state: UploadState) => state.fileCount));
  showFiles = false;

  private readonly fileTypeMap: Map<string, string> = new Map([
    [".jpeg", "images"],
    [".jpg", "images"],
    [".png", "images"],
    [".pdf", "documents"],
    [".doc", "documents"],
    [".docx", "documents"],
    [".txt", "documents"],
    [".zip", "documents"],
    [".mp4", "video"],
    [".mov", "video"]
  ]);

  uploadDisabled = combineLatest([
    iif(
      () => {
        return this.directory === false && !!this.fileFormGroup;
      },
      this.fileFormGroup.valueChanges.pipe(startWith(null)),
      of(null)
    ),
    this.uploadStoreService.state$
  ]).pipe(
    map(([valueChanges, uploadStoreState]) => {
      if (valueChanges)
        if (valueChanges.file) {
          return false;
        }
      if (uploadStoreState.isDisabled) {
        return true;
      }
      if (uploadStoreState.files.length === 0) {
        return true;
      }
      return false;
    })
  );

  ngOnInit(): void {
    this.enableForm();

    // Listen for upload file event from file-upload.service to mark complete
    this.subscriptions.add(
      this.eventAggregator.on<UploadFileCompleteEvent>(UploadFileCompleteEvent).subscribe({
        next: uploadedFile => {
          const found = this.fileEntries.find(
            file =>
              file.name === (uploadedFile.args as IFileUpload).file.fieldName &&
              file.path === (uploadedFile.args as IFileUpload).file.webkitRelativePath
          );
          if (found) {
            found.icon = "check_circle_outline";
            this.changeDetectorRef.detectChanges();
          }
        }
      })
    );

    // Listen for upload complete event from file-upload.service to auto close the dialog
    this.subscriptions.add(
      this.eventAggregator.on<UploadCompletedEvent>(UploadCompletedEvent).subscribe({
        next: uploadedFile => this.dialogRef.close(uploadedFile.args)
      })
    );

    // Listen for upload file event from file-upload.service to mark complete
    this.subscriptions.add(
      this.eventAggregator.on<UploadFileFailEvent>(UploadFileFailEvent).subscribe({
        next: uploadedFile => {
          const found = this.fileEntries.find(
            file => file.name === (uploadedFile.args as IFileUpload).file.fieldName
          );
          if (found) {
            found.icon = "error_outline";
            this.changeDetectorRef.detectChanges();
          }
        },
        error: () => this.notificationService.errorMessage("File Upload Failed.")
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getAcceptedFiles(): string {
    return this.accept.join(",");
  }

  fileSelectedHandler(event: Event | File): void {
    if (event instanceof Event) {
      // select button
      const target = event.target as HTMLInputElement;
      const fileList = target.files as FileList;
      this.selectedFile = fileList[0];
    } else if (event instanceof File) {
      // drag and drop event
      this.selectedFile = event;
    }

    if (!this.selectedFile) {
      this.fileFormGroup.patchValue({
        [this.fileNameFieldName]: null,
        [this.fileTypeFieldName]: null,
        [this.fileEncodedFieldName]: null
      });
      return;
    }

    switch (this.mapFileType(this.selectedFile.name)) {
      case "images":
        this.fileType = "images";
        break;
      case "documents":
        this.fileType = "documents";
        break;
      case "video":
        this.fileType = "video";
        break;
      default:
        this.fileType = null;
        this.selectedFile = undefined;
        return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (this.fileType === "images") {
        this.setImage(reader);
      }

      // Remove the prefix to the encoded string i.e. 'data:application/pdf;base64,'
      const result = reader.result as string;
      const prefixLength = result.indexOf(",") + 1;
      const encodedString = result.substring(prefixLength);

      if (!encodedString) {
        this.notificationService.warningMessage("Unable to upload empty files", "Warning");
        return;
      }

      if (this.fileType === "video") {
        this.src = `data:video/mp4;base64,${encodedString}`;
        this.changeDetectorRef.detectChanges();
      }

      this.fileFormGroup.patchValue({
        [this.fileNameFieldName]: this.selectedFile?.name,
        [this.fileTypeFieldName]: this.selectedFile?.type,
        [this.fileEncodedFieldName]: encodedString
      });
    };
    reader.readAsDataURL(this.selectedFile);
  }

  dropped(files: NgxFileDropEntry[]) {
    this.fileUploadService
      .prepareFolderWithValidation(files)
      .then((acceptableFiles: FileState[]) => {
        if (acceptableFiles && acceptableFiles.length > 0) {
          this.fileDropEntries = files;
          this.fileEntries = acceptableFiles;
          this.uploadStoreService.patchState(this.fileEntries, "files");
          this.uploadStoreService.patchState(this.fileEntries.length, "fileCount");
        } else {
          this.notificationService.warningMessage(
            "Folder must contain a valid DICOMDIR file or DCM files"
          );
        }
      })
      .catch(() => console.error);
  }

  @HostListener("window:dragover", ["$event"]) @HostListener("window:drag", ["$event"]) dragOver(
    e: DragEvent
  ) {
    if (e) {
      e.preventDefault();
      if (e.dataTransfer) {
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
    }
  }

  private readonly setImage = (reader: FileReader): void => {
    const image = new Image();
    image.onload = () => {
      this.dimensions.next({
        width: image.width,
        height: image.height
      } as IImageDimensions);
    };
    image.src = reader.result as string;
    this.src = image.src;
  };

  uploadHandler = (): void => {
    if (this.directory) {
      this.fileUploadService.getAllFilesFromSystem(this.fileDropEntries).then(allFiles => {
        this.uploadStoreService.patchState(true, "isDisabled");
        this.subscriptions.add(
          this.fileUploadService
            .uploadFolderWithProgress(
              allFiles,
              this.data.createURL,
              this.data.queueUploadURLTemplate
            )
            .subscribe({
              error: error => this.logger.logException(error)
            })
        );
      });
    } else {
      this.disableForm();

      const file = this.formGroup.value.file;
      if (!file) {
        return;
      }

      const fileSelection = this.buildFileSelection(file);
      this.fileUploadService.uploadWithProgress(fileSelection, this.uploadURL);
    }
  };

  private readonly buildFileSelection = (values: any) => {
    return {
      file: {
        fileName: values.name,
        fileType: this.mapFileType(values.name),
        fileDescription: values.fileDescription,
        fileTextBase64: values.file,
        fieldName: this.fileType
      } as IFile,
      metadata: null
    } as IFileUpload;
  };

  private readonly mapFileType = (fileName: string) => {
    const extension = fileName.substr(fileName.lastIndexOf("."), fileName.length - 1).toLowerCase();
    if (this.fileTypeMap.has(extension) && this.accept.includes(extension)) {
      return this.fileTypeMap.get(extension);
    }
    this.notificationService.warningMessage("File is not of a supported type.", "Warning");
    return undefined;
  };

  private getForm(): FormGroup {
    // formGroup will hold the selected file
    return new FormGroup({
      // fileGroupName holds the controls
      [this.fileGroupName]: new FormGroup({
        [this.fileNameFieldName]: new FormControl(null),
        [this.fileDescriptionFieldName]: new FormControl(null, [Validators.maxLength(30)]),
        [this.fileTypeFieldName]: new FormControl(null),
        [this.fileEncodedFieldName]: new FormControl(null, [Validators.required])
      })
    });
  }

  private disableForm(): void {
    this.formGroup.disable();
    this.uploadStoreService.patchState(true, "isDisabled");
    this.changeDetectorRef.detectChanges();
  }

  private enableForm(): void {
    this.formGroup.enable();
    const fileNameControl = this.fileFormGroup.get(this.fileNameFieldName)!;
    fileNameControl.disable();
    this.uploadStoreService.patchState(false, "isDisabled");
    this.changeDetectorRef.detectChanges();
  }
}

export interface IImageDimensions {
  height: number;
  width: number;
  fileName: string;
}
