import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { first } from "rxjs/operators";
import { DialogComponent } from "./generic-dialog.component";
import { DialogData, DialogOptions } from "./generic-dialog.module";
import { DialogService } from "./generic-dialog.service";

@Injectable({
  providedIn: "root"
})
export class DialogFactoryService<T = undefined> {
  constructor(private dialog: MatDialog) {}

  open(
    dialogData: DialogData<T>,
    options: DialogOptions = { width: "75vw", disableClose: true }
  ): DialogService<T> {
    const dialogRef = this.dialog.open<DialogComponent<T>, DialogData<T>>(DialogComponent, {
      ...this.fetchOptions(options),
      data: dialogData
    });

    dialogRef.afterClosed().pipe(first());

    return new DialogService(dialogRef);
  }

  private fetchOptions({
    width,
    disableClose
  }: DialogOptions): Pick<MatDialogConfig<DialogData<T>>, "width" | "disableClose"> {
    return {
      width: `${width}`,
      disableClose
    };
  }
}
