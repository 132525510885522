import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatListOption } from "@angular/material/list";
import { map } from "rxjs/operators";
import { AssignData } from "../assign-data.interface";
import { AssignOptions } from "../assign-options.interface";
import { DynAssignComponent } from "../components/assign.component";

@Injectable({
  providedIn: "root"
})
export class DynAssignService {
  constructor(public matDialog: MatDialog) {}

  open = (items: Record<string, any>[], options?: AssignOptions) => {
    const dialogRef = this.matDialog.open(DynAssignComponent, {
      disableClose: true,
      autoFocus: false,
      closeOnNavigation: true,
      data: {
        items: items,
        options: options
      } as AssignData,
      panelClass: "mobile-responsive"
    });

    return dialogRef.afterClosed().pipe(
      map((selectedOptions: undefined | MatListOption[]) => {
        const values = selectedOptions?.map((item: MatListOption) => item.value);
        return options?.multiple ? values : values?.pop();
      })
    );
  };
}
