import { Tag } from "@vp/core/models";

export namespace TagsActions {
  export class CreateTag {
    public static readonly type = "[Tags] Create Tag";
    constructor(public tag: Tag) {}
  }

  export class LoadTags {
    public static readonly type = "[Tags] Load Tags";
    constructor() {}
  }

  export class SetSelectedTagId {
    public static readonly type = "[Tags] Set Selected TagId";
    constructor(public tagId: string) {}
  }

  export class UpdateTag {
    public static readonly type = "[Tags] Update Tag";
    constructor(public tag: Tag) {}
  }

  export class DeleteTag {
    public static readonly type = "[Tags] Delete Tag";
    constructor(public tagId: string) {}
  }
}
