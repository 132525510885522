import { BreakpointObserver } from "@angular/cdk/layout";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource as ClientArrayTableDataSource } from "@angular/material/table";
import { FieldType } from "@ngx-formly/core";
import { SharedConfirmationService } from "@vp-libs/shared/confirmation";
import { CaseFile, FileDescriptor, ICaseDocument } from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UiStoreService } from "@vp/shared/store/ui";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, EMPTY, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { ExtractPdfDialogComponent } from "../../case-dashboard/documents/extract-pdf-dialog/extract-pdf-dialog.component";
import { FileApiService } from "../../services/api/file-api.service";
import { CaseContextService } from "../../services/case-context/case-context.service";
import { RenameDialogComponent } from "../rename-dialog/rename-dialog.component";

@Component({
  selector: "vp-document-grid",
  templateUrl: "./document-grid.component.html",
  styleUrls: ["./document-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentGridComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {
  formControl!: FormControl;
  @Input() actionsEnabled = true;

  private destroyed$ = new Subject<void>();
  documents$ = new BehaviorSubject<ICaseDocument[]>([]);
  fileDescriptor: FileDescriptor | null = null;
  caseId: string | undefined;
  clientArrayTableDataSource = new ClientArrayTableDataSource<ICaseDocument>();
  private mobileColumns = ["fileName", "fileDescription"];
  private desktopColumns = ["fileIcon", "fileName", "fileDescription"];
  displayedColumns = this.breakpointObserver.observe(["(max-width: 959px)"]).pipe(
    map(state => {
      if (state.matches) {
        return this.mobileColumns;
      } else {
        return this.desktopColumns;
      }
    })
  );

  get hasDocuments() {
    return this.documents$.getValue()?.length > 0;
  }

  get documentCount() {
    return this.documents$.pipe(
      map((documents: ICaseDocument[]) => {
        return documents.length;
      })
    );
  }

  getDisplayName(document: ICaseDocument) {
    return document.displayName ?? document.fileName;
  }

  get hideExtract(): boolean {
    /*
      Not realy happy with this approach, the setting is applied via the formlyConfig on #/properties/documents,
      but because "document-grid" does not exist on the schema and the documents definition is in the app
      it cant be selectively applied (i.e. wizard or documents type) at run time using the layout provider.
      We need to think of a better way to do this.
    */
    return this.field.parent?.templateOptions?.hideExtract || false;
  }

  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly caseContextService: CaseContextService,
    private readonly confirmationDialog: SharedConfirmationService,
    private readonly dialog: MatDialog,
    private readonly fileApiService: FileApiService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly notificationService: NotificationService,
    public readonly permConst: PermissionsConstService,
    public readonly uiStoreService: UiStoreService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  ngOnInit(): void {
    combineLatest([
      this.caseContextService.Context,
      this.caseContextService.contextDocuments,
      this.caseContextService.contextFirstOrDefaultDocumentsDescriptor
    ])
      .pipe(filterNullMap(), takeUntil(this.destroyed$))
      .subscribe({
        next: ([caseData, documents, descriptor]) => {
          this.caseId = caseData?.caseId;
          this.fileDescriptor = descriptor;
          this.clientArrayTableDataSource.data = documents;
        },
        error: () => this.notificationService.errorMessage("Failed to load documents.")
      });

    this.ngxPermissionsService
      .hasPermission([this.permConst.Case.Document.Write])
      .then(hasPermissions => {
        if (this.actionsEnabled && hasPermissions) {
          this.mobileColumns.push("actions");
          this.desktopColumns.push("actions");
        }
      });
  }

  ngAfterViewInit(): void {
    // MatTableDataSource automatically handles sorting
    this.clientArrayTableDataSource.sort = this.sort;

    // MatTableDataSource automatically handles paging
    // TODO Add pager and uncomment if needed
    //this.dataSource.paginator = this.paginator;
  }

  deleteDocument(document: CaseFile) {
    if (!document) return;
    if (!this.caseId) {
      throw new Error("context is missing");
    }

    this.confirmationDialog
      .open(`You are about to permanently delete ${document.fileName}`, "Delete")
      .afterConfirmed()
      .pipe(
        switchMap(() => {
          return this.fileApiService.deleteFile(
            this.caseId as string,
            "documents",
            document.fileName
          );
        }),
        switchMap(() => {
          return this.caseContextService.contextRefresh();
        })
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Successfully deleted document."),
        error: () => this.notificationService.errorMessage("Failed to delete document.")
      });
  }

  extractPdfPages = (document: ICaseDocument) => {
    if (!this.caseId) {
      throw new Error("context is missing");
    }
    const dialogRef = this.dialog.open(ExtractPdfDialogComponent, {
      height: "85vh",
      minWidth: "80vw",
      data: { pdf: document, caseId: this.caseId }
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(pdfFile => {
          if (pdfFile) {
            return this.caseContextService.contextRefresh();
          }
          return EMPTY;
        })
      )
      .subscribe();
  };

  renameFile(document: CaseFile) {
    const dialogRef = this.dialog.open(RenameDialogComponent, {
      width: "400px",
      data: document.displayName ?? document.fileName
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(dialogResult => {
          if (dialogResult && dialogResult !== document.displayName) {
            document.displayName = dialogResult;
            return this.fileApiService.renameFile(this.caseId as string, "documents", document);
          }
          return EMPTY;
        }),
        switchMap(updated => {
          if (updated) {
            this.notificationService.successMessage("File renamed successfully");
            return this.caseContextService.contextRefresh();
          }
          this.notificationService.warningMessage("Unable to rename");
          return EMPTY;
        })
      )
      .subscribe({
        error: (error: any) => this.notificationService.errorMessage(error)
      });
  }

  getSecureLink = (document: ICaseDocument) => {
    this.fileApiService
      .getReadOnlySecurelink(this.caseId, document.fileName, false)
      .subscribe(secureLink => window.open(secureLink, "_blank"));
  };

  trackByFn = (
    _index: number,
    item: { fileName: string; url: string; fileDescription: string }
  ): string => item.url;
}
