import { Injectable } from "@angular/core";
import { ObservableStore } from "@codewithdan/observable-store";
import { AppState, DisplayTag, ui } from "@vp/core/models";

export const TAG_EXISTS = "true";

@Injectable({
  providedIn: "root"
})
export class UiStoreService extends ObservableStore<AppState> {
  constructor() {
    super({
      stateSliceSelector: state => {
        return {
          ui: state.ui as typeof ui
        };
      },
      trackStateHistory: false
    });
  }

  get ui(): typeof ui {
    return this.getStateProperty("ui");
  }

  //TO DO:  REVIEW THIS CODE
  customizeUiFromDisplayTags(displayTags: DisplayTag[]) {
    const state = this.getState();
    let uiExists: Partial<typeof ui> = {};
    displayTags.forEach(displayTag => {
      if (uiExists[displayTag.tag] !== TAG_EXISTS) {
        state.ui[displayTag.tag] = displayTag.display;
        uiExists[displayTag.tag] = TAG_EXISTS;
      } else {
        console.warn(`Organization display tag already exists with key: ${displayTag.tag}`);
      }
    });
    this.setState(state, "SET_DISPLAY_TAGS");
  }
}
