/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { filterNullMap } from "./filterNull";

export function filterRecord(key: string) {
  return (source$: Observable<Record<string, unknown>>) => {
    return source$.pipe(
      filterNullMap(),
      filter((record: Record<string, unknown>) => {
        return Object.keys(record).includes(key);
      })
    );
  };
}
