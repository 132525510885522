import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CaseContextService } from "../../../services/case-context/case-context.service";

@Component({
  selector: "vp-security-popup",
  templateUrl: "./security-popup.component.html",
  styleUrls: ["./security-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityPopupComponent implements OnInit {
  permissionsArray$: Observable<string[]> | undefined = undefined;

  constructor(
    public ngxPermissionsService: NgxPermissionsService,
    public caseContextService: CaseContextService
  ) {}

  ngOnInit(): void {
    // Map permissions object to an interable array for display
    this.permissionsArray$ = this.ngxPermissionsService.permissions$.pipe(
      map(permissionsObject =>
        Object.keys(permissionsObject).map(perm => permissionsObject[perm].name)
      )
    );
  }
}
