import { Directive, Input } from "@angular/core";
import { ShowHideDirective } from "@angular/flex-layout";

const selector = `[fxShow.min1455]`;

@Directive({ selector })
export class CustomShowHideDirective extends ShowHideDirective {
  @Input()
  protected inputs = ["fxShow.min1455"];
}
