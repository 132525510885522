import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "vp-document-preview",
  templateUrl: "./document-preview.component.html",
  styleUrls: ["./document-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentPreviewComponent {
  @Input() fileName!: string;
  get extension(): string {
    return this.fileName?.split(".").pop() as string;
  }

  constructor() {}
}
