import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FieldType } from "@ngx-formly/core";
import {
  CaseData,
  CaseType,
  DicomImagesUpload,
  DicomImagesUploadStatus,
  DicomStudy,
  FileDescriptor
} from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap, pollUntil } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UiStoreService } from "@vp/shared/store/ui";
import { EMPTY, Observable, of } from "rxjs";
import { map, startWith, switchMap, take, tap } from "rxjs/operators";
import { JsonUiStoreService } from "../../case-dashboard/json-ui.store";
import { FileApiService } from "../../services/api/file-api.service";
import { CaseContextService } from "../../services/case-context/case-context.service";
import { FileUploadComponent, FileUploadData } from "../../shared/components/file-upload";

const CONTEXT_REFRESH_INTERVAL_MS = 15000;
const CONTEXT_REFRESH_INTERVAL_MAX = 1000;

@Component({
  selector: "vp-dicoms-actions",
  templateUrl: "./dicoms-actions.component.html",
  styleUrls: ["./dicoms-actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [JsonUiStoreService]
})
export class DicomsActionsComponent extends FieldType implements OnInit {
  descriptor: FileDescriptor | null = null;

  caseId = this.caseContextService.Context.pipe(
    filterNullMap(),
    map((caseData: CaseData) => caseData.caseId)
  );

  dicomStudiesCount = this.caseContextService.contextDicomStudies.pipe(
    map((dicomStudies: DicomStudy[]) => dicomStudies?.length)
  );

  uploadsPendingCount = this.caseContextService.Context.pipe(
    map((caseData: CaseData | null) => {
      const count = caseData?.dicomImagesUploads.filter((value: DicomImagesUpload) => {
        const lastUpdated = new Date(value.lastUpdatedDateTime);
        const oneHourAgo = new Date();
        oneHourAgo.setHours(oneHourAgo.getHours() - 1);
        return (
          (value.uploadStatus === DicomImagesUploadStatus.Pending ||
            value.uploadStatus === DicomImagesUploadStatus.Queued ||
            value.uploadStatus === DicomImagesUploadStatus.Sent) &&
          lastUpdated > oneHourAgo
        );
      })?.length;
      return count ?? 0;
    })
  );

  uploadMessages = this.jsonUiStoreService.errorMessages;

  // Polling the context for changes
  // SignalR could replace when fully implemented
  intervalRefresh = of(1).pipe(
    switchMap(() => this.caseContextService.contextRefresh()),
    switchMap(() => this.uploadsPendingCount.pipe(startWith(1))),
    pollUntil(0, CONTEXT_REFRESH_INTERVAL_MS, CONTEXT_REFRESH_INTERVAL_MAX, count => count === 0)
  );

  get imagePermissions() {
    return this.permConst.Case.Image;
  }

  constructor(
    private readonly caseContextService: CaseContextService,
    private readonly dialog: MatDialog,
    private readonly fileApiService: FileApiService,
    private readonly notificationService: NotificationService,
    public readonly uiStoreService: UiStoreService,
    public readonly jsonUiStoreService: JsonUiStoreService,
    private permConst: PermissionsConstService
  ) {
    super();
  }

  ngOnInit(): void {
    this.caseContextService.caseType$
      .pipe(filterNullMap(), take(1))
      .subscribe((caseType: CaseType) => {
        const images = caseType.images.imageDescriptors[0] ?? new FileDescriptor();
        this.descriptor = new FileDescriptor(images.fileTypes, images.required, images.recommended);
      });
  }

  onAddDocumentClicked(caseId: string | null | undefined) {
    this.showFileUploadDialog(caseId)
      .pipe(
        tap((uploadPerformed: boolean | undefined) => {
          if (uploadPerformed) {
            this.caseContextService.contextRefresh();
            this.notificationService.successMessage("Upload successfull");
          }
        }),
        switchMap((uploadPerformed: boolean | undefined) => {
          if (uploadPerformed) {
            return this.intervalRefresh;
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (caseData: CaseData | null) => {
          if (caseData) {
            this.notificationService.successMessage("Processing complete");
          }
        },
        error: () => {
          this.notificationService.errorMessage("Failed to upload and/or process files");
        }
      });
  }

  showFileUploadDialog(caseId: string | null | undefined): Observable<boolean | undefined> {
    if (!caseId) {
      throw new Error("context is missing");
    }
    this.jsonUiStoreService.setErrorMessages(null);
    const directory = true;
    const accept = "";
    const queueUploadURLTemplate = this.fileApiService.queueUploadURL(caseId);
    const createURL = this.fileApiService.createUploadURL(caseId);
    const enableDrapAndDrop = false;
    const dialogRef = this.dialog.open<FileUploadComponent, FileUploadData, boolean>(
      FileUploadComponent,
      {
        width: "60vw",
        data: {
          accept,
          directory,
          createURL,
          queueUploadURLTemplate,
          enableDrapAndDrop
        } as FileUploadData,
        panelClass: "mobile-responsive"
      }
    );
    return dialogRef.afterClosed();
  }
}
