import { EventBase } from "@vp/shared/event-aggregator";
import { Guid } from "guid-typescript";
import { PaymentTransaction } from "../payment.service";

export class TransactionCancelledEvent extends EventBase<Guid> {
  constructor(private transactionId: Guid) {
    super();
    this.args = this.transactionId;
  }
}

export class TransactionCreatedEvent extends EventBase<PaymentTransaction> {
  constructor(private transactionId: PaymentTransaction) {
    super();
    this.args = this.transactionId;
  }
}
