import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { Organization } from "@vp/core/models";
import { OrganizationState } from "@vp/data-access/organization";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Pipe({
  name: "groupType"
})
export class GroupTypePipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  transform(groupTypeId: string): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      map((org: Organization) => {
        const groupType = org.groupTypes.find(gt => gt.groupTypeId === groupTypeId);
        return `${groupType?.displayName ?? "[group type deleted]"}`;
      })
    );
  }
}
