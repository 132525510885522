import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { ActiveToast, IndividualConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";

export interface AlertItem {
  type: "toast-success" | "toast-info" | "toast-warning" | "toast-error";
  message: string;
  title: string;
}

export const INLINE_TOASTR_CONFIG: Partial<IndividualConfig> = {
  positionClass: "inline",
  tapToDismiss: false,
  closeButton: false,
  disableTimeOut: true
};

@Component({
  selector: "vp-alert-container",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ToastrService]
})
export class AlertComponent implements OnInit, OnChanges {
  @Input() alerts: null | string | string[] | AlertItem[] = null;
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer:
    | ToastContainerDirective
    | undefined;

  constructor(private toastrService: ToastrService) {}
  private activeToasts: ActiveToast<any>[] = [];

  ngOnInit(): void {
    this.toastrService.overlayContainer = this.toastContainer;
    this.toastrService.toastrConfig.preventDuplicates = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.toastrService.clear();
    if (changes.alerts) {
      const value = changes.alerts.currentValue;
      if (typeof value === "string") {
        const alert = value;
        if (alert) {
          this.activeToasts.push(
            this.toastrService.show(alert, undefined, INLINE_TOASTR_CONFIG, "toast-info")
          );
        }
      } else if (value && value.length) {
        const alerts = value as string[] | AlertItem[];
        alerts.forEach((alert: string | AlertItem) => {
          if (typeof alert === "string") {
            if (alert) {
              this.activeToasts.push(
                this.toastrService.show(alert, undefined, INLINE_TOASTR_CONFIG, "toast-info")
              );
            }
          } else {
            const alertObject = alert as AlertItem;
            if (alertObject.message) {
              this.activeToasts.push(
                this.toastrService.show(
                  alertObject.message,
                  alertObject.title,
                  INLINE_TOASTR_CONFIG,
                  alertObject.type || "toast-info"
                )
              );
            }
          }
        });
      }
    }
  }
}
