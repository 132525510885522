import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AdvancedCommandRequest } from "../models/advanced-command-request";
import { AdvancedCommand } from "../models/advanced-command";
import { DeviceMicrophoneDTO } from "../models/device-microphone-dto";
import { DeviceCameraDTO } from "../models/device-camera-dto";
import { MatDialog } from "@angular/material/dialog";
import { DeviceSpeakerDTO } from "../models/device-speaker-dto";
import { AdvancedCommandsComponent } from "../components/advanced-commands.component";

export const ADVANCED_COMMANDS_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");
@Injectable({
  providedIn: "root"
})
export class AdvancedCommandsService {
  constructor(
    @Inject(ADVANCED_COMMANDS_API_BASE_URL) private _apiBaseUrl: string,
    private _http: HttpClient,
    private readonly dialog: MatDialog
  ) {}

  public getDeviceCommands = (): Observable<AdvancedCommand[]> => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/device-commands`;
    return this._http.get<AdvancedCommand[]>(apiURL);
  };

  public sendDeviceCommand = (command: AdvancedCommandRequest): Observable<void> => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/send-device-command`;

    return this._http.post<void>(apiURL, command);
  };

  public getDeviceCameras = (deviceUserId: string) => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/device-cameras/${deviceUserId}`;

    return this._http.get<DeviceCameraDTO[]>(apiURL);
  };

  public switchCamera = (deviceUserId: string, camera: DeviceCameraDTO) => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/switch-camera/${deviceUserId}`;

    return this._http.post<boolean>(apiURL, camera);
  };

  public getDeviceMicrophones = (deviceUserId: string) => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/device-microphones/${deviceUserId}`;

    return this._http.get<DeviceMicrophoneDTO[]>(apiURL);
  };

  public switchMic = (deviceUserId: string, mic: DeviceMicrophoneDTO) => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/switch-mic/${deviceUserId}`;

    return this._http.post<boolean>(apiURL, mic);
  };

  public getDeviceSpeakers = (deviceUserId: string) => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/device-speakers/${deviceUserId}`;

    return this._http.get<DeviceSpeakerDTO[]>(apiURL);
  };

  public switchSpeaker = (deviceUserId: string, speaker: DeviceSpeakerDTO) => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/switch-speaker/${deviceUserId}`;

    return this._http.post<boolean>(apiURL, speaker);
  };

  public showAdvancedCommands(caseId: string, zoomCallActive: boolean): void {
    this.dialog.open(AdvancedCommandsComponent, {
      data: {
        caseId: caseId,
        zoomCallActive: zoomCallActive
      }
    });
  }
}
