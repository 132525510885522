import { CommonModule } from "@angular/common";
import { Inject, ModuleWithProviders, NgModule, Optional } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { FormlyFieldCheckbox } from "@ngx-formly/material/checkbox";
import { FormlyMatDatepickerModule } from "@ngx-formly/material/datepicker";
import { NgxLinkifyjsModule } from "ngx-linkifyjs";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { ExpansionPanelWrapperComponent } from "./expansion-panel-wrapper/expansion-panel-wrapper.component";
import { FormInputComponent } from "./form-input/form-input.component";
import { FormlyArrayTypeComponent } from "./formly-array-type/formly-array-type.component";
import { FormlyFlexInputWrapperComponent } from "./formly-flex-input-wrapper/formly-flex-input-wrapper.component";
import { FormlyFlexLayoutTypeComponent } from "./formly-flex-layout-type/formly-flex-layout-type.component";
import { FormlyFlexLayoutWrapperComponent } from "./formly-flex-layout-wrapper/formly-flex-layout-wrapper.component";
import { FormlyIntlPhoneInputTypeComponent } from "./formly-intl-phone-input-type/formly-intl-phone-input-type.component";
import { FormlyMaskedTypeComponent } from "./formly-masked-type/formly-masked-type.component";
import { FormlyMixedSchemaTypeComponent } from "./formly-mixed-schema-type/formly-mixed-schema-type.component";
import { FormlyMultiSchemaTypeComponent } from "./formly-multi-schema-type/formly-multi-schema-type.component";
import { FormlyNullTypeComponent } from "./formly-null-type/formly-null-type.component";
import { FormlyObjectTypeComponent } from "./formly-object-type/formly-object-type.component";
import { DisableAngularEditorDirective } from "./formly-rich-text-type/disable-angular-editor.directive";
import { FormlyRichTextTypeComponent } from "./formly-rich-text-type/formly-rich-text-type.component";
import { FormlyTableLayoutTypeComponent } from "./formly-table-layout-type/formly-table-layout-type.component";
import { FormlyTabsLayoutTypeComponent } from "./formly-tabs-layout-type/formly-tabs-layout-type.component";
import { FormlyTextareaTypeComponent } from "./formly-textarea-type/formly-textarea-type.component";
import {
  constValidationMessage,
  exclusiveMaximumValidationMessage,
  exclusiveMinimumValidationMessage,
  maxItemsValidationMessage,
  maxlengthValidationMessage,
  maxValidationMessage,
  minItemsValidationMessage,
  minlengthValidationMessage,
  minValidationMessage,
  multipleOfValidationMessage
} from "./formly-validation-message.config";
import { JsonInputAccessorDirective } from "./json-edit-type/json-accessor.directive";
import { JsonEditTypeComponent } from "./json-edit-type/json-edit-type.component";
import { ModalWrapperComponent } from "./modal-wrapper/modal-wrapper.component";
import { UiSchemaLayoutProvider } from "./providers/ui-schema-layout-provider.service";
import { StringArrayEditTypeComponent } from "./string-array-edit-type/string-array-edit-type.component";
import { UiSchemaCollection } from "./ui-schema-collection";
import { defaultConfig, UiSchemaConfigService, UI_SCHEMA_CONFIG } from "./ui-schema-config.service";
import { MemoizePipe } from "./utilities/memoize.pipe";
import { TagsDataAccessModule } from "@vp/data-access/tags";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormlyLanguageSelectorTypeComponent } from "./formly-language-selector-type/formly-language-selector-type.component";

export const DATE_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY"
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [
    FormInputComponent,
    FormlyArrayTypeComponent,
    FormlyFlexLayoutTypeComponent,
    FormlyMultiSchemaTypeComponent,
    FormlyNullTypeComponent,
    FormlyObjectTypeComponent,
    FormlyTableLayoutTypeComponent,
    FormlyTabsLayoutTypeComponent,
    FormlyRichTextTypeComponent,
    FormlyMaskedTypeComponent,
    FormlyTextareaTypeComponent,
    MemoizePipe,
    StringArrayEditTypeComponent,
    JsonEditTypeComponent,
    ModalWrapperComponent,
    DisableAngularEditorDirective,
    JsonInputAccessorDirective,
    FormlyMixedSchemaTypeComponent,
    FormlyFlexLayoutWrapperComponent,
    FormlyFlexInputWrapperComponent,
    ExpansionPanelWrapperComponent,
    FormlyIntlPhoneInputTypeComponent,
    FormlyLanguageSelectorTypeComponent
  ],
  exports: [DisableAngularEditorDirective, FormlyMixedSchemaTypeComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCardModule,
    MatTabsModule,
    MatTableModule,
    MatChipsModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    FormlyMaterialModule,
    NgxLinkifyjsModule,
    MatNativeDateModule,
    AngularEditorModule,
    FormlyMatDatepickerModule,
    NgxMatIntlTelInputModule,
    TagsDataAccessModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot({
      validation: true
    }),
    FormlyModule.forRoot({
      extras: {
        lazyRender: true,
        resetFieldOnHide: false
      },
      validationMessages: [
        { name: "required", message: "This field is required" },
        { name: "null", message: "should be null" },
        { name: "minlength", message: minlengthValidationMessage },
        { name: "maxlength", message: maxlengthValidationMessage },
        { name: "min", message: minValidationMessage },
        { name: "max", message: maxValidationMessage },
        { name: "multipleOf", message: multipleOfValidationMessage },
        {
          name: "exclusiveMinimum",
          message: exclusiveMinimumValidationMessage
        },
        {
          name: "exclusiveMaximum",
          message: exclusiveMaximumValidationMessage
        },
        { name: "minItems", message: minItemsValidationMessage },
        { name: "maxItems", message: maxItemsValidationMessage },
        { name: "uniqueItems", message: "should not have duplicate items" },
        { name: "const", message: constValidationMessage }
      ],
      types: [
        {
          name: "flex-layout",
          component: FormlyFlexLayoutTypeComponent
        },
        {
          name: "input",
          defaultOptions: { modelOptions: { updateOn: "change" } },
          component: FormInputComponent
        },
        {
          name: "checkbox",
          component: FormlyFieldCheckbox,
          wrappers: ["flex-input"]
        },
        { name: "string", extends: "input", wrappers: ["form-field"] },
        { name: "boolean", extends: "checkbox" },
        { name: "datepicker", wrappers: ["form-field"] },
        { name: "enum", extends: "select" },
        { name: "multischema", component: FormlyMultiSchemaTypeComponent },
        { name: "rich-text", component: FormlyRichTextTypeComponent },
        { name: "array", component: FormlyArrayTypeComponent },
        { name: "tabs", component: FormlyTabsLayoutTypeComponent },
        { name: "table", component: FormlyTableLayoutTypeComponent },
        {
          name: "mixed-schema",
          component: FormlyMixedSchemaTypeComponent
        },
        {
          name: "masked",
          wrappers: ["form-field"],
          extends: "input",
          component: FormlyMaskedTypeComponent
        },
        {
          name: "number",
          extends: "input",
          wrappers: ["form-field"],
          defaultOptions: {
            templateOptions: {
              type: "number"
            }
          }
        },
        {
          name: "integer",
          extends: "input",
          wrappers: ["form-field"],
          defaultOptions: {
            templateOptions: {
              type: "number"
            }
          }
        },
        {
          name: "null",
          component: FormlyNullTypeComponent,
          wrappers: ["form-field"]
        },
        {
          name: "object",
          component: FormlyObjectTypeComponent
        },
        {
          name: "grid",
          component: FormlyFlexLayoutTypeComponent,
          defaultOptions: {
            templateOptions: {
              columns: 2
            }
          }
        },
        {
          name: "textarea",
          component: FormlyTextareaTypeComponent,

          defaultOptions: {
            templateOptions: {
              rows: 4,
              cols: 100,
              autosizeMinRows: 3,
              autosizeMaxRows: 5,
              autosize: true
            }
          }
        },
        {
          name: "intl-phone",
          component: FormlyIntlPhoneInputTypeComponent,
          wrappers: ["form-field"]
        },
        {
          name: "language-selector",
          component: FormlyLanguageSelectorTypeComponent
        }
      ],
      wrappers: [
        {
          name: "modal-wrapper",
          component: ModalWrapperComponent
        },
        {
          name: "flex-wrapper",
          component: FormlyFlexLayoutWrapperComponent
        },
        {
          name: "flex-input",
          component: FormlyFlexInputWrapperComponent
        },
        {
          name: "expansion-panel",
          component: ExpansionPanelWrapperComponent
        }
      ]
    })
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-US" },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class SchemaConfigurationModule {
  constructor(
    configService: UiSchemaConfigService,
    @Optional() @Inject(UI_SCHEMA_CONFIG) config: UiSchemaCollection[] = []
  ) {
    if (!config) {
      return;
    }
    config.forEach(config => configService.addConfig(config));
  }

  static forRoot(config: UiSchemaCollection): ModuleWithProviders<SchemaConfigurationModule> {
    return {
      ngModule: SchemaConfigurationModule,
      providers: [
        {
          provide: UI_SCHEMA_CONFIG,
          multi: true,
          useFactory: defaultConfig,
          deps: [UiSchemaConfigService]
        },
        { provide: UI_SCHEMA_CONFIG, multi: true, useValue: config },
        UiSchemaConfigService,
        UiSchemaLayoutProvider
      ]
    };
  }

  static forChild(config: UiSchemaCollection): ModuleWithProviders<SchemaConfigurationModule> {
    return {
      ngModule: SchemaConfigurationModule,
      providers: [
        {
          provide: UI_SCHEMA_CONFIG,
          multi: true,
          useFactory: defaultConfig,
          deps: [UiSchemaConfigService]
        },
        { provide: UI_SCHEMA_CONFIG, multi: true, useValue: config },
        UiSchemaLayoutProvider
      ]
    };
  }
}
