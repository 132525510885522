export enum SignalrMethods {
  newCaseChat = "newCaseChat",
  newAssignUser = "newAssignUser",
  updatedCase = "updatedCase",
  movementInRoomDetected = "movementInRoomDetected",
  callLightActivated = "callLightActivated",
  callLightDeactivated = "callLightDeactivated",
  interactiveSessionEnded = "interactiveSessionEnded",
  interactiveSessionStarted = "interactiveSessionStarted",
  deviceConnectionChanged = "deviceConnectionChanged",
  caseDataChanged = "caseDataChanged",
  roomAssignedToDevice = "roomAssignedToDevice",
  roomUnassignedFromDevice = "roomUnassignedFromDevice",
  messageToPatient = "messageToPatient",
  caseStatusChanged = "caseStatusChanged"
}
