import { Inject, Injectable, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router, Event, Scroll } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

/**
 * some parts of this code taken from
 * https://github.com/angular/angular/blob/master/packages/common/src/viewport_scroller.ts
 */

@Injectable({
  providedIn: "root"
})
export class DrawerContentScrollService implements OnDestroy {
  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {}

  private subscriptions = new Subscription();

  trackAnchors() {
    this.subscriptions.add(
      this.router.events.pipe(filter((event: Event) => event instanceof Scroll)).subscribe({
        next: (event: Event) => {
          setTimeout(() => {
            event = event as Scroll;
            if (event.anchor) {
              const anchorEl = this.findAnchorFromDocument(this.document, event.anchor);
              if (anchorEl) {
                anchorEl.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }
            }
          }, 300);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  findAnchorFromDocument(document: Document, target: string): HTMLElement | null {
    const documentResult = document.getElementById(target) || document.getElementsByName(target)[0];

    if (documentResult) {
      return documentResult;
    }

    // `getElementById` and `getElementsByName` won't pierce through the shadow DOM so we
    // have to traverse the DOM manually and do the lookup through the shadow roots.
    if (
      typeof document.createTreeWalker === "function" &&
      document.body &&
      ((document.body as any).createShadowRoot || document.body.attachShadow)
    ) {
      const treeWalker = document.createTreeWalker(document.body, NodeFilter.SHOW_ELEMENT);
      let currentNode = treeWalker.currentNode as HTMLElement | null;

      while (currentNode) {
        const shadowRoot = currentNode.shadowRoot;

        if (shadowRoot) {
          // Note that `ShadowRoot` doesn't support `getElementsByName`
          // so we have to fall back to `querySelector`.
          // const result =
          //     shadowRoot.getElementById(target) || shadowRoot.querySelector(`[name="${target}"]`);
          const result = shadowRoot.querySelector(`[name="${target}"]`) as HTMLElement;
          if (result) {
            return result;
          }
        }

        currentNode = treeWalker.nextNode() as HTMLElement | null;
      }
    }

    return null;
  }
}
