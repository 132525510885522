import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/material/form-field";
import { Select, Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { TagsActions, TagsState } from "@vp/data-access/tags";
import { Observable } from "rxjs";
import { Tag } from "@vp/core/models";

@Component({
  selector: "lib-formly-language-selector-type",
  templateUrl: "./formly-language-selector-type.component.html",
  styleUrls: ["./formly-language-selector-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyLanguageSelectorTypeComponent extends FieldType {
  formControl!: FormControl;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;
  languageTags$ = this.tags$.pipe(
    map(tags => tags.filter(t => t.tagTypeFriendlyId === "language"))
  );

  constructor(store: Store) {
    super();
    store.dispatch(new TagsActions.LoadTags());
  }
}
