import { Injectable, OnDestroy } from "@angular/core";
import { Select } from "@ngxs/store";
import { AssignedRolePerDepartment } from "@vp/administration/user/feature";
import { Group, GroupLite, Organization, Tag, User, UserRole } from "@vp/core/models";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { filterNullMap } from "@vp/shared/operators";
import { UserApiService } from "@vp/shared/store/user";
import { deeperCopy, mergeDeep } from "@vp/shared/utilities";
import { createPatch, Operation } from "rfc6902";
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from "rxjs";
import { concatMap, first, map, mergeMap, switchMap, take, takeUntil, tap } from "rxjs/operators";

@Injectable()
export class UserAdministrationService implements OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  hasPendingOperations$: Observable<boolean>;
  user$: Observable<User>;
  userAssignableTags$: Observable<Tag[]>;

  private _destroyed$ = new Subject();
  private _operations$ = new BehaviorSubject<UserOperations | null>(null);
  private _user$ = new BehaviorSubject<User | null>(null);
  private _userAssignedTagsIds$: Observable<string[]>;

  constructor(private userServiceApi: UserApiService) {
    this.user$ = this._user$.pipe(filterNullMap());
    this.hasPendingOperations$ = this._operations$.pipe(
      map((o: UserOperations | null) => {
        return o?.operations ? o.operations.length > 0 : false;
      }),
      takeUntil(this._destroyed$)
    );

    this._userAssignedTagsIds$ = this._user$.pipe(
      filterNullMap(),
      map(u => u?.tags ?? []),
      takeUntil(this._destroyed$)
    );

    this.userAssignableTags$ = combineLatest([this._userAssignedTagsIds$, this.tags$]).pipe(
      map(([assigned, all]: [string[], Tag[]]) =>
        all.filter(tag => assigned.indexOf(tag.tagId) < 0)
      ),
      takeUntil(this._destroyed$)
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public loadUser = (load: User, active: boolean = true): Observable<User> => {
    return of(deeperCopy(load)).pipe(
      tap((user: User) => {
        user.active = active;
        this._user$.next(user);
      })
    );
  };

  public setExistingUser = (userId: string): Observable<User> => {
    return this.userServiceApi.getUser(userId).pipe(
      filterNullMap(),
      tap((user: User) => {
        this._user$.next(user);
      })
    );
  };

  public reset = () => {
    this._user$.next(null);
  };

  updateUser = (updated: User) => {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        const merged = mergeDeep({ ...user }, updated, "merge", true);
        return combineLatest([
          this.stagedOrActive(user),
          of(merged).pipe(tap(user => this._user$.next(user)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      first()
    );
  };

  /** @deprecated use addDepartmentRoles/deleteDepartmentRole  */
  updateRoles(roles: UserRole[]) {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        return combineLatest([
          this.stagedOrActive(user),
          of({
            ...user,
            roles: roles
          } as User).pipe(tap(user => this._user$.next(user)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      first()
    );
  }

  deleteGroup$(groupId: string) {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        let modified = deleteGroup(user, groupId);
        return combineLatest([
          this.stagedOrActive(user),
          of(modified).pipe(tap(u => this._user$.next(u)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      take(1)
    );
  }

  addGroups$(groups: Group[]) {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        let modified = addGroups(
          user,
          groups.map(e => e.groupId)
        );
        return combineLatest([
          this.stagedOrActive(user),
          of(modified).pipe(tap(u => this._user$.next(u)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      take(1)
    );
  }

  /** @deprecated use addGroups/deleteGroups  */
  updateGroups$(groups: GroupLite[]) {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        return combineLatest([
          this.stagedOrActive(user),
          of({
            ...user,
            groups: groups
          } as User).pipe(tap(user => this._user$.next(user)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      take(1)
    );
  }

  deleteDepartmentRole$(roleId: string, departmentId: string) {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        const modified = deleteDepartmentByRole(user, roleId, departmentId);
        return combineLatest([
          this.stagedOrActive(user),
          of(modified).pipe(tap(u => this._user$.next(u)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      take(1)
    );
  }

  addDepartmentRoles$(add: AssignedRolePerDepartment[]) {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        let modified = this.addDepartmentRoles(user, add);
        return combineLatest([
          this.stagedOrActive(user),
          of(modified).pipe(tap(u => this._user$.next(u)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      take(1)
    );
  }

  deleteTag(deleteTagId: string) {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        return combineLatest([
          this.stagedOrActive(user),
          of({
            ...user,
            assignedTags: user.assignedTags.filter(tagId => tagId !== deleteTagId)
          } as User).pipe(tap(user => this._user$.next(user)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      take(1)
    );
  }

  assignTags(tagIds: string[]) {
    return this.user$.pipe(
      first(),
      switchMap((user: User) => {
        return combineLatest([
          this.stagedOrActive(user),
          of({
            ...user,
            assignedTags: user.assignedTags?.concat(tagIds) ?? tagIds
          } as User).pipe(tap(user => this._user$.next(user)))
        ]);
      }),
      concatMap(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this._operations$.next(userOperations);
      }),
      first()
    );
  }

  invite() {
    return this._user$.pipe(
      filterNullMap(),
      switchMap(user => {
        if (!user) {
          return throwError("User Context was not set.");
        }
        if (!user.roles || user.roles.length === 0) {
          return throwError("Please assign one or more department & role(s) to User");
        }
        return of(user);
      }),
      switchMap(user => this.userServiceApi.inviteUser(user)),
      take(1)
    );
  }

  patch(): Observable<User | null> {
    return this._operations$.pipe(
      filterNullMap(),
      first(),
      switchMap((userOperations: UserOperations) =>
        this.userServiceApi
          .patch(userOperations.userId, userOperations.operations)
          .pipe(mergeMap(user => this.userServiceApi.getUser(user.userId).pipe(filterNullMap())))
      ),
      tap(user => {
        if (user !== null) {
          this._user$.next(user);
        }
      }),
      first()
    );
  }

  // return the currently loaded user if it is inactive (offline editing) otherwise
  // return the active user from the api.
  stagedOrActive = (user: User) => {
    if (user.active === false) {
      return of(user);
    }
    return this.userServiceApi.getUser(user.userId);
  };

  private getOperations(original: User, updated: User) {
    return combineLatest([of(original), of(updated)]).pipe(
      map(([original, changed]: [User, User]) => {
        return {
          userId: changed.userId,
          operations: createPatch(original, changed)
        } as UserOperations;
      })
    );
  }

  private addDepartmentRole = (userRef: User, roleId: string, departmentId: string) => {
    this.organization$
      .pipe(
        map(org => {
          return {
            roles: org.roles,
            departments: org.departments
          };
        }),
        first()
      )
      .subscribe(refs => {
        let role = userRef.roles.find(r => r.roleId === roleId);
        let deptFriendlyId = refs.departments.find(
          d => d.departmentId === departmentId
        )?.friendlyId;
        if (role) {
          role.departments.push({
            departmentId: departmentId,
            friendlyId: deptFriendlyId
          });
        } else {
          let roleFriendlyId = refs.roles.find(r => r.roleId === roleId)?.friendlyId;
          userRef.roles.push({
            roleId: roleId,
            friendlyId: roleFriendlyId,
            departments: [
              {
                departmentId: departmentId,
                friendlyId: deptFriendlyId
              }
            ]
          });
        }
      });
  };

  private addDepartmentRoles = (user: User, data: AssignedRolePerDepartment[]) => {
    var copy = deeperCopy(user);
    data.forEach(d => {
      this.addDepartmentRole(copy, d.roleId, d.departmentId);
    });
    return copy;
  };
}

export interface UserOperations {
  userId: string;
  operations: Operation[];
}

const deleteDepartmentByRole = (user: User, roleId: string, departmentId: string) => {
  let copy: User = deeperCopy(user);
  let role = copy.roles.find(r => r.roleId === roleId);
  if (role) {
    role.departments = role?.departments.filter(d => d.departmentId !== departmentId);
    if (role.departments.length === 0) {
      copy.roles = copy.roles.filter(r => r.roleId !== roleId);
    }
  }
  return copy;
};

const addGroups = (user: User, data: string[]) => {
  var copy: User = deeperCopy(user);
  copy.groups = copy.groups.concat(
    data
      .filter(i => copy.groups.findIndex(g => g.groupId === i) < 0)
      .map(id => {
        return {
          groupId: id
        } as GroupLite;
      })
  );
  return copy;
};

const deleteGroup = (user: User, groupId: string) => {
  var copy: User = deeperCopy(user);
  copy.groups = copy.groups.filter(g => g.groupId !== groupId);
  return copy;
};
