import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Store } from "@ngxs/store";
import { GroupsDataAccessModule } from "@vp/data-access/groups";
import { TagsActions, TagsDataAccessModule } from "@vp/data-access/tags";
import { CaseStatusPipe } from "./case-status.pipe";
import { DaysBetweenTwoDatesPipe } from "./days-between-two-dates.pipe";
import { DepartmentPipe } from "./department.pipe";
import { GroupDisplayPipe } from "./group-display.pipe";
import { GroupTypePipe } from "./group-type.pipe";
import { RolePipe } from "./role.pipe";
import { TagDisplayPipe } from "./tag-display.pipe";
import { TagSortPipe } from "./tag-sort.pipe";
import { TagTypePipe } from "./tag-type.pipe";
import { UserNamePipe } from "./user-name.pipe";

@NgModule({
  imports: [CommonModule, GroupsDataAccessModule, TagsDataAccessModule],
  exports: [
    CaseStatusPipe,
    DaysBetweenTwoDatesPipe,
    DepartmentPipe,
    GroupDisplayPipe,
    GroupTypePipe,
    RolePipe,
    TagDisplayPipe,
    TagSortPipe,
    TagTypePipe,
    UserNamePipe
  ],
  declarations: [
    CaseStatusPipe,
    DaysBetweenTwoDatesPipe,
    DepartmentPipe,
    GroupDisplayPipe,
    GroupTypePipe,
    RolePipe,
    TagDisplayPipe,
    TagSortPipe,
    TagTypePipe,
    UserNamePipe
  ]
})
export class ContextDisplayModule {
  constructor(store: Store) {
    store.dispatch(new TagsActions.LoadTags());
  }
}
