import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Sort } from "@angular/material/sort";
import { UserAdministrationService } from "@vp/administration/user/data-access/user-administration-service";
import { AssignedRolePerDepartment } from "@vp/administration/user/feature";
import { UserRole } from "@vp/core/models";
import {
  AssignmentModalOptions,
  ASSIGNMENT_MODAL_OPTIONS,
  Column
} from "@vp/shared/assignments/models";
import {
  DialogData,
  DialogFactoryService,
  DialogService
} from "@vp/shared/components/generic-dialog";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { DepartmentPipe, RolePipe } from "@vp/shared/pipes/context-display";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-user-assigned-roles",
  templateUrl: "./user-assigned-roles.component.html",
  styleUrls: ["./user-assigned-roles.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ASSIGNMENT_MODAL_OPTIONS,
      useValue: {
        columns: [
          {
            field: "departmentId",
            header: "Department",
            pipe: DepartmentPipe
          },
          {
            field: "roleId",
            header: "Role",
            pipe: RolePipe
          }
        ],
        title: "Assign Roles",
        config: {
          disableClose: true,
          autoFocus: false,
          closeOnNavigation: true,
          width: "60vw"
        }
      }
    }
  ]
})
export class UserAssignedRolesComponent implements OnInit, OnDestroy {
  @ViewChild("modalTemplate", { static: false }) modalTemplate!: TemplateRef<any>;
  dialog!: DialogService;
  assigned$: Observable<AssignedRolePerDepartment[]>;
  displayedColumns$: Observable<Column[]>;

  private readonly destroyed$ = new Subject();
  private readonly displayedColumnsSubject$ = new BehaviorSubject<Column[]>([]);
  private readonly sortSubject$ = new BehaviorSubject<Sort>({
    direction: "asc",
    active: "department"
  });

  constructor(
    @Inject(ASSIGNMENT_MODAL_OPTIONS) public options: AssignmentModalOptions,
    public permConst: PermissionsConstService,
    private userAdministrationService: UserAdministrationService,
    private dialogFactoryService: DialogFactoryService,
    private ngxPermissionsService: NgxPermissionsService
  ) {
    this.assigned$ = this.userAdministrationService.user$.pipe(
      filterNullMap(),
      map(user => user.roles),
      map((userRoles: UserRole[]) => {
        return userRoles.reduce((a: AssignedRolePerDepartment[], role: UserRole) => {
          let w = role.departments.map(d => {
            return {
              departmentId: d.departmentId,
              roleId: role.roleId
            } as AssignedRolePerDepartment;
          });
          a = a.concat(w);
          return a;
        }, []);
      }),
      takeUntil(this.destroyed$)
    );

    this.displayedColumns$ = this.displayedColumnsSubject$.pipe(
      switchMap(columns => {
        return combineLatest([
          of([...columns]),
          this.ngxPermissionsService.hasPermission([
            this.permConst.Admin.User.RoleAssignment.Delete
          ])
        ]);
      }),
      map(([columns, hasWritePermissions]: [Column[], boolean]) => {
        if (hasWritePermissions) {
          columns.push({
            field: "actions",
            header: "Delete"
          } as Column);
        }
        return columns;
      })
    );
  }
  ngOnInit(): void {
    this.displayedColumnsSubject$.next(this.options.columns);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  assignHandler = () => {
    this.openDialog({
      title: "Assign Tags",
      template: this.modalTemplate
    });
  };

  unassignHandler = (e: AssignedRolePerDepartment) => {
    this.userAdministrationService.deleteDepartmentRole$(e.roleId, e.departmentId).subscribe();
  };

  sortHandler = (sort: Sort): void => {
    this.sortSubject$.next(sort);
  };

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, {
      width: "70vw",
      disableClose: false
    });
    this.dialog.closed$.subscribe();
  }
}
