import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SharedConfirmationService } from "@vp-libs/shared/confirmation";
import { CaseData, CaseFile } from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { BehaviorSubject, combineLatest, iif, of, Subject } from "rxjs";
import { mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { ImageZoomDialogComponent } from "../../components/image-zoom-dialog/image-zoom-dialog.component";
import { FileApiService } from "../../services/api/file-api.service";
import { CaseContextService } from "../../services/case-context/case-context.service";

@Component({
  selector: "vp-images-grid",
  templateUrl: "./images-grid.component.html",
  styleUrls: ["./images-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesGridComponent implements OnInit {
  caseId: string | null = null;
  images$ = new BehaviorSubject<CaseFile[]>([]);
  @Input() readonly = false;

  get hasImages() {
    return this.images$.getValue()?.length > 0;
  }

  private destroyed$ = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly caseContextService: CaseContextService,
    private readonly notificationService: NotificationService,
    private readonly confirmationDialog: SharedConfirmationService,
    private readonly imageApiService: FileApiService
  ) {}

  ngOnInit(): void {
    this.caseContextService.Context.pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) => {
        const imageFileNames: string[] = caseData?.images.imageList
          .sort((a, b) => a.fileName.localeCompare(b.fileName))
          .map(image => image.fileName);

        return combineLatest([
          of(caseData),
          iif(
            () => imageFileNames.length > 0,
            this.imageApiService.getReadOnlyImageSecurelinks(caseData.caseId, imageFileNames),
            of([])
          )
        ]);
      })
    ).subscribe({
      next: ([caseData, secureLinks]: [CaseData, string[]]) => {
        this.caseId = caseData.caseId;

        if (caseData?.images.imageList.length > 0) {
          const caseImages = caseData?.images.imageList.sort((a, b) =>
            a.fileName.localeCompare(b.fileName)
          );

          const isVideo = (fileName: string): boolean => {
            const extention = fileName.substring(fileName.indexOf(".") + 1).toLowerCase();
            return ["mp4", "mov"].includes(extention);
          };

          caseImages?.forEach((x, index) => {
            x.isVideo = isVideo(x.fileName);
            x.url = secureLinks[index];
          });

          this.images$.next(caseImages);
        }
      },
      error: () => this.notificationService.errorMessage("Failed to retrieve images")
    });
  }

  imageClicked(image: CaseFile) {
    this.showImageZoomDialog(image).subscribe({
      next: result => {
        if (result) {
          this.notificationService.successMessage("Image was uploaded.");
        }
      }
    });
  }

  deleteImage(image: CaseFile) {
    if (!image) return;
    if (!this.caseId) {
      throw new Error("context is missing");
    }

    this.confirmationDialog
      .open(`You are about to permanently delete ${image.fileName}`, "Delete")
      .afterConfirmed()
      .pipe(
        switchMap(() => {
          return this.imageApiService.deleteFile(this.caseId as string, "images", image.fileName);
        }),
        switchMap(() => {
          return this.caseContextService.contextRefresh();
        })
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Successfully deleted"),
        error: () => this.notificationService.errorMessage("Failed to delete")
      });
  }

  trackByFn = (
    _index: number,
    item: { fileName: string; url: string; fileDescription: string }
  ): string => item.url;

  private showImageZoomDialog(image: CaseFile) {
    const fullScreenDialog = {
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%"
    };

    return this.dialog
      .open(ImageZoomDialogComponent, {
        ...fullScreenDialog,
        data: image
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$));
  }
}
