import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FieldType } from "@ngx-formly/core";
import { CaseData, CaseType, FileDescriptor } from "@vp/core/models";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UiStoreService } from "@vp/shared/store/ui";
import { EMPTY, Observable, Subject } from "rxjs";
import { map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { JsonUiStoreService } from "../../case-dashboard/json-ui.store";
import { FileApiService } from "../../services/api/file-api.service";
import { CaseContextService } from "../../services/case-context/case-context.service";
import { FileUploadComponent, FileUploadData } from "../../shared/components/file-upload";

@Component({
  selector: "vp-images-actions",
  templateUrl: "./images-actions.component.html",
  styleUrls: ["./images-actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [JsonUiStoreService]
})
export class ImagesActionsComponent extends FieldType implements OnInit {
  private destroyed$ = new Subject<void>();

  caseId: string | null = null;
  descriptor: FileDescriptor | null = null;

  get imageCount(): Observable<number> {
    return this.caseContextService.Context.pipe(
      filterNullMap(),
      map((caseData: CaseData) => {
        return caseData.images.imageList.filter(i => {
          return this.filterVideos(i.fileName, false);
        }).length;
      })
    );
  }

  get Permissions() {
    return this.permConst.Case.Image;
  }

  constructor(
    private readonly caseContextService: CaseContextService,
    private readonly dialog: MatDialog,
    private readonly _imageApiService: FileApiService,
    private readonly notificationService: NotificationService,
    public readonly uiStoreService: UiStoreService,
    public readonly jsonUiStoreService: JsonUiStoreService,
    private permConst: PermissionsConstService
  ) {
    super();
  }

  ngOnInit(): void {
    this.caseContextService.Context.pipe(
      filterNullMap(),
      take(1),
      tap((caseData: CaseData) => {
        this.caseId = caseData.caseId;
      }),
      switchMap(() => {
        return this.caseContextService.caseType$.pipe(filterNullMap());
      }),
      map((caseType: CaseType) => {
        const first = caseType.images.imageDescriptors[0] ?? new FileDescriptor();
        return new FileDescriptor(first.fileTypes, first.required, first.recommended);
      }),
      takeUntil(this.destroyed$)
    ).subscribe({
      next: (descriptor: FileDescriptor) => {
        this.descriptor = descriptor;
      },
      error: () => this.notificationService.errorMessage("Failed to retrieve file descriptors")
    });
  }

  onAddImageClicked($event: MouseEvent) {
    $event.preventDefault();
    this.showFileUploadDialog()
      .pipe(
        switchMap((uploadPerformed: boolean | undefined) => {
          if (uploadPerformed) {
            return this.caseContextService.contextRefresh();
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (caseData: CaseData | null) => {
          if (caseData) {
            this.notificationService.successMessage("Image was uploaded.");
          }
        }
      });
  }

  showFileUploadDialog(): Observable<boolean | undefined> {
    if (!this.caseId) {
      throw new Error("context is missing");
    }

    const accept = this.descriptor?.fileTypesAsList();
    const uploadURL = this._imageApiService.uploadFileURL(this.caseId);
    const dialogRef = this.dialog.open<FileUploadComponent, FileUploadData, boolean>(
      FileUploadComponent,
      {
        width: "60vw",
        data: { accept, uploadURL } as FileUploadData,
        panelClass: "mobile-responsive"
      }
    );
    return dialogRef.afterClosed().pipe(takeUntil(this.destroyed$));
  }

  private filterVideos(fileName: string, isVideo: boolean): boolean {
    const extension = fileName.substring(fileName.indexOf(".") + 1);
    return isVideo ? extension === "mp4" : extension !== "mp4";
  }
}

export interface ImageCounts {
  images: number;
  videos: number;
}
