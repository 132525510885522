import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "lib-json-edit-type",
  templateUrl: "./json-edit-type.component.html",
  styleUrls: ["./json-edit-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonEditTypeComponent extends FieldType {}
