import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  TrackByFunction
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { FilterToolbarItem } from "@vp/shared/components/filter-toolbar";
import { AssignData } from "../assign-data.interface";
import { AssignColumnDef, AssignFilterBar } from "../assign-options.interface";

/**
 * Star is used because `undefined` does not work as a MatSelect value
 */
export const FILTER_ALL_KEY = "*";
const SEARCH_TERM_KEY = "searchTerm";

/* eslint-disable @angular-eslint/template/no-call-expression */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "dyn-assign",
  templateUrl: "./assign.component.html",
  styleUrls: ["./assign.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynAssignComponent implements OnInit {
  @Input() items: Record<string, any>[] = [];
  @Input() properties: string[] = [];
  @Input() columnDefs: AssignColumnDef[] = [];
  @Input() filterBar: AssignFilterBar[] = [];
  @Input() excludeProperties: string[] = [];
  @Input() multiple = false;
  @Input() title = "Assign";
  @Input() button = "Assign";
  @Input() noRecords = "No records available";
  @Input() selectedKey: string | null = null;

  columns: AssignColumnDef[] = [];
  keyProperty: string | undefined;

  filters: Record<string, unknown> | undefined = undefined;
  searchTerm: string | undefined = undefined;
  filterBarItems: FilterToolbarItem[] = [];
  showHeaders = false; // set to true when columnDefs are present

  selectedItems: Record<string, any>[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public assignData: AssignData) {}

  ngOnInit(): void {
    this.items = this.assignData.items ?? this.items;
    const allProperies = this.reflectProperties(this.items);
    this.properties = this.assignData.options?.properties ?? allProperies;
    this.columnDefs = this.assignData.options?.columnDefs ?? [];
    this.columns = this.setColumnsFromProperties(this.properties, this.columnDefs);
    this.filterBar = this.assignData.options?.filterBar ?? [];
    this.keyProperty = this.assignData.options?.keyProperty;
    const defaultExcludeProperties = this.reflectExcludeProperties(allProperies, this.properties);
    this.excludeProperties = this.assignData.options?.excludeProperties ?? defaultExcludeProperties;
    this.multiple = this.assignData.options?.multiple ?? this.multiple;
    this.title = this.assignData.options?.title ?? this.title;
    this.button = this.assignData.options?.button ?? this.button;
    this.noRecords = this.assignData.options?.noRecords ?? this.noRecords;
    this.selectedKey = this.assignData.options?.selectedKey ?? this.selectedKey;
    this.filterBar.filter(o => {
      o.options = this.getFilterObject(this.items, o.columnProp);
    });
  }

  filterChange(event: MatSelectChange) {
    if (this.filters && event.value === "*") {
      delete this.filters[event.source.id];
      this.filters = { ...this.filters };
      this.filterBarItems = this.filterBarItems.filter(fbi => fbi.value !== event.source.id);
    } else {
      this.filters = {
        ...this.filters,
        [event.source.id]: event.value
      };
      if (this.filterBarItems.some(fbi => fbi.value === event.source.id)) {
        this.filterBarItems = this.filterBarItems.map(fbi => {
          if (fbi.value === event.source.id) {
            return {
              ...fbi,
              label: event.value
            };
          } else {
            return fbi;
          }
        });
      } else {
        this.filterBarItems = [
          ...this.filterBarItems,
          {
            value: event.source.id,
            label: event.value
          }
        ];
      }
    }
  }

  searchTermChange(event: string | undefined) {
    this.searchTerm = event;
    if (event) {
      if (this.filterBarItems.some(fbi => fbi.value === SEARCH_TERM_KEY)) {
        this.filterBarItems = this.filterBarItems.map(fbi => {
          if (fbi.value === SEARCH_TERM_KEY) {
            return {
              ...fbi,
              label: event
            };
          } else {
            return { ...fbi };
          }
        });
      } else {
        this.filterBarItems = [
          ...this.filterBarItems,
          {
            value: SEARCH_TERM_KEY,
            label: event
          }
        ];
      }
    } else {
      this.filterBarItems = this.filterBarItems.filter(fbi => fbi.value !== SEARCH_TERM_KEY);
    }
  }

  itemRemovedHandler(event: FilterToolbarItem) {
    if (event.value === "searchTerm") {
      this.searchTerm = undefined;
    }
    this.filterBar = this.filterBar.map(fb => {
      if (fb.columnProp === event.value) {
        return {
          ...fb,
          modelValue: FILTER_ALL_KEY
        };
      } else {
        return { ...fb };
      }
    });
    if (this.filters) {
      delete this.filters[event.value];
      this.filters = { ...this.filters };
    }
    this.filterBarItems = this.filterBarItems.filter(fbi => fbi.value !== event.value);
  }

  filterClearedHandler(event: FilterToolbarItem[]) {
    this.searchTerm = undefined;
    this.filterBar = this.filterBar.map(fb => {
      return {
        ...fb,
        modelValue: FILTER_ALL_KEY
      };
    });
    this.filters = {};
    this.filterBarItems = event;
  }

  // Satisfies template-use-track-by-function
  trackByIndex: TrackByFunction<string> = (index: number, _item: string) => index;
  trackByAssignColumnDef: TrackByFunction<AssignColumnDef> = (
    _index: number,
    item: AssignColumnDef
  ) => item.key;

  onOptionSelected(item: any) {
    const index = this.selectedItems.indexOf(item);
    if (index === -1) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  isSelected(item: any) {
    if (this.selectedKey) {
      return item[this.selectedKey];
    }
    return this.selectedItems.indexOf(item) !== -1;
  }

  // Get Unique values from columns to build filter
  private getFilterObject(fullObj: Record<string, any>[], key: string) {
    const uniqChk: string[] = [];
    fullObj.filter(obj => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  private reflectProperties(items: Record<string, any>[]): string[] {
    if (items?.length > 0) {
      return Object.keys(items[0]);
    } else {
      return [];
    }
  }

  private reflectExcludeProperties(allProperties: string[], shownProperties: string[]): string[] {
    return allProperties.filter(item => !shownProperties.includes(item));
  }

  private setColumnsFromProperties(
    properties: string[],
    columnDefs: AssignColumnDef[]
  ): AssignColumnDef[] {
    let columns: AssignColumnDef[] = [];

    columns = properties.map(prop => {
      const colDef = columnDefs.find(item => item.key === prop);

      if (colDef) {
        this.showHeaders = true;
        return {
          key: prop,
          width: colDef.width,
          header: colDef.header
        } as AssignColumnDef;
      } else {
        return {
          key: prop,
          width: 100 / properties.length
        } as AssignColumnDef;
      }
    });

    return columns;
  }
}
