import { Pipe, PipeTransform } from "@angular/core";
import { User } from "@vp/core/models";
import { UserApiService } from "@vp/shared/store/user";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Pipe({
  name: "userName"
})
export class UserNamePipe implements PipeTransform {
  constructor(private userApiService: UserApiService) {}

  transform(userId: string): Observable<string> {
    return this.userApiService.getUser(userId).pipe(
      shareReplay(),
      map((user: User) => {
        return `${user.profile.firstName} ${user?.profile.lastName}`;
      })
    );
  }
}
