import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "../../../environments/environment";

@Pipe({
  name: "environment",
  pure: true
})
export class EnvironmentPipe implements PipeTransform {
  transform = (variable: string): any => environment[variable];
}
