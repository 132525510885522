import { AngularEditorConfig } from "@kolkov/angular-editor";
import { LogLevel, OidcConfigService } from "angular-auth-oidc-client";
import { environment } from "../environments/environment";

// this checks if the app is running on IE
export const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig({
      stsServer: environment.authServer,
      redirectUrl: `${window.location.origin}/login/callback`,
      clientId: environment.oktaClientId,
      scope: "openid profile email",
      responseType: "code",
      postLogoutRedirectUri: `${window.location.origin}/loggedout`,
      startCheckSession: false,
      silentRenew: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      postLoginRoute: "/loggedin",
      forbiddenRoute: "/forbidden",
      unauthorizedRoute: "/home",
      logLevel: LogLevel.Warn,
      historyCleanupOff: true,
      disableIatOffsetValidation: true,
      renewTimeBeforeTokenExpiresInSeconds: 120
    });
}

export const angularEditorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  width: "auto",
  minHeight: "490px",
  placeholder: "Enter text here...",
  translate: "no",
  sanitize: true,
  // toolbarPosition: 'top',
  outline: true,
  defaultFontSize: "3",
  // showToolbar: false,
  defaultParagraphSeparator: "p",
  toolbarHiddenButtons: [
    ["subscript", "superscript", "heading", "fontName"],
    [
      "insertImage",
      "insertVideo",
      "link",
      "unlink",
      "customClasses",
      "backgroundColor",
      "fontSize",
      "insertHorizontalRule",
      "removeFormat",
      "toggleEditorMode"
    ]
  ]
};
