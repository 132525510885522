import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { CaseData, CaseUser, PageResult, Profile, User } from "@vp/core/models";
import { Operation } from "rfc6902";
import { forkJoin, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";

export const USER_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class UserApiService {
  constructor(@Inject(USER_API_BASE_URL) private _apiBaseUrl: string, private _http: HttpClient) {}

  public getUser = (userId: string): Observable<User> => {
    if (userId.length === 0) {
      return throwError("User Id is required");
    }
    const apiURL = `${this._apiBaseUrl}/user/${userId}`;
    return this._http.get<User>(apiURL);
  };

  public getUserLogin = (): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/user/get/login`;

    return this._http.get<User>(apiURL);
  };

  public getUsers = (take: number = 10, skip: number = 0): Observable<PageResult<User>> => {
    const apiURL = `${this._apiBaseUrl}/user/?take=${take}&skip=${skip}`;

    return this._http.get<PageResult<User>>(apiURL);
  };

  public getUsersPaged = (
    take: number = 10,
    skip: number = 0,
    sort: string | null | undefined,
    sortdirection: boolean | null | undefined,
    search: string | null | undefined,
    filters?: string[] | null | undefined
  ): Observable<PageResult<User>> => {
    let apiURL = `${this._apiBaseUrl}/user/?take=${take}&skip=${skip}`;
    if (sort) {
      apiURL = apiURL + `&sort=${sort}`;
    }
    if (sort && sortdirection !== null) {
      apiURL = apiURL + `&sortdirection=${sortdirection}`;
    }
    if (search) {
      apiURL = apiURL + `&search=${search}`;
    }
    if (filters) {
      apiURL = apiURL + filters.map(filter => `&filter=${filter}`);
    }

    return this._http
      .get<PageResult<User>>(apiURL, {
        observe: "response"
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          const total: number = Number(response.headers.get("X-Paging-TotalRecordCount")).valueOf();
          return {
            results: response.body as User[],
            totalRecords: total
          } as PageResult<User>;
        })
      );
  };

  public createUser = (userId: string, email: string, profile: Profile): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/user/`;
    return this._http.post<User>(apiURL, {
      userId,
      email,
      profile
    });
  };

  public inviteUser = (user: User): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/invite`;
    return this._http.post<User>(apiURL, user);
  };

  public updateUser = (user: User, resendVerificationCode?: boolean): Observable<User> => {
    let apiURL = `${this._apiBaseUrl}/user/${user.userId}`;
    if (resendVerificationCode) {
      apiURL += "?resend-code=true";
    }
    return this._http.put<User>(apiURL, user);
  };

  public getAssignableUsers = (caseTypeId: string) => {
    const apiURL = `${this._apiBaseUrl}/users?caseTypeId=${caseTypeId}`;
    return this._http.get<User[]>(apiURL);
  };

  public getAssignableUsersByCaseId = (caseId: string, queryString: string): Observable<User[]> => {
    const apiURL = `${this._apiBaseUrl}/case/${caseId}/assignableUsers/${queryString}`;
    return this._http.get<User[]>(apiURL);
  };

  public assignUsersToCase = (caseId: string, userIds: string[]): Observable<boolean[]> => {
    let observableBatch: Observable<boolean>[] = [];
    userIds.forEach((userId: string) => {
      observableBatch.push(this.assignUserToCase(caseId, userId));
    });
    return forkJoin(observableBatch);
  };

  private assignUserToCase = (caseId: string, userId: string): Observable<boolean> => {
    const apiURL = `${this._apiBaseUrl}/case/${caseId}/user/${userId}`;
    return this._http
      .put(apiURL, null, {
        observe: "response"
      })
      .pipe(
        map((result: HttpResponse<any>) => {
          if (result.status === 200) {
            return true;
          }
          return false;
        })
      );
  };

  public getUsersAssignedToCase = (caseId: string): Observable<CaseUser[]> => {
    const apiURL = `${this._apiBaseUrl}/case/${caseId}`;
    return this._http.get<CaseData>(apiURL).pipe(
      map((caseData: CaseData) => {
        return caseData.users;
      })
    );
  };

  public removeUserFromCase = (caseId: string, userId: string): Observable<boolean> => {
    const apiURL = `${this._apiBaseUrl}/case/${caseId}/user/${userId}`;
    return this._http
      .delete(apiURL, {
        observe: "response"
      })
      .pipe(
        map((result: HttpResponse<any>) => {
          if (result.status === 200) {
            return true;
          }
          return false;
        })
      );
  };

  public patch = (userId: string, operations: Operation[]) => {
    const apiURL = `${this._apiBaseUrl}/user/${userId}`;
    return this._http.patch<User>(apiURL, operations);
  };
}
