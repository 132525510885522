import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { CaseType, Organization, User, UserRole } from "@vp/core/models";
import { CaseTypesState } from "@vp/data-access/case-types";
import { OrganizationState } from "@vp/data-access/organization";
import { filterNull, filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { hasOwnProperty } from "@vp/shared/utilities";
import { NgxPermissionsService } from "ngx-permissions";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import { CaseContextService } from "../case-context/case-context.service";
import { Logger } from "../logging/logging.service";

@Injectable({
  providedIn: "root"
})
export class AccessControlService {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(CaseTypesState.allCaseTypes) caseTypes$!: Observable<CaseType[]>;

  rolePermission$ = new ReplaySubject<UserRole>(1);

  constructor(
    private readonly appStoreService: AppStoreService,
    private readonly caseContextService: CaseContextService,
    private readonly logger: Logger,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly router: Router
  ) {}

  initNgxPermissions() {
    combineLatest([
      this.appStoreService.stateChanged.pipe(map(state => state.user)),
      this.organization$.pipe(filterNull()),
      this.caseContextService.Context,
      this.caseTypes$.pipe(filterNullMap())
    ])
      .pipe(
        tap(([user, organization, caseData, caseTypes]) => {
          this.ngxPermissionsService.flushPermissions();
          const currentPermissions: string[] = [];
          if (user && organization) {
            this.addGlobalPermissions(organization, user, currentPermissions);
            if (caseData) {
              const found = caseTypes.find(
                caseType => caseType.caseTypeId === caseData.caseType.caseTypeId
              );
              if (found) {
                this.getCaseTypePermissions(found, user, currentPermissions);
              }
            }
          }
          // Load permissions into NgxPermissionsService (duplicates removed)
          this.ngxPermissionsService.loadPermissions(currentPermissions);
        })
      )
      .subscribe({
        error: (error: unknown) => {
          if (error instanceof Error) this.logger.logException(error);
        }
      });
  }

  /**
   * Get user's non-case type permissions
   * @param caseType The current case type object
   * @param user The current user object
   * @param currentPermissions Updated permissions by ref
   */
  private getCaseTypePermissions(caseType: CaseType, user: User, currentPermissions: string[]) {
    caseType.rolePermissions.forEach(rolePermissionGroup => {
      const hasRole = rolePermissionGroup.roles.find(role => role.roleId === user.selectedRoleId);
      if (hasRole) {
        currentPermissions.push(...rolePermissionGroup.permissions);
      }
    });
  }

  /**
   * Get user's global (non-case type) permissions
   * @param organization The current organization object
   * @param user The current user object
   * @param currentPermissions Updated permissions by ref
   */
  private addGlobalPermissions(
    organization: Organization,
    user: User,
    currentPermissions: string[]
  ) {
    const selectedOrganizationRole = organization.roles.find(
      role => role.roleId === user.selectedRoleId
    );
    if (selectedOrganizationRole) {
      currentPermissions.push(...selectedOrganizationRole.permissions);
    }
  }

  public userHasRoles = (roles: string[]): Observable<(string | undefined)[]> => {
    return this.appStoreService.stateChanged.pipe(
      map(state => state.user),
      filterNullMap(),
      map((user: User) => {
        if (user.roles && user.roles.length > 0) {
          return user.roles
            .filter(role => (role.friendlyId ? roles.includes(role.friendlyId) : false))
            .map(r => r.friendlyId);
        }
        return [];
      })
    );
  };

  public userSelectedRoleIncludes = (roles: string[]): Observable<boolean> => {
    return this.appStoreService.stateChanged.pipe(
      map(state => state.user),
      filterNullMap(),
      map(user => {
        if (user.roles && user.roles.length > 0) {
          const selectedRole = user.roles.find(role => role.roleId === user.selectedRoleId);
          if (selectedRole && selectedRole.friendlyId) {
            return roles.includes(selectedRole.friendlyId);
          }
          return false;
        }
        return false;
      })
    );
  };

  /**
   * Defines a strategy for the first route the user currenly has permissions
   * to view and navigates to it. It will always navigate the router.
   *
   * @param defaultRoutesStrategy object with 'path' and 'only'
   */
  public defaultRoutesStrategy = (defaultRoutesStrategy: unknown[]) =>
    this.ngxPermissionsService.permissions$.pipe(
      filter(permissions => Object.keys(permissions).length !== 0),
      distinctUntilChanged((x, y) => Object.keys(x).length === Object.keys(y).length),
      tap(permissions => {
        const permissionValues = Object.keys(permissions);
        let defaultRoute = ["/"];
        defaultRoutesStrategy.some(route => {
          if (route && typeof route === "object") {
            if (hasOwnProperty(route, "path") && typeof route.path === "string") {
              if (hasOwnProperty(route, "only") && Array.isArray(route.only)) {
                if (route.only.some((x: string) => permissionValues.find(y => x === y))) {
                  defaultRoute = [route.path];
                  return true;
                }
              } else {
                defaultRoute = [route.path];
                return true;
              }
            }
          }
          return false;
        });
        this.router.navigate(defaultRoute);
      })
    );
}
