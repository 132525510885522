import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { UiSchemaConfigService } from "@vp-libs/formly/json-schema";
import { CaseApiService } from "@vp/core/case";
import {
  CaseCommunication,
  CaseData,
  CaseFile,
  CaseManagementData,
  CaseResponse,
  CaseType,
  FileDescriptor,
  Group,
  GroupLite,
  ICaseDocument,
  Organization,
  ResponseCreatedEvent,
  ResponseDeletedEvent,
  ResponseUpdatedEvent,
  Role,
  ServiceFeeTotalViewModel
} from "@vp/core/models";
import { CaseTypesActions, CaseTypesState } from "@vp/data-access/case-types";
import { GroupsState } from "@vp/data-access/groups";
import { OrganizationState } from "@vp/data-access/organization";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { NotificationService } from "@vp/shared/notification";
import { cleanData, filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import {
  deeperCopy,
  IFilterPredicate,
  IPredicate,
  mergeDeep,
  propertyAtPath,
  toObject
} from "@vp/shared/utilities";
import { Guid } from "guid-typescript";
import { createPatch, Operation } from "rfc6902";
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from "rxjs";
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeAll,
  mergeMap,
  scan,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { AnswerGroupApiService } from "../api/answer-group-api.service";
import { ResponseApiService } from "../api/response-api.service";
import { CaseProgress, CaseProgressService } from "../case-progress/case-progress.service";
import { JsonSchemaValidationService } from "../json-schema-service.service";

export type CaseDataContext = CaseData | null;

@Injectable({
  providedIn: "root"
})
export class CaseContextService {
  @Select(CaseTypesState.currentCaseType) caseType$!: Observable<CaseType>;
  @Select(GroupsState.allGroups) groups$!: Observable<Group[]>;
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  private readonly caseData$ = new BehaviorSubject<CaseData | null>(null);
  private readonly recordData$ = new Subject<Record<string, any>>();

  constructor(
    private _answerApi: AnswerGroupApiService,
    private _eventAggregator: EventAggregator,
    private _responseApi: ResponseApiService,
    private appStoreService: AppStoreService,
    private caseApi: CaseApiService,
    private caseProgressService: CaseProgressService,
    private readonly notificationService: NotificationService,
    private readonly store: Store,
    private schemaLayoutService: UiSchemaConfigService,
    private schemaValidationService: JsonSchemaValidationService
  ) {}

  get Context(): Observable<CaseData | null> {
    return this.caseData$.pipe(map(caseData => deeperCopy(caseData)));
  }

  get contextCaseType(): Observable<CaseType> {
    return this.caseType$.pipe(filterNullMap());
  }

  /** @deprecated Use this.Context, retained to support old references until they can be changed. */
  get context(): CaseDataContext {
    return this.caseData$.getValue();
  }

  get hasCase() {
    return !!this.caseData$.getValue();
  }

  get serviceFeeTotal(): Observable<ServiceFeeTotalViewModel> {
    return this.Context.pipe(
      filterNullMap(),
      map((caseData: CaseData) => {
        let totals: ServiceFeeTotalViewModel = {
          totalServices: 0,
          amountDue: 0,
          amountPaid: 0,
          balanceDue: 0
        };

        if (caseData.serviceFees !== undefined && caseData.serviceFees?.length > 0) {
          totals.totalServices = caseData.serviceFees.length;

          let amountDue = 0;
          caseData.serviceFees.forEach(service => {
            service?.fee ? (amountDue = amountDue + service.fee) : 0;
          });

          totals.amountDue = amountDue;
          totals.balanceDue = amountDue;
        }

        if (caseData.payments !== undefined && caseData.payments?.length > 0) {
          let amountPaid = 0;
          caseData.payments.forEach(payment => {
            payment?.amount ? (amountPaid = amountPaid + +payment.amount) : 0;
          });

          totals.amountPaid = amountPaid;
          totals.balanceDue = totals.balanceDue - amountPaid;
        }

        return totals;
      })
    );
  }

  //#region Record Data Functions

  private filters$$: BehaviorSubject<Observable<IFilterPredicate>> = new BehaviorSubject<
    Observable<IFilterPredicate>
  >(of({} as IFilterPredicate));

  /**
   * This is the primary observable for subscribing to records being emitted from the context.
   * This stream is filtered of any matching keys in the excludedKeys$ observable. Which are
   * provided by the pluckRecord function.
   */
  get recordDataStream(): Observable<Record<string, unknown>> {
    return combineLatest([this.recordData$, this.excludedKeys$]).pipe(
      map(([stream, excluded]: [Record<string, any>, IFilterPredicate[]]) => {
        // check for any excluded records on the stream and remove them, notifying
        // the remover via the predicate
        const excludedKey = excluded.find(e => stream.hasOwnProperty(e.key));
        if (excludedKey) {
          excludedKey.predicate(stream);
          return null;
        }
        return stream;
      }),
      filterNullMap(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private excludedKeys$: Observable<IFilterPredicate[]> = this.filters$$.pipe(
    mergeAll(),
    scan((acc: IFilterPredicate[], filter: IFilterPredicate) => {
      const exists = acc.find(a => a.key === filter.key);
      if (!exists) {
        acc.push(filter);
      }
      return acc;
    }, [] as IFilterPredicate[])
  );

  /**
   * Creates an observable of keys which removes matching records from the record stream and sends the
   * record to the caller via the provided predicate.
   *
   * WARNING: Because record are removed from the stream when they are found, ONLY ONE instance of this
   * function can exist for each record being plucked. This is by design. The record can be re-emiited
   * in the predicate body if needed elsewhere.
   *
   * @param key String - The name of the key of the record to remove and send to the predicate.
   * @param predicate Function - A fucntion that is called on and with matching records in the stream
   */
  public pluckRecord(key: string, predicate: IPredicate): void {
    const filter = of({
      key: key,
      predicate: predicate
    });
    this.filters$$.next(filter);
  }

  get progress(): Observable<CaseProgress> {
    return this.Context.pipe(
      filterNullMap(),
      map((caseData: CaseData) => {
        return this.caseProgressService.calculateCaseProgress(caseData);
      })
    );
  }

  /**
   * Return the first or default document descriptor for the current case
   * @todo Extra document descriptors are ignored currently
   */
  get contextFirstOrDefaultDocumentsDescriptor() {
    return this.caseType$.pipe(
      filterNullMap(),
      map(caseType => {
        const first = caseType.documents.documentDescriptors[0] ?? new FileDescriptor();
        return new FileDescriptor(first.fileTypes, first.required, first.recommended);
      })
    );
  }

  /**
   * Updates the recordData in the context and saves it.
   * This combines the updating the context and saving the data to the API
   * so that the service has more control instead of the components mangaging it.
   */
  saveRecordData = (recordData: any): Observable<boolean> => {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }
    return this.validateRecordDataAndUpdateContext(recordData).pipe(
      switchMap((cleanData: CaseData) => {
        if (cleanData) {
          return this._answerApi.updateRecordData(cleanData.caseId, cleanData.recordData).pipe(
            map((caseData: CaseData) => {
              return caseData ? true : false;
            })
          );
        } else {
          return of(false);
        }
      })
    );
  };

  validateRecordDataAndUpdateContext(recordData: Record<string, any>): Observable<CaseData> {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }
    return this.Context.pipe(
      filterNullMap(),
      take(1),
      tap((caseData: CaseData) => {
        if (!caseData.recordData) {
          caseData.recordData = {};
        }
        let entries = Object.entries(recordData);
        entries.forEach((pair: [string, unknown]) => {
          const record = cleanData(toObject([pair]));
          /* Validate the record data against the schema to determine if it can be
           * added to the context. */
          const validationResult = this.schemaValidationService.validateData(
            caseData.caseType.caseTypeId.concat("recordSchema"),
            record
          );
          if (validationResult.isValid) {
            // clean data here again to ensure no nulls or empty strings from pevious values.
            caseData.recordData = cleanData(mergeDeep(caseData.recordData, record, "replace"));
          }
          this.recordData$.next(record);
        });
        this.caseData$.next(caseData);
      }),
      catchError((err: any) => {
        this.notificationService.warningMessage(err);
        return throwError(err);
      })
    );
  }

  /**
   * Updates the managementData in the context and saves it.
   * This combines the updating the context and saving the data to the API
   * so that the service has more control instead of the components mangaging it.
   */
  saveManagementData = (managementData: any): Observable<boolean> => {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }
    return this.validateManagementDataAndUpdateContext(managementData).pipe(
      switchMap((cleanData: CaseData) => {
        if (cleanData) {
          return this._answerApi
            .saveManagementData(cleanData.caseId, cleanData.management.managementData)
            .pipe(
              map((caseData: CaseData) => {
                return caseData ? true : false;
              })
            );
        } else {
          return of(false);
        }
      })
    );
  };

  validateManagementDataAndUpdateContext(
    managementData: Record<string, any>
  ): Observable<CaseData> {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }
    return this.Context.pipe(
      filterNullMap(),
      take(1),
      switchMap((caseData: CaseData) => {
        if (caseData.management === null || caseData.management === undefined) {
          caseData.management = {} as CaseManagementData;
        }
        const cleanManagementData = cleanData(managementData);
        /* Validate the managementData against the schema to determine if it can be added to the context. */
        const validationResult = this.schemaValidationService.validateData(
          caseData.caseType.caseTypeId.concat("managementSchema"),
          cleanManagementData
        );
        if (validationResult.isValid) {
          // clean data here again to ensure no nulls or empty strings from pevious values.
          caseData.management.managementData = cleanData(
            mergeDeep(caseData.management.managementData, cleanManagementData, "replace")
          );
        } else {
          return throwError(validationResult.errorsText);
        }
        return of(caseData);
      }),
      tap((caseData: CaseData) => {
        this.caseData$.next(caseData);
      }),
      catchError((err: any) => {
        this.notificationService.warningMessage(err);
        return throwError(err);
      })
    );
  }

  saveSummaryData(summaryData: string): Observable<boolean> {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }

    const context = this.caseData$.getValue() as CaseData;
    return this.caseApi.updateCaseSummary(context.caseId, summaryData).pipe(
      switchMap((success: boolean) => {
        if (success) {
          return this.updateSummaryDataContext(summaryData);
        }
        return of(false);
      })
    );
  }

  saveSummaryViewNotesData(summaryViewNotes: string): Observable<boolean> {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }

    const context = this.caseData$.getValue() as CaseData;
    return this.caseApi.updateCaseSummaryViewNotes(context.caseId, summaryViewNotes).pipe(
      switchMap((success: boolean) => {
        if (success) {
          return this.updateSummaryViewNotesContext(summaryViewNotes);
        }
        return of(false);
      })
    );
  }

  updateManagementDataContext = (caseManagementData: CaseManagementData) => {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }

    const context = this.caseData$.getValue() as CaseData;
    context.management = caseManagementData;
    this.caseData$.next(context);
    return of(true);
  };

  updateSummaryDataContext(summaryData: string): Observable<boolean> {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }

    const context = this.caseData$.getValue() as CaseData;
    context.summary = summaryData;
    this.caseData$.next(context);
    return of(true);
  }

  updateSummaryViewNotesContext(summaryViewNotes: string): Observable<boolean> {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }

    const context = this.caseData$.getValue() as CaseData;
    context.summaryViewNotes = summaryViewNotes;
    this.caseData$.next(context);
    return of(true);
  }

  createEmptyCase(
    caseTypeId: string,
    defaults: Record<string, unknown> = {},
    subjectUserId: string | null = null
  ): Observable<CaseData> {
    return this.caseApi.getCase(Guid.EMPTY, caseTypeId).pipe(
      tap((caseData: CaseData) => {
        this.store.dispatch(new CaseTypesActions.SetCurrentCaseType(caseData.caseType.caseTypeId));
      }),
      switchMap((caseData: CaseData) => {
        return combineLatest([of(caseData), this.caseType$.pipe(filterNullMap())]);
      }),
      tap(([caseData, caseType]: [CaseData, CaseType]) => {
        if (Object.keys(defaults).length > 0) {
          this.applyDefaultProperties(caseType.recordSchema, defaults);
        }
        if (subjectUserId) {
          if (!Guid.isGuid(subjectUserId)) {
            throw Error("Subject User is not valid.");
          }
          caseData.subjectUserId = subjectUserId;
        }
      }),
      withLatestFrom(this.groups$.pipe(filterNullMap()), this.organization$),
      map(([[caseData, caseType], groups, org]: [[CaseData, CaseType], Group[], Organization]) => {
        this.schemaValidationService.loadSchema(
          caseType.recordSchema,
          caseType.caseTypeId.concat("recordSchema")
        );
        populateCaseDataGroups(caseData.groups, groups, org);
        return caseData;
      }),
      tap((caseData: CaseData) => {
        this.caseData$.next(caseData);
      }),
      take(1)
    );
  }

  private applyDefaultProperties(recordSchema: any, defaults: Record<string, unknown>) {
    Object.entries(defaults).reduce((accumulator, pair: [string, any]) => {
      const key = pair[0];
      const value = pair[1];
      if (value.hasOwnProperty("properties")) {
        this.applyDefaultProperties(value["properties"], propertyAtPath(value["properties"], key));
      } else {
        Object.entries(value).forEach(([_key, _value]) => {
          if (!!accumulator[key]["properties"][_key]) {
            accumulator[key]["properties"][_key]["default"] = _value;
          }
        });
        return accumulator;
      }
    }, recordSchema["properties"]);
  }

  /**
   *
   * @param caseResponse {CaseResponse}
   * @param fileName {string}
   * @returns {boolean} indicates whether or not the action completed successfully
   */
  generateResponsePdf = (caseResponse: CaseResponse, fileName?: string): Observable<CaseData> => {
    return this.Context.pipe(
      concatMap(caseData => {
        return combineLatest([
          this._responseApi.generateResponsePdf(caseData.caseId, caseResponse, fileName),
          of(caseResponse),
          of(caseData)
        ]);
      }),
      take(1),
      switchMap(([caseFile, caseResponse, caseData]: [CaseFile, CaseResponse, CaseData]) => {
        caseResponse.document = caseFile.url;
        caseData.documents.documentList.push(caseFile);
        let index = caseData.responses.findIndex(
          (r: CaseResponse) => r.responseId === caseResponse.responseId
        );
        caseData.responses[index] = caseResponse;
        return this.patchCase(caseData);
      }),
      tap(() => {
        this.contextRefresh();
      })
    );
  };

  // generateResponsePdf = (
  //   caseResponse: CaseResponse,
  //   fileName?: string
  // ): Observable<null | CaseResponse> => {
  //   const context = this.caseData$.getValue() as CaseData;
  //   return this._responseApi.generateResponsePdf(context.caseId, caseResponse, fileName).pipe(
  //     switchMap((caseFile: CaseFile) => {
  //       caseResponse.document = caseFile.url;
  //       let index = context.responses.findIndex(r => r.responseId === caseResponse.responseId);
  //       context.responses[index] = caseResponse;
  //       return this.patchCase(context);
  //     }),
  //     map((caseData: null | CaseData) => {
  //       return (
  //         caseData?.responses.find(response => {
  //           return response.responseId === caseResponse.responseId;
  //         }) ?? null
  //       );
  //     })
  //   );
  // };

  contextCaseId = this.Context.pipe(
    filterNullMap(),
    map(caseData => caseData?.caseId)
  );

  contextRefresh(): Observable<CaseData> {
    const currentCaseId = this.caseData$.getValue()?.caseId;
    return currentCaseId ? this.load(currentCaseId) : throwError("Invalid Context");
  }

  load = (caseId: string): Observable<CaseData> => {
    return this.caseApi.getCase(caseId).pipe(
      tap((caseData: CaseData) =>
        this.store.dispatch(new CaseTypesActions.SetCurrentCaseType(caseData.caseType.caseTypeId))
      ),
      switchMap((caseData: CaseData) => {
        return combineLatest([of(caseData), this.caseType$.pipe(filterNullMap())]);
      }),
      map(([caseData, caseType]: [CaseData, CaseType]) => {
        this.schemaLayoutService.addScopedConfig(caseType.recordLayout, caseType.caseTypeId);
        this.schemaValidationService.loadSchema(
          caseType.recordSchema,
          caseType.caseTypeId.concat("recordSchema")
        );
        this.schemaValidationService.loadSchema(
          caseType.managementSchema,
          caseType.caseTypeId.concat("managementSchema")
        );
        return caseData;
      }),
      tap((caseData: CaseData) => {
        this.caseData$.next(caseData);
      }),
      take(1)
    );
  };

  reset = () => {
    this.caseData$.next(null);
  };

  public patchCase(updated: CaseData): Observable<CaseData> {
    return of(updated).pipe(
      mergeMap((changed: CaseData) => {
        return combineLatest([of(this.caseData$.getValue() as CaseData), of(changed)]);
      }),
      map(([original, changed]: [CaseData, CaseData]) => {
        return {
          caseId: changed.caseId,
          operations: createPatch(original, changed)
        };
      }),
      switchMap((caseOperations: { caseId: string; operations: Operation[] }) =>
        this.caseApi
          .patch(caseOperations.caseId, caseOperations.operations)
          .pipe(switchMap(() => this.contextRefresh()))
      ),
      take(1)
    );
  }

  new(): Observable<boolean> {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }
    return of(this.caseData$.getValue()).pipe(
      filterNullMap(),
      take(1),
      tap((caseData: CaseData) => {
        caseData.recordData = cleanData(caseData.recordData);
        caseData.active = true;
      }),
      switchMap(caseData => {
        return this.caseApi.createCase(caseData);
      }),
      tap((caseData: CaseData) => {
        // TODO: Working with client on GTM setup
        // this._eventAggregator.emit(
        //   new TagEvent({
        //     "event" : "create-case-event"
        //   }),
        //   "CaseContextService.createCase"
        // );
        this.caseData$.next(caseData);
      }),
      map(() => {
        return true;
      })
    );
  }

  createResponse = (caseResponse: Partial<CaseResponse>): Observable<CaseResponse> => {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }

    const context = this.caseData$.getValue() as CaseData;
    return this._responseApi.saveResponse(context.caseId, caseResponse).pipe(
      concatMap((success: boolean) => {
        if (success) {
          this._eventAggregator.emit(
            new ResponseCreatedEvent(caseResponse as CaseResponse),
            "CaseContextService.createResponse"
          );
          return this.load(context?.caseId as string);
        }
        return throwError("Error Saving Response.");
      }),
      map((caseData: CaseData) => {
        if (Array.isArray(caseData?.responses) && caseData?.responses.length > 0) {
          const response = caseData?.responses.find(response => {
            return response.resultId === caseResponse.resultId;
          });
          if (response) return response;
        }
        throw Error("Case contains no responses.");
      })
    );
  };

  deleteResponse = (responseId: string): Observable<boolean> => {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }

    const context = this.caseData$.getValue() as CaseData;
    return this._responseApi.daleteResponse(context.caseId, responseId).pipe(
      tap((success: boolean) => {
        if (success) {
          this._eventAggregator.emit(
            new ResponseDeletedEvent(responseId),
            "CaseContextService.createResponse"
          );
        }
      }),
      switchMap((deleted: boolean) => {
        if (deleted) {
          return this.load(context.caseId);
        }
        return of(null);
      }),
      map((caseData: null | CaseData): boolean => {
        return !caseData?.responses.find(response => {
          return response.responseId === responseId;
        });
      })
    );
  };

  updateResponse = (caseResponse: CaseResponse): Observable<undefined | null | CaseResponse> => {
    if (!this.hasCase) {
      throw new Error("context is missing");
    }

    const context = this.caseData$.getValue() as CaseData;
    return this._responseApi.updateResponse(context.caseId, caseResponse).pipe(
      switchMap((created: boolean) => {
        if (created) {
          this._eventAggregator.emit(
            new ResponseUpdatedEvent(caseResponse),
            "CaseContextService.createResponse"
          );
          if (this.context) {
            return this.load(this.context.caseId);
          } else {
            return of(null);
          }
        }
        return of(null);
      }),
      map((caseData: null | CaseData) => {
        return caseData?.responses.find(response => {
          return response.responseId === caseResponse.responseId;
        });
      })
    );
  };

  /**
   * Get the communications user can view based on current role
   */
  contextCommunications = this.Context.pipe(
    filterInactive(),
    mergeMap((caseData: CaseData) =>
      combineLatest([of(caseData), this.appStoreService.selectedRole, this.contextCaseType])
    ),
    map(([caseData, userRole, caseType]: [CaseData | null, Role | undefined, CaseType | null]) => {
      const caseCommunications: CaseCommunication[] = [];

      caseData?.communications.forEach(c => {
        const ctComm = caseType?.communications.find(
          comm => comm.communicationTypeId === c.communicationType.communicationTypeId
        );

        if (!ctComm?.audience.roles || !ctComm.audience.roles.length) {
          caseCommunications.push(c);
        }

        if (ctComm?.audience.roles.find(r => r.roleId === userRole?.roleId)) {
          caseCommunications.push(c);
        }
      });

      return caseCommunications;
    })
  );

  /**
   * Return images for the current case
   */
  contextImages = this.Context.pipe(
    filterInactive(),
    map((caseData: CaseData) => {
      const isVideo = (fileName: string): boolean => {
        return fileName.substring(fileName.indexOf(".") + 1) === "mp4";
      };

      const caseImages = caseData?.images.imageList;

      // Add new helper property for videos
      caseImages?.forEach(x => (x.isVideo = isVideo(x.fileName)));

      return caseImages;
    })
  );

  /**
   * Return DICOM studies for the current case
   * `secureLink` property is updated with environment base URL
   */
  contextDicomStudies = this.Context.pipe(
    filterInactive(),
    map((caseData: CaseData) => {
      return caseData.dicomStudies || [];
    })
  );

  /**
   * Return images for the current case
   */
  contextImagesJson = this.Context.pipe(
    filterInactive(),
    withLatestFrom(this.contextCaseType),
    map(([caseData, caseType]: [CaseData, CaseType]) => {
      const imageLayout = caseType?.images?.imageLayout ?? {};
      const imageSchema = caseType?.images?.imageSchema ?? {};
      if (Object.keys(imageLayout).length > 0) {
        this.schemaLayoutService.addScopedConfig(imageLayout, `imageLayout${caseType.caseTypeId}`);
      }
      return {
        caseId: caseData?.caseId,
        caseTypeId: caseType?.caseTypeId,
        data: caseData?.images?.imageData ?? {},
        schema: imageSchema,
        layout: imageLayout
      };
    })
  );

  /**
   * Return documents for the current case
   */
  contextDocuments = this.Context.pipe(
    filterInactive(),
    map((caseData: CaseData) => {
      const iconMap: Map<string, string> = new Map([
        ["pdf", "picture_as_pdf"],
        ["docx", "description"],
        ["doc", "description"],
        ["zip", "description"]
      ]);

      let caseDocuments = caseData?.documents.documentList;

      // Add new icon property using extension
      caseDocuments = caseDocuments?.map((document: CaseFile) => {
        const extenstion = document.fileName.substr(
          document.fileName.lastIndexOf(".") + 1,
          document.fileName.length - 1
        );

        return {
          fileName: document.fileName,
          displayName: document.displayName,
          fileDescription: document.fileDescription,
          url: document.url,
          extension: extenstion,
          icon: iconMap.get(extenstion)
        } as ICaseDocument;
      });

      return (caseDocuments as ICaseDocument[]) ?? [];
    })
  );

  /**
   * Return documents for the current case
   */
  contextDocumentsJson = this.Context.pipe(
    filterInactive(),
    withLatestFrom(this.contextCaseType),
    map(([caseData, caseType]: [CaseData, CaseType]) => {
      return {
        caseId: caseData?.caseId,
        data: caseData?.documents?.documentData ?? {},
        schema: caseType?.documents?.documentSchema ?? {},
        layout: caseType?.documents?.documentLayout ?? {}
      };
    })
  );
}

const filterInactive = () => {
  return (source$: Observable<CaseData | null | undefined>) =>
    source$.pipe(
      filterNullMap(),
      filter((caseData: CaseData) => {
        return caseData.active === true;
      })
    );
};
const populateCaseDataGroups = (groupLites: GroupLite[], groups: Group[], org: Organization) => {
  groupLites.forEach(groupLite => {
    let group = groups.find(g => g.groupId === groupLite.groupId);
    if (group) {
      groupLite.groupDisplayName = group.displayName;
      groupLite.groupDescription = group.description;
    }
    let groupType = org.groupTypes.find(g => g.groupTypeId === groupLite.groupTypeId);
    if (groupType) groupLite.groupTypeDisplayName = groupType.displayName;
  });
};
