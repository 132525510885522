import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Organization, Template } from "@vp/core/models";
import { tap } from "rxjs/operators";
import { OrganizationApiService } from "../api/organization-api.service";
import { OrganizationActions } from "./organization-actions";

export interface OrganizationStateModel {
  organization: Organization;
  templates: Template[];
}

@State<OrganizationStateModel>({
  name: "organization",
  defaults: { organization: null, templates: [] }
})
@Injectable()
export class OrganizationState {
  constructor(private readonly _organizationApiService: OrganizationApiService) {}

  @Selector()
  public static organization(state: OrganizationStateModel) {
    return state.organization;
  }

  @Selector()
  public static templates(state: OrganizationStateModel) {
    return state.templates;
  }

  @Action(OrganizationActions.LoadOrganization)
  load(ctx: StateContext<OrganizationStateModel>, {}: OrganizationActions.LoadOrganization) {
    return this._organizationApiService.getOrganization().pipe(
      tap((organization: Organization) => {
        ctx.patchState({ organization: organization });
      })
    );
  }

  @Action(OrganizationActions.PatchOrganization)
  patch(
    ctx: StateContext<OrganizationStateModel>,
    { opertaions: operations }: OrganizationActions.PatchOrganization
  ) {
    return this._organizationApiService.patch(operations).pipe(
      tap((organization: Organization) => {
        ctx.patchState({ organization: organization });
      })
    );
  }

  @Action(OrganizationActions.LoadTemplates)
  loadTemplates(ctx: StateContext<OrganizationStateModel>, {}: OrganizationActions.LoadTemplates) {
    return this._organizationApiService.getTemplates().pipe(
      tap((templates: Template[]) => {
        ctx.patchState({ templates: templates });
      })
    );
  }
}
