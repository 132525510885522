import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileUploadComponent } from "./file-upload.component";
import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FileDragDropDirective } from "./file-drag-drop.directive";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { DocumentPreviewComponent } from "./document-preview/document-preview.component";
import { UploadProgressComponent } from "./upload-progress/upload-progress.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { NgxFileDropModule } from "ngx-file-drop";
import { MatTableModule } from "@angular/material/table";
import { NgxFilesizeModule } from "ngx-filesize";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    FileUploadComponent,
    FileDragDropDirective,
    DocumentPreviewComponent,
    UploadProgressComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true
    }),
    MatProgressBarModule,
    MatDividerModule,
    MatTableModule,
    NgxFileDropModule,
    NgxFilesizeModule
  ],
  exports: [
    FileUploadComponent,
    FileDragDropDirective,
    DocumentPreviewComponent,
    UploadProgressComponent
  ]
})
export class FileUploadModule {}
