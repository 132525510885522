import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  TrackByFunction
} from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ThemeManagerService } from "@vp-libs/shared/theme-manager";
import { OrganizationFeatures, Role } from "@vp/core/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { FeatureFlagsService } from "@vp/shared/feature-flags";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { AppStoreService } from "@vp/shared/store/app";
import { UiStoreService } from "@vp/shared/store/ui";
import { RouterUtilities } from "@vp/shared/utilities";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { switchMap, take, takeUntil, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { IS_IVY_API } from "../../app-initializer.factory";
import { SignalRService } from "../../services/signal-r.service";
import { NavigationItem } from "../side-nav";

@Component({
  selector: "vp-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit, OnDestroy {
  @Input() navItems!: NavigationItem[] | null;
  @Input() navToggled!: boolean;
  @Input() showMobileMenu = false;

  logo!: SafeUrl;
  logoRedirectLink: string | undefined;
  isProd = environment.production;

  signalREnabled$ = this.featureFlagService.featureEnabled$(OrganizationFeatures.signalR);

  public OrganizationFeatures: any = OrganizationFeatures;

  private readonly isLoading$$ = new BehaviorSubject<boolean>(false);
  private readonly userDisplayName$$ = new BehaviorSubject<string>("loading...");
  private readonly selectedRoleDisplayName$$ = new BehaviorSubject<string>("loading...");

  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Inject(IS_IVY_API) public readonly isIvyApi: boolean,
    public readonly authenticationService: AuthenticationService,
    public readonly appStoreService: AppStoreService,
    public readonly _PermissionsConstService: PermissionsConstService,
    public readonly permConst: PermissionsConstService,
    public readonly uiStoreService: UiStoreService,
    public readonly signalRService: SignalRService,
    public readonly featureFlagService: FeatureFlagsService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly routerUtilitiesService: RouterUtilities,
    private readonly themeManagerService: ThemeManagerService
  ) {
    this.logo = this.themeManagerService.logo;
    this.logoRedirectLink = this.themeManagerService.logoRedirectLink;
  }

  ngOnInit(): void {
    if (this.isIvyApi) {
      this.logoRedirectLink = "/wizard";
      this.logo = "assets/ivy/ivylogo.png";
    }

    this.appStoreService.userFullName$
      .pipe(filterNullMap(), takeUntil(this.destroyed$))
      .subscribe((userFullName: string): void => {
        if (!userFullName.includes("undefined")) {
          this.userDisplayName$$.next(userFullName);
        }
      });

    this.appStoreService.selectedRoleDisplayName$
      .pipe(filterNullMap(), takeUntil(this.destroyed$))
      .subscribe((selectedRoleDisplayName: string): void => {
        this.selectedRoleDisplayName$$.next(selectedRoleDisplayName);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  get userDisplayName$(): Observable<string> {
    return this.userDisplayName$$.asObservable();
  }

  get selectedRoleDisplayName$(): Observable<string> {
    return this.selectedRoleDisplayName$$.asObservable();
  }

  onLogout = () => {
    this.authenticationService.logout();
  };

  onLogin = () => {
    this.isLoading$$.next(true);
    this.authenticationService.login();
  };

  switchUserRole(roleId: string) {
    this.appStoreService
      .updateUserRole(roleId)
      .pipe(
        switchMap(() => this.appStoreService.selectedRole),
        tap((role: Role) => {
          let { path, queryParams } = this.routerUtilitiesService.getRouteDefaultFromRole(role);
          this.router.navigate([path], { queryParams });
        }),
        take(1)
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Switched role and app updated"),
        error: () => this.notificationService.errorMessage("Cannot switch role")
      });
  }

  // Satisfies template-use-track-by-function
  trackByFn: TrackByFunction<Role> = (_index: number, item: Role) => item.roleId;
}
