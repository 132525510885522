import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SpinnerModule } from "@vp/shared/spinner";
import { AdvancedCommandsComponent } from "./components/advanced-commands.component";
import { CommandListComponent } from "./components/command-list/command-list.component";

@NgModule({
  declarations: [AdvancedCommandsComponent, CommandListComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SpinnerModule
  ],
  exports: [AdvancedCommandsComponent, CommandListComponent]
})
export class AdvancedCommandsModule {}
