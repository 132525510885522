import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthenticationService } from "@vp/shared/authentication";

@Component({
  selector: "vp-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent {
  constructor(private _authenticationService: AuthenticationService) {}

  login = ($event: Event) => {
    $event.preventDefault();
    this._authenticationService.login();
  };
}
