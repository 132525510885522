import { mergeDeep } from "..";

/**
 * Merge `arr2` into `arr1` using the `key` property
 */
export const mergeByKey = <T>(arr1: T[], arr2: T[], key: string) => {
  return arr1.map((obj: any) => arr2.find((o: any) => o[key] === obj[key]) || obj);
};

export type arrayAction = "merge" | "concat" | "replace";

/**
 * @description Uses fisher yates algorithm to shuffle an array
 * @param array array to shuffle
 */
export const shuffle = (array: Array<any>): Array<any> => {
  const modified = [...array];
  for (let i = modified.length - 1; i > 0; --i) {
    const j = Math.floor(Math.random() * modified.length);
    const temp = modified[i];
    modified[i] = modified[j];
    modified[j] = temp;
  }

  return modified;
};

/**
 * @description Check to determine if IE11 or Edge browser
 */
export const isIE11orEdge = () => {
  return !!navigator.userAgent.match(/Trident.*rv[ :]*11\./) || navigator.userAgent.match(/Edge/);
};

export const isIE = () =>
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Converts milliseconds to a human readable string using mm:ss
 * @param milliseconds number of milliseconds
 * @returns formatted string
 */
export const millisecondsToMinuteString = (milliseconds: number) => {
  milliseconds = 1000 * Math.round(milliseconds / 1000);
  const date = new Date(milliseconds);
  const seconds =
    date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : `${date.getUTCSeconds()}`;
  return `${date.getUTCMinutes()}:${seconds}`;
};

/**
 * Converts milliseconds to a human readable string using hh:mm:ss
 * @param milliseconds number of milliseconds
 * @returns formatted string
 */
export const millisecondsToHoursString = (milliseconds: number) => {
  milliseconds = 1000 * Math.round(milliseconds / 1000);
  const date = new Date(milliseconds);
  const minutes =
    date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : `${date.getUTCMinutes()}`;
  const seconds =
    date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : `${date.getUTCSeconds()}`;

  return `${date.getUTCHours()}:${minutes}:${seconds}`;
};

export type keySelector<T, U> = (x: T) => U;
/**
 * Sort array comparer using selector function
 * @param selector function to determine the key to use in the sort
 * @returns sorted array
 */
export const compareBy = <T>(selector: keySelector<T, string>) => {
  return (a: T, b: T) => selector(a).localeCompare(selector(b));
};

/**
 * Finds a needle in a haystack
 * @param selector function to determine the key to use in the comparison
 * @param needle string to find
 * @param haystack array to look in
 * @returns index found
 */
export const findIndexInArrayBy = <T>(
  selector: keySelector<T, string>,
  needle: string,
  haystack: T[]
) => {
  const count = haystack.length;
  for (let i = 0; i < count; i++) {
    if (selector(haystack[i]) === needle) {
      return i;
    }
  }
  return -1;
};

/**
 * Validates `mm/yy` pattern
 */
export const MONTH_YEAR_PATTERN = /\d\d\/\d\d/;

/**
 * Returns `mm` of a `mm/yy` string
 * @param value A `mm/yy` string
 */
export const getMonthYearFromString = (value: string) => {
  const _split = splitAtIndex(value, 2);
  if (TryParseNumber(_split[0]) > 12) {
    throw Error("Not a valid month.");
  }
  if (_split[1].length > 2) {
    throw Error("Year is not the in correct format.");
  }
  return {
    month: _split[0],
    year: _split[1]
  };
};

export const splitAtIndex = (slicable: string, ...indices: number[]) => {
  return [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]));
};

export const TryParseNumber = (input: string): Number => {
  if (!input) return NaN;
  if (input.trim().length === 0) {
    return NaN;
  }
  return Number(input);
};

/**
 * Convert a string to a number; undefined otherwise.
 * @param s A nullable string
 */
export const stringToNumber = (s: string | null | undefined): number | undefined => {
  if (s === null || s === undefined) {
    return undefined;
  }
  const n = parseFloat(s);
  if (isNaN(n)) {
    return undefined;
  }
  return n;
};

/**
 * Scoped property creation on target object. Inserts the objcToInsert object at the location of props specificed by
 * an array of properties representing the "path" of the object to insert:
 *
 * For Example:
 *  ["parent","child", "grandchild", "greatgrandchild"] = parent.child.grandchild.greatgrandchild
 *
 * This also creates any missing nested objects along the way. So if grandchild is missing in the above example, then it
 * is created, and the greatgrandchild is created with the objToInsert object.
 *
 * @param targetObject Object - The target object to insert said properties into. By reference.
 * @param props Array - Array of properties representing the path at which to insert. See above for example.
 * @param objecToInsert Object - the object to be inserted at the above path as the last property in the props array
 */
export const insertObjectAt = (targetObject: any, props: string[], objToInsert: any): void => {
  props.reduce((result, prop) => {
    if (!result[prop]) {
      result[prop] = {};
      return insertObjectAt(result, [prop], objToInsert);
    } else if (result[prop].constructor === Object) {
      result[prop] = mergeDeep(result[prop], objToInsert);
    }
    return result[prop];
  }, targetObject);
};
export const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

/**
 * Test for any items in an array.
 * Use: `myArray.some(hasAny)`
 */
export const hasAny = () => true;

interface Item<T = any> {
  [key: string]: T;
}

/**
 * A function to use with array filter based on object propery
 * Use: `array.filter(uniqueByKeyFilterFn("myProperyKey"))`
 *
 * @param key propery used to test uniqueness
 */
export const uniqueByKeyFilterFn = <T extends Item>(key: keyof T) => {
  return (v: T, i: number, a: T[]) => a.findIndex((t: T) => t[key] === v[key]) === i;
};

export const toObject = (pairs: [[string, unknown]]) => {
  return Array.from(pairs).reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {});
};

export const isRecord = (value: unknown): value is Record<string, unknown> => {
  return typeof value === "object" && value !== null;
};

/**
 * NonNullable return type
 * @param value Value to check
 */
export const isNonNull = <T>(value: T): value is NonNullable<T> => {
  return value !== null;
};

export interface IPredicate {
  (...args: any[]): void;
}

export interface IFilterPredicate {
  key: string;
  predicate: IPredicate;
}

export const propertyAtPath = (obj: any, path: string) => {
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  var a = path.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in obj) {
      obj = obj[k];
    } else {
      return;
    }
  }
  return obj;
};

export const deepMerge = (target: any, source: any) => {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = deepMerge(target[key] || {}, source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

/**
 * Compares two Date objects and returns e number value that represents
 * the result:
 * 0 if the two dates are equal.
 * 1 if the first date is greater than second.
 * -1 if the first date is less than second.
 * @param date1 First date object to compare.
 * @param date2 Second date object to compare.
 */
export const compareDate = (date1: Date, date2: Date): number => {
  let d1 = new Date(date1);
  let d2 = new Date(date2);
  let same = d1.getTime() === d2.getTime();
  if (same) return 0;
  if (d1 > d2) return 1;
  if (d1 < d2) return -1;
  throw Error("Invalid Date Range");
};

export const MAX_TIME = 8640000000000000;
export const MAX_DATE = (): Date => {
  var maxDate = new Date(MAX_TIME);
  var dayBefore = maxDate.getDate() - 1;
  maxDate.setDate(dayBefore);
  maxDate.setHours(0, 0, 0);
  return maxDate;
};

export const indexOfOf = (value: any, outer: number[], inner: number[]) => {
  const result = outer.indexOf(inner.indexOf(value)) > -1;
  return result;
};
