import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { User } from "@vp/core/models";
import { EmailVerificationService } from "@vp/profile/data-access/email-verification";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import {
  catchError,
  delay,
  first,
  map,
  switchMap,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";
@Component({
  selector: "vp-email-verification",
  templateUrl: "./email-verification.component.html",
  styleUrls: ["./email-verification.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmailVerificationService],
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateY(-100%)" }),
        animate("200ms ease-in", style({ transform: "translateY(0%)" }))
      ]),
      transition(":leave", [animate("200ms ease-in", style({ transform: "translateY(-100%)" }))])
    ])
  ]
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  form$!: Observable<FormGroup>;
  verified$!: Observable<boolean>;
  showVerification$!: Observable<boolean>;
  user$!: Observable<User>;
  verifying$!: Observable<boolean>;
  invalid$!: Observable<boolean>;

  private imported$!: Observable<boolean>;
  private form$$ = new BehaviorSubject<FormGroup | null>(null);
  private invalid$$ = new BehaviorSubject<boolean | null>(null);

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly appStoreService: AppStoreService,
    private readonly notificationService: NotificationService,
    private readonly emailVerificationService: EmailVerificationService
  ) {
    let form = this.formBuilder.group({
      verificationCode: [
        null,
        [Validators.minLength(6), Validators.required],
        [this.verificationCodeValidator()]
      ]
    });
    this.form$$.next(form);

    this.user$ = this.appStoreService.userStream.pipe(filterNullMap(), takeUntil(this.destroyed$));

    this.verified$ = this.user$.pipe(
      map(user => user.tags),
      map(tags => tags.includes("email.verified")),
      takeUntil(this.destroyed$)
    );

    this.verifying$ = this.user$.pipe(
      map(user => user.tags),
      map(
        tags =>
          !tags.includes("email.verified") && !!tags.find((tag: string) => tag.includes("verify."))
      ),
      takeUntil(this.destroyed$)
    );

    this.imported$ = this.user$.pipe(
      map(user => user.tags),
      map(tags => tags.includes("import")),
      takeUntil(this.destroyed$)
    );

    this.invalid$ = this.invalid$$.pipe(filterNullMap());

    this.showVerification$ = combineLatest([this.verified$, this.imported$]).pipe(
      map(([verified, imported]) => {
        return imported && !verified;
      }),
      switchMap((state: boolean) => {
        if (!state) {
          return of(state).pipe(delay(2000));
        }
        return of(state);
      }),
      takeUntil(this.destroyed$)
    );

    this.form$ = this.form$$.pipe(filterNullMap());
  }
  ngOnInit(): void {
    this.invalid$$
      .pipe(filterNullMap(), withLatestFrom(this.form$$))
      .subscribe(([invalid, form]) => {
        if (invalid) {
          form.controls["verificationCode"].setErrors({ codeInvalid: true });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onVerify() {
    this.invalid$$.next(false);
    this.form$$
      .pipe(
        filterNullMap(),
        switchMap((form: FormGroup) => {
          return this.emailVerificationService.verify(form.value).pipe(map(() => false));
        }),
        first(),
        catchError(() => of(true))
      )
      .subscribe({
        next: invalid => {
          this.invalid$$.next(invalid);
        }
      });
  }

  onResend() {
    this.emailVerificationService
      .sendVerification()
      .pipe(first())
      .subscribe({
        next: () => {
          this.notificationService.successMessage("Verification code sent");
        }
      });
  }

  verificationCodeValidator(): AsyncValidatorFn {
    return (_control: AbstractControl) => {
      return this.invalid$$.pipe(
        map((invalid: boolean | null) => {
          if (invalid === true) {
            return { codeInvalid: true };
          }
          return null;
        })
      );
    };
  }
}
